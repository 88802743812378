import { Container, Content, Image } from './AccountConfirmation.styled'
import Text from '@components/ui/Text/Text'

const AccountConfirmation = () => {
  return (
    <Container>
      <Content>
        <Text large>Prawie skończone!</Text>
        <Text small>Aby Twoje zlecenie było aktywne, musisz potwierdzić adres e-mail, na który wysłaliśmy Ci link aktywujący konto.</Text>
        <Image />
      </Content>
    </Container>
  )
}

export default AccountConfirmation
