import styled, { keyframes } from 'styled-components'
const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const SpinnerWrapper = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  width: ${(props) => props.size === 'large' && '60px !important'};
  height: ${(props) => props.size === 'large' && '60px !important'};
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ff771c;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
`
