import { Routes as Routing, Route, useLocation } from 'react-router-dom'
import MainLayout from '@components/layout/MainLayout/MainLayout'
import AccountConfirmationLink from '@pages/confirmations/AccountConfirmationLink/AccountConfirmationLink'
import SecondaryLayout from '@components/layout/SecondaryLayout/SecondaryLayout'
import OfferConfirmationLink from '@pages/confirmations/OfferConfirmationLink/OfferConfirmationLink'
import OrderConfirmation from '@pages/confirmations/OrderConfirmation/OrderConfirmation'
import AccountConfirmation from '@pages/confirmations/AccountConfirmation/AccountConfirmation'
import Home from '@pages/common/Home/Home'
import AddOrder from '@pages/common/AddOrder/AddOrder'
import CollectOffers from '@pages/common/CollectOffers/CollectOffers'
import OfferConfirmation from '@pages/confirmations/OfferConfirmation/OfferConfirmation'

import AccountCreatedConfirmation from '@pages/confirmations/AccountCreatedConfirmation/AccountCreatedConfirmation'
import RegisterCustomer from '@pages/customer/RegisterCustomer/RegisterCustomer'
import RegisterPilot from '@pages/pilot/RegisterPilot/RegisterPilot'
import { useTranslation } from 'react-i18next'

const PublicRoutes = () => {
  const location = useLocation()
  const { t } = useTranslation()
  return (
    <Routing location={location} key={location.pathname}>
      <Route
        path="/"
        exact
        element={
          <MainLayout>
            <Home />
          </MainLayout>
        }
      />
      <Route
        path="/register/customer"
        exact
        element={
          <MainLayout
            locations={[
              { name: 'DroneConnect', path: '/' },
              { name: 'customer-register', path: '' },
            ]}
            title={t('tracking-bar.customer-register')}
          >
            <RegisterCustomer />
          </MainLayout>
        }
      />
      <Route
        path="/register/pilot"
        exact
        element={
          <MainLayout
            locations={[
              { name: 'DroneConnect', path: '/' },
              { name: 'pilot-register', path: '' },
            ]}
            title={t('tracking-bar.pilot-register')}
          >
            <RegisterPilot />
          </MainLayout>
        }
      />

      <Route
        path="/add-order"
        exact
        element={
          <MainLayout
            locations={[
              { name: 'DroneConnect', path: '/' },
              { name: 'auctions', path: '' },
            ]}
            title={t('tracking-bar.auctions')}
          >
            <AddOrder />
          </MainLayout>
        }
      />
      <Route
        path="/add-order/order-confirmation"
        exact
        element={
          <MainLayout>
            <OrderConfirmation />
          </MainLayout>
        }
      />

      <Route
        path="/account-confirmation"
        exact
        element={
          <MainLayout>
            <AccountConfirmation />
          </MainLayout>
        }
      />
      <Route
        path="/account-confirmation/created"
        exact
        element={
          <MainLayout>
            <AccountCreatedConfirmation />
          </MainLayout>
        }
      />
      <Route
        path="/collect-offers"
        exact
        element={
          <MainLayout
            locations={[
              { name: 'DroneConnect', path: '/' },
              { name: 'collect-offers', path: '' },
            ]}
            title={t('tracking-bar.collect-offers')}
          >
            <CollectOffers />
          </MainLayout>
        }
      />
      <Route
        path="/collect-offers/offer-confirmation"
        exact
        element={
          <MainLayout>
            <OfferConfirmation />
          </MainLayout>
        }
      />
      {/* <Route
        path="/collect-offers/account"
        exact
        element={
          <MainLayout>
            <CollectOffersAccount />
          </MainLayout>
        }
      /> */}
      <Route
        path="/users/:id/confirmation"
        exact
        element={
          <SecondaryLayout>
            <AccountConfirmationLink />
          </SecondaryLayout>
        }
      />
      <Route
        path="/offer-confirmation-link"
        exact
        element={
          <SecondaryLayout>
            <OfferConfirmationLink />
          </SecondaryLayout>
        }
      />
    </Routing>
  )
}

export default PublicRoutes
