import styled from 'styled-components'
import { MdOutlineArrowForwardIos } from 'react-icons/md'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`
export const Text = styled.p`
  font-size: 12px;
  color: ${(props) => (props.currentLocation ? '#6C757D' : '#3d3d3d')};
`
export const Separator = styled(MdOutlineArrowForwardIos)`
  font-size: 12px;
`
