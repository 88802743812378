import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
`
export const Row = styled.div`
  display: flex;
  height: fit-content;
  align-items: center;
  gap: 8px;
  p {
    color: #6c757d;
    font-size: 12px;
  }
  svg {
    color: white;
    height: 20px;
    width: 20px;
  }
  div {
    height: 32px;
    width: 32px;
    background-color: ${(props) => (props.fulfilled ? '#74DC98' : '#E0E2E4')};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
`
