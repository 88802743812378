import {
  Content,
  ListItem,
  AuctionImage,
  AuctionEndTime,
  ContentBox,
  GeneralInfo,
  GeneralInfoTop,
  GeneralInfoTopCategory,
  Details,
  DetailsRow,
  DetailsRowData,
  DetailsRowTitle,
  Description,
} from './Offer.styled'
import { FaEarthEurope } from 'react-icons/fa6'
import { CiMoneyBill } from 'react-icons/ci'
import Text from '../Text/Text'
import Separator from '../Separator/Separator'
import { TfiTime } from 'react-icons/tfi'

import { BiCalendarX } from 'react-icons/bi'
import { FiMapPin } from 'react-icons/fi'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { useGetServices } from '@services/dictionaries.services'
import { useGetBidding } from '@services/biddings'
import { AuthContext } from '@context/AuthContext'
import { formatDate } from '@utils/formatDate'
import PilotOffers from '../PilotOffers/PilotOffers'
import { List } from '../Auction/Auction.styled'
import OfferLoader from './OfferLoader'
import {
  useGetCitiesByCountry,
  useGetCountries,
  useGetRegions,
  useGetState,
} from '@services/locations.services'
import { useTranslation } from 'react-i18next'
const Offer = ({ offer, pilot, customer, biddings }) => {
  const { t } = useTranslation()
  const { data: regions } = useGetRegions()

  const region = regions?.filter((el) => el.id === offer?.locationRegion)[0]
    ?.name

  const { data: countries } = useGetCountries(region)
  const country = countries?.filter((el) => el.id === offer.locationCountry)[0]
    ?.name
  const { data: cities } = useGetCitiesByCountry(
    countries?.filter((el) => el.id === offer.locationCountry)[0]?.id
  )
  const { data: states } = useGetState(
    countries?.filter((el) => el.id === offer.locationCountry)[0]?.id
  )
  const state = states?.filter((el) => el.id === offer.locationState)[0]?.name

  const city = cities?.filter((el) => el.id === offer.locationCity)[0]?.name

  const params = useParams()
  const {
    user: { token },
  } = useContext(AuthContext)
  const { data, isLoading } = useGetBidding(token, params.id)
  const services = useGetServices()

  return (
    <Content>
      {isLoading && <OfferLoader />}
      {!isLoading && data && (
        <>
          <Text large>{t('auction-list-item.offer')}</Text>
          <ListItem>
            {offer.background && <AuctionImage src={offer.background} />}
            <ContentBox>
              <GeneralInfo>
                <AuctionEndTime>
                  <TfiTime />
                  {t('auction-list-item.end')}: {formatDate(offer?.rfqEndDate)}
                </AuctionEndTime>

                <GeneralInfoTop>
                  <GeneralInfoTopCategory>
                    {' '}
                    {offer &&
                      services?.data?.data?.filter(
                        (el) => el?.service?.id === data?.service?.id
                      )[0]?.name}{' '}
                  </GeneralInfoTopCategory>
                </GeneralInfoTop>
              </GeneralInfo>
              <Details>
                <DetailsRow>
                  <DetailsRowTitle>
                    {t('auction-list-item.date')}:
                  </DetailsRowTitle>
                  <DetailsRowData>
                    <BiCalendarX />
                    {offer.oneDay
                      ? formatDate(offer?.dateFrom)
                      : `${formatDate(offer?.dateFrom)}-${formatDate(
                          offer?.dateTo
                        )}`}
                  </DetailsRowData>
                </DetailsRow>

                <DetailsRow>
                  <DetailsRowTitle>
                    {t('auction-list-item.location')}:
                  </DetailsRowTitle>
                  <DetailsRowData>
                    <FiMapPin />
                    {region}, {country}, {state}, {city}
                  </DetailsRowData>
                </DetailsRow>
                <DetailsRow>
                  <DetailsRowTitle>
                    {t('auction-list-item.languages')}:
                  </DetailsRowTitle>
                  <DetailsRowData>
                    <FaEarthEurope />
                    {offer?.language}
                  </DetailsRowData>
                </DetailsRow>
                <DetailsRow>
                  <DetailsRowTitle>
                    {t('auction-list-item.currency')}:
                  </DetailsRowTitle>
                  <DetailsRowData>
                    <CiMoneyBill />
                    {offer.currency}
                  </DetailsRowData>
                </DetailsRow>
              </Details>
            </ContentBox>
          </ListItem>

          <Description> {offer?.description} </Description>

          <Separator margin="0" />
          <Text large>{t('auction-list-item.offers')}</Text>
          {biddings && !biddings.length && (
            <Text small secondary margin="10px">
              {t('auction-list-item.no-offers')}
            </Text>
          )}

          <List>
            {biddings &&
              biddings?.map((el) => (
                <PilotOffers customer={customer} pilot={pilot} offer={el} />
              ))}
          </List>
          <Separator margin="0" />
        </>
      )}
    </Content>
  )
}

export default Offer
