import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #0000007f;
  z-index: 10;
`

export const MainContainer = styled.div`
  width: 100%;
`

export const Popup = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 24px;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    @media only screen and (max-width: 600px) {
      padding: 12px;
      align-items: center;
    }
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  height: fit-content:
  width: fit-content;
  cursor: pointer;
  svg {
    color: #3d3d3d;
    height: 14px;
    width: 14px;
  }
`
export const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
`
