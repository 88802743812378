import { api } from './api.service'

export const getBiddings = async (token) => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/pilots/biddings`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const getBidding = async (token, id) => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/auctions/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res.data
}

export const getAuctionBiddings = async (id) => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/auctions/admin/${id}/bid`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
  return res.data
}

export const bidAuction = async (id, token, data) => {
  const res = await api.post(`${process.env.REACT_APP_API_URL}/auctions/${id}/bid`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res.data
}

export const acceptBid = async (projectId, biddingId, token) => {
  const res = await api.post(
    `${process.env.REACT_APP_API_URL}/auctions/${projectId}/bid_accept/${biddingId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res.data
}
