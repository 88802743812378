import Text from '@components/ui/Text/Text'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import FormGroup from '@components/form/FormGroup/FormGroup'

import { useState } from 'react'
import {
  Container,
  Background,
  Content,
  Header,
  Icon,
  Form,
  Footer,
} from './LoginModal.styled'
import Button from '@components/ui/Button/Button'
import { useLoginQuery } from '@services/auth.services'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const LoginModal = ({ setIsAuthModalOpen, setIsRegisterModalOpen }) => {
  const [resetPasswordContext, setResetPasswordContext] = useState(false)
  const { t } = useTranslation()
  const [userData, setUserData] = useState({})
  const login = useLoginQuery()
  const navigate = useNavigate()

  const onSubmit = async (e) => {
    e.preventDefault()
    login.mutate(userData, {
      onSuccess: (data) => {
        setIsAuthModalOpen(false)
        if (data.data.pilot) {
          navigate('/pilot/auctions')
        } else {
          navigate('/customer/auctions')
        }
      },
    })
  }

  return (
    <Container>
      <Background
        onClick={(e) => {
          e.preventDefault()
          setIsAuthModalOpen(false)
        }}
      />
      <Content>
        <Header>
          <Text medium>
            {resetPasswordContext
              ? t('login-modal.reset-password ')
              : t('login-modal.title')}
          </Text>
          <Icon
            onClick={(e) => {
              e.preventDefault()
              setIsAuthModalOpen(false)
            }}
          />
        </Header>
        <Form onSubmit={onSubmit}>
          <FormGroup type="text" width="100%" margin="1vw">
            {login.isError && <Text error>{t('login.login-error')}</Text>}
            <Label>{t('login-modal.email')}</Label>
            <Input
              width="100%"
              name="email"
              id="email"
              type="email"
              onChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }}
            />
          </FormGroup>
          {!resetPasswordContext && (
            <FormGroup type="text" width="100%" margin="1vw">
              <Label>{t('login-modal.password')}</Label>
              <Input
                width="100%"
                name="password"
                id="password"
                type="password"
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }}
              />
            </FormGroup>
          )}
          {!resetPasswordContext && (
            <Text primary small onClick={() => setResetPasswordContext(true)}>
              {t('login-modal.forgot-password')}
            </Text>
          )}
          {!resetPasswordContext && (
            <>
              {!login.isLoading && (
                <Button
                  primary
                  light
                  label={t('login-modal.login')}
                  width="100%"
                  margin="1vw 0 0 0"
                  type="submit"
                />
              )}
              {login.isLoading && (
                <Button
                  primary
                  light
                  label={
                    <p>
                      {t('login-modal.loading')}
                      <i
                        className="pi pi-spin pi-spinner"
                        style={{ fontSize: '14px' }}
                      ></i>
                    </p>
                  }
                  width="100%"
                  margin="1vw 0 0 0"
                />
              )}
            </>
          )}
          {resetPasswordContext && (
            <>
              {!login.isLoading && (
                <Button
                  primary
                  light
                  label={t('login-modal.send-email')}
                  width="100%"
                  margin="1vw 0 0 0"
                  type="submit"
                />
              )}
              {login.isLoading && (
                <Button
                  primary
                  light
                  label={
                    <p>
                      {t('login-modal.loading')}
                      <i
                        className="pi pi-spin pi-spinner"
                        style={{ fontSize: '14px' }}
                      ></i>
                    </p>
                  }
                  width="100%"
                  margin="1vw 0 0 0"
                />
              )}
            </>
          )}
        </Form>
        {!resetPasswordContext && (
          <Footer>
            <Text small secondary margin="0 0.3vw 0 0">
              {t('login-modal.do-not-have-account')}
            </Text>

            <Text
              primary
              small
              onClick={(e) => {
                e.preventDefault()
                setIsAuthModalOpen(false)
                setIsRegisterModalOpen(true)
              }}
            >
              {t('login-modal.register')}
            </Text>
          </Footer>
        )}
      </Content>
    </Container>
  )
}

export default LoginModal
