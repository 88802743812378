import React from 'react'
import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import FormError from '@components/form/FormError/FormError'
import Spinner from '@components/ui/Spinner/Spinner'

import FormGroup from '@components/form/FormGroup/FormGroup'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useTranslation } from 'react-i18next'
const ContactInfo = ({ next, isLoading, isError }) => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <Card width="58vw" form medium>
      <Text margin="0.625vw 0 1.8vw 0" small>
        {t('add-auction.contact-info.title')}
      </Text>
      <FormGroup type="text">
        <Label>{t('add-auction.contact-info.name')}</Label>
        <Input
          type="text"
          width="23.75vw"
          name="firstName"
          {...register('firstName', {
            required: t('add-auction.contact-info.field-required'),
          })}
          hasError={errors.hasOwnProperty('firstName')}
        />
        <ErrorMessage
          errors={errors}
          name="firstName"
          render={({ message }) => <FormError message={message} />}
        />
      </FormGroup>
      <FormGroup type="text">
        <Label>{t('email')}</Label>
        <Input
          width="23.75vw"
          name="email"
          type="email"
          {...register('email', {
            required: t('add-auction.contact-info.filed,required'),
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Niepoprawny adres e-mail',
            },
          })}
          hasError={errors.hasOwnProperty('email')}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => <FormError message={message} />}
        />
      </FormGroup>
      {!isLoading ? (
        <Button
          label={t('add-auction.contact-info.send')}
          primary
          light
          type="submit"
        />
      ) : (
        <Spinner size="small" />
      )}
    </Card>
  )
}

export default ContactInfo
