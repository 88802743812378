import { useEffect } from 'react'
import Text from '../Text/Text'
import { Container, Row } from './PasswordRequirements.styled'
import { BsCheckLg } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

const PasswordRequirements = ({ watch, name, email }) => {
  const passwordValue = watch(name)
  const { t } = useTranslation()
  return (
    <Container>
      <Text small margin="0 0 0.5vw 0">
        {t('password-requirements.title')}
      </Text>
      <Row fulfilled={passwordValue && /[a-z]/.test(passwordValue)}>
        <div>
          <BsCheckLg />
        </div>
        <p>{t('password-requirements.requirement-1')}</p>
      </Row>
      <Row fulfilled={passwordValue && /[A-Z]/.test(passwordValue)}>
        <div>
          <BsCheckLg />
        </div>
        <p>{t('password-requirements.requirement-2')}</p>
      </Row>
      <Row fulfilled={passwordValue && /\d/.test(passwordValue)}>
        <div>
          <BsCheckLg />
        </div>
        <p>{t('password-requirements.requirement-3')}</p>
      </Row>
      <Row fulfilled={passwordValue && passwordValue?.length >= 8}>
        <div>
          <BsCheckLg />
        </div>
        <p>{t('password-requirements.requirement-4')}</p>
      </Row>
      <Row fulfilled={watch(name) !== watch(email)}>
        <div>
          <BsCheckLg />
        </div>
        <p>{t('password-requirements.requirement-5')}</p>
      </Row>
    </Container>
  )
}

export default PasswordRequirements
