import { Btn } from './Button.styled.js'

const Button = ({ primary, outlined, dark, light, label, margin, large, onClick, width, blue, disabled, type, ...rest }) => {
  return (
    <Btn
      onClick={onClick}
      label={label}
      width={width}
      primary={primary}
      outlined={outlined}
      dark={dark}
      light={light}
      margin={margin}
      blue={blue}
      large={large}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {label}
    </Btn>
  )
}

export default Button
