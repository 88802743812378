import styled from 'styled-components'

export const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: '#f5f5f5';
  .trackingbar {
    @media only screen and (max-width: 600px) {
      padding: 16px !important;
    }
  }
  .title {
    @media only screen and (max-width: 600px) {
      padding: 16px 0 !important;
    }
  }
  .grid {
    @media only screen and (max-width: 600px) {
      display: flex;
      padding: 0 !important;
    }
  }
    
  }


  
`
