import styled from 'styled-components'

export const Grid = styled.div`
width: 100%;
  display: grid;

  grid-template-columns: 20% 60% 20%;`
export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1vw;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  justify-content: ${(props) => props.row && 'space-between'};
  margin-bottom: ${(props) => props.row && '3vw'};

  gap: 24px;
`

export const AuctionsList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  jusitfy-content: center;
  gap: 1vw;
`
