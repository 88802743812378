import {
  Container,
  Box,
  Content,
  UserInfo,
  Right,
} from './CustomerAccount.styled'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import TrackingBar from '@components/layout/TrackingBar/TrackingBar'
import AccountInfo from './AccountInfo'
import { useContext } from 'react'
import { AuthContext } from '@context/AuthContext'
import { useGetUser, useUpdateUser } from '@services/user.services'
import CompanyInfo from './CompanyInfo'
import ChangePassword from './ChangePassword'
import { useForm } from 'react-hook-form'
import FileUploader from '@components/form/FileUploader/FileUploader'
import PilotAccountLoader from '@pages/pilot/PilotAccount/PilotAccountLoader'
import useToast from '@hooks/useToast'
import { useTranslation } from 'react-i18next'

const CustomertAccount = () => {
  const { showToast, ToastComponent } = useToast()

  const { handleSubmit, control, setValue } = useForm()
  const {
    user: { userId, token },
  } = useContext(AuthContext)
  const { data: user, isLoading } = useGetUser(userId)

  const updateUser = useUpdateUser(userId, token)
  const { t } = useTranslation()
  const onSubmit = (data) => {
    const updatedAvatar = {
      ...user,
      avatar: data.avatar,
    }
    updateUser.mutate(updatedAvatar, {
      onSuccess: () =>
        showToast('success', 'Success', t('customer-account.avatar-updated')),
    })
  }

  return (
    <Container>
      {ToastComponent}
      <TrackingBar
        locations={[
          { name: 'DroneConnect', path: '/' },
          { name: 'my-accounts' },
        ]}
      />
      <Box>
        {isLoading && <PilotAccountLoader />}
        {!isLoading && user && (
          <Content>
            <Text large>{t('customer-account.my-accounts')}</Text>
            <UserInfo>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FileUploader
                  name="avatar"
                  control={control}
                  setValue={setValue}
                  currentAvatar={user?.data?.avatar}
                  isSuccess={updateUser.isSuccess}
                />
                <Button
                  label={
                    updateUser.isLoading ? (
                      <p>
                        {t('customer-account.loading')}
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: '14px' }}
                        ></i>{' '}
                      </p>
                    ) : (
                      t('customer-account.save')
                    )
                  }
                  margin={'10px 0'}
                  width="100%"
                  light
                  primary
                  type="submit"
                />
              </form>

              <Right>
                <AccountInfo customer={user?.data} />
                <CompanyInfo customer={user?.data} />
                <ChangePassword customer={user?.data} />
              </Right>
            </UserInfo>
          </Content>
        )}
      </Box>
    </Container>
  )
}

export default CustomertAccount
