import { useContext, useState, useEffect } from 'react'
import { useAcceptBid } from '@services/biddings'
import Button from '../Button/Button'
import Text from '../Text/Text'
import { List, ListItem, Avatar } from './PilotOffers.styled'
import { AuthContext } from '@context/AuthContext'
import { useTranslation } from 'react-i18next'

const PilotOffers = ({ customer, pilot, offer }) => {
  const [showMessage, setShowMessage] = useState(false)
  const { t } = useTranslation()
  const {
    user: { token, userId },
  } = useContext(AuthContext)

  const accept = useAcceptBid(offer.project.publicId, offer.publicId, token)
  useEffect(() => {
    if (accept.isSuccess || accept.isError) {
      setShowMessage(true)
      const timeoutId = setTimeout(() => {
        setShowMessage(false)
      }, 3000)
      return () => clearTimeout(timeoutId)
    }
  }, [accept.isSuccess, accept.isError])

  return (
    <ListItem>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {(offer.pilot.user.publicId === userId || customer) && (
          <Avatar src={offer?.pilot?.user?.avatar} />
        )}
        <Text medium block>
          {(offer.pilot.user.publicId === userId || customer) &&
            offer?.pilot?.user?.firstName}{' '}
          {(offer.pilot.user.publicId === userId || customer) &&
            offer?.pilot?.user?.lastName}
          {offer.pilot.user.publicId !== userId && pilot && 'Najlepsza oferta'}
        </Text>
      </div>
      <Text medium primary>
        {offer?.amount} {offer.project.currency}
      </Text>
      {customer && !offer.won && !showMessage && !accept.isLoading && (
        <Button
          primary
          light
          label={t('pilot-offers.pick')}
          onClick={() => accept.mutate()}
        />
      )}
      {customer && showMessage && accept.isSuccess && (
        <Text success small>
          <i className="pi pi-check"></i> {t('pilot-offers.confirm')}
        </Text>
      )}
      {customer && showMessage && accept.isError && (
        <Text error small>
          <i className="pi pi-times"></i> {t('pilot-offers.failed')}
        </Text>
      )}
      {customer && accept.isLoading && !showMessage && (
        <Button
          primary
          light
          label={
            <p>
              {t('pilot-offers.loading')}
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: '14px' }}
              ></i>
            </p>
          }
        />
      )}
      {customer && !accept.isLoading && offer.won && (
        <div
          style={{
            width: '180px',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <Text medium block>
            {t('pilot-offers.won')}
          </Text>
        </div>
      )}
    </ListItem>
  )
}

export default PilotOffers
