import styled from 'styled-components'
import logo from '../../../assets/logo_black.svg'
export const Container = styled.footer`
  display: flex;
  padding: 24px 32px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  .link {
    color: #a1a5b7;
    font-size: 12px;
    margin-bottom: 12px;
  }
  .description {
    font-size: 12px;
    color: #3d3d3d;
  }
`

export const Logo = styled.div`
  background-image: url(${logo});
  background-size: 24px;
  height: 24px;
  width: 24px;
  margin-bottom: 24px;
`

export const Title = styled.h5`
  font-size: 14px;
  line-height: 16px;
  color: #3d3d3d;
  margin-bottom: 16px;
`

export const Card = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid #eef3f7;
  border-radius: 8px;
  flex-direction: column;
  gap: 24px;
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #3d3d3d;
  }
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #3d3d3d;
  }
`
