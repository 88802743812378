import Hero from './partials/Hero/Hero'
import Services from './partials/Services/Services'
import Description from './partials/Description/Description'
import HowItWorks from './partials/HowItWorks/HowItWorks'
import Footer from '@components/layout/Footer/Footer'

const Home = () => {
  return (
    <div>
      <Hero />
      <HowItWorks />
      <Description />
      <Services />
      <Footer />
    </div>
  )
}

export default Home
