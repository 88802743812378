import Text from '@components/ui/Text/Text'
import AuctionList from '@components/ui/AuctionList/AuctionList'
import { Container } from './PilotBids.styled'
import { useGetPilotsBiddings } from '@services/biddings'
import { useContext } from 'react'
import { AuthContext } from '@context/AuthContext'
import AuctionListItemLoader from '@components/ui/AuctionListItem/AuctionListItemLoader'
import { useTranslation } from 'react-i18next'

const PilotBids = () => {
  const {
    user: { token },
  } = useContext(AuthContext)
  const { data, isLoading } = useGetPilotsBiddings(token)

  const { t } = useTranslation()
  return (
    <Container>
      <div></div>

      {isLoading && (
        <div style={{ marginTop: 40 }}>
          {[1, 2, 3, 4].map((el) => (
            <AuctionListItemLoader />
          ))}
        </div>
      )}
      {!isLoading && !data?.data.length && (
        <Text secondary margin="30px 0">
          {t('auctions-list.no-bids')}
        </Text>
      )}

      {data && !isLoading && <AuctionList pilot auctions={data?.data} />}
    </Container>
  )
}

export default PilotBids
