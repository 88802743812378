import styled from 'styled-components'

export const Container = styled.div`
  height: fit-content;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  justify-content: center;
`

export const Content = styled.div`
  width: 80%;
  padding: 24px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3vw;
`
