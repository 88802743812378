import { Container, Form } from './CollectOffers.styled'
import ProgressBar, {
  useHandleProgressBar,
} from '@components/layout/ProgressBar/ProgressBar'
import { FormProvider, useForm } from 'react-hook-form'
import OfferInfo from '@components/ui/OfferInfo/OfferInfo'
import ContactInfo from '@components/ui/ContactInfo/ContactInfo'
import { useNavigate } from 'react-router'
import {
  useCreateAuction,
  useCreateAuctionAsGuest,
} from '@services/auctions.services'
import { useContext, useState } from 'react'
import { AuthContext } from '@context/AuthContext'
import { useRegisterUser } from '@services/auth.services'

const CollectOffers = () => {
  const [active, setActive] = useState('oneDay')
  const methods = useForm({
    defaultValues: {
      locationCountry: {
        id: 176,
        name: 'Poland',
        region: null,
        regionId: 4,
      },
      language: 'Polski',
      locationRegion: { name: 'Europe', id: 4 },
    },
  })

  const {
    user: { token, userId },
  } = useContext(AuthContext)
  const { currentPage, next } = useHandleProgressBar(methods.trigger)

  const createAuction = useCreateAuction(token)
  const createAutionAsGuest = useCreateAuctionAsGuest()
  const registerUser = useRegisterUser()
  const navigate = useNavigate()
  async function onSubmit(data) {
    let newData = {
      ...data,
      userPublicId: null,
      oneDay: active === 'oneDay' ? true : false,
      dateFrom: Array.isArray(data.dateFrom) ? data.dateFrom[0] : data.dateFrom,
      ...(Array.isArray(data.dateFrom) &&
        data.dateFrom.length === 2 && { dateTo: data.dateFrom[1] }),
      duration: 3,
      title: '',
      auction: false,
      location: '',
      locationRegion: data.locationRegion?.id ?? 0,
      locationCity: data.locationCity?.id ?? 0,
      locationCountry: data.locationCountry?.id ?? 0,
      locationState: data?.locationState.id ?? 0,
    }
    if (userId) {
      createAuction.mutate(newData, {
        onSuccess: (data) =>
          navigate(`/customer/auctions/${data.data.publicId}`),
      })
    } else {
      registerUser.mutate(
        {
          firstName: data.firstName,
          email: data.email,
          phone: '',
          password: '',
          lastName: '',
        },
        {
          onSuccess: (data) => {
            delete newData.firstName
            delete newData.email
            newData = {
              ...newData,
              userPublicId: data.data.publicId,
            }
            createAutionAsGuest.mutate(newData, {
              onSuccess: () => navigate('/'),
            })
          },
        }
      )
    }
  }

  return (
    <Container>
      <ProgressBar currentPage={currentPage} collectOffers />
      {userId && (
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            {currentPage === 1 && (
              <OfferInfo
                active={active}
                setActive={setActive}
                next={next}
                isLoading={createAuction.isLoading}
              />
            )}
          </Form>
        </FormProvider>
      )}{' '}
      {!userId && (
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            {currentPage === 1 && (
              <OfferInfo
                next={next}
                active={active}
                setActive={setActive}
                isLoading={false}
              />
            )}
            {currentPage === 2 && (
              <ContactInfo
                next={next}
                isLoading={createAutionAsGuest.isLoading}
                isError={registerUser.isError}
              />
            )}
          </Form>
        </FormProvider>
      )}
    </Container>
  )
}

export default CollectOffers
