import Card from '../Card/Card'
import Input from '../../form/Input/Input'
import FormGroup from '../../form/FormGroup/FormGroup'
import Button from '../Button/Button'
import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetAuctionBidding, useBidAuction } from '@services/biddings'
import { AuthContext } from '@context/AuthContext'
import { Container } from './AddOffer.styled'
import Text from '../Text/Text'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
const AddOffer = ({ top, right, currency }) => {
  const { register, handleSubmit, setValue } = useForm()
  const params = useParams()
  const { t } = useTranslation()
  const {
    user: { token },
  } = useContext(AuthContext)

  const bidAuction = useBidAuction(params.id, token)
  const onSubmit = async (data) => {
    bidAuction.mutate(data)
    setValue('amount', '')
  }

  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    if (bidAuction.isSuccess || bidAuction.isError) {
      setShowMessage(true)
      const timeoutId = setTimeout(() => {
        setShowMessage(false)
      }, 3000)
      return () => clearTimeout(timeoutId)
    }
  }, [bidAuction.isSuccess, bidAuction.isError])
  return (
    <Container>
      <Card form width="20vw" padding="1.5vw">
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text medium margin="0 0 0vw 0">
              {t('add-offer.offer')} {currency}
            </Text>
          </div>

          <FormGroup type="text" width="100%" margin="20px 0">
            <Text small secondary>
              {t('add-offer.provide-offer')}
            </Text>
            <Input
              width="100%"
              type="number"
              {...register('amount', { required: true })}
            />

            {showMessage && bidAuction.isSuccess && (
              <Text success small>
                <i className="pi pi-check"></i> {t('add-offer.added-offer')}
              </Text>
            )}
            {showMessage && bidAuction.isError && (
              <Text error small>
                <i className="pi pi-times"></i> {t('add-offer.failed')}
              </Text>
            )}
          </FormGroup>
          {!bidAuction.isLoading && (
            <Button
              label={t('add-offer.add')}
              primary
              light
              width="100%"
              type="submit"
            />
          )}
          {bidAuction.isLoading && (
            <Button
              label={
                <p>
                  {t('add-offer.loading')}
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: '14px' }}
                  ></i>{' '}
                </p>
              }
              primary
              light
              width="100%"
              disabled={true}
            />
          )}
        </form>
      </Card>
    </Container>
  )
}

export default AddOffer
