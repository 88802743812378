import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Box,
  Card,
  ButtonBox,
  Form,
  ContentContainer,
} from './AddOrder.styled'
import Headline from '@components/ui/Headline/Headline'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import LoginPopup from '@components/ui/LoginPopup/LoginPopup'
import ProgressBar, {
  useHandleProgressBar,
} from '@components/layout/ProgressBar/ProgressBar'
import { FormProvider, useForm } from 'react-hook-form'

import {
  useCreateAuction,
  useCreateAuctionAsGuest,
} from '@services/auctions.services'
import { AuthContext } from '@context/AuthContext'
import {
  useConfirmAccount,
  useLoginQuery,
  useRegisterUser,
} from '@services/auth.services'
import AddOrderForm from './AddOrderForm'
import { useTranslation } from 'react-i18next'
const AddOrder = () => {
  const { t } = useTranslation()
  const methods = useForm({
    defaultValues: {
      locationCountry: {
        id: 176,
        name: 'Poland',
        region: null,
        regionId: 4,
      },
      language: 'Polski',
      locationRegion: { name: 'Europe', id: 4 },
    },
  })
  const {
    user: { userId, token },
  } = useContext(AuthContext)
  const navigate = useNavigate()
  const { currentPage, next, setCurrentPage } = useHandleProgressBar(
    methods.trigger
  )
  const registerUser = useRegisterUser()
  const createAuction = useCreateAuction(token)
  const createAucitonAsGuest = useCreateAuctionAsGuest()

  function daysBetween(dateString) {
    const today = new Date()

    const timeDifference = dateString - today

    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

    return daysDifference
  }

  const onSubmitLoggedIn = async (data) => {
    const duration = daysBetween(data.duration)
    let newData = {
      ...data,
      oneDay: Array.isArray(data.dateFrom) ? false : true,
      dateFrom: Array.isArray(data.dateFrom) ? data.dateFrom[0] : data.dateFrom,
      ...(Array.isArray(data.dateFrom) &&
        data.dateFrom.length === 2 && { dateTo: data.dateFrom[1] }),
      image1: data.files && data.files[0] ? data.files[0]?.data : '',
      image2: data.files && data.files[1] ? data.files[1]?.data : '',
      image3: data.files && data.files[2] ? data.files[2]?.data : '',
      image4: data.files && data.files[3] ? data.files[3]?.data : '',
      image5: data.files && data.files[4] ? data.files[4]?.data : '',
      locationRegion: data.locationRegion?.id ?? 0,
      locationCity: data.locationCity?.id ?? 0,
      locationCountry: data.locationCountry?.id ?? 0,
      locationState: data?.locationState.id ?? 0,
      location: '',
      title: '',
      auction: true,
      duration,
    }
    delete newData.files
    createAuction.mutate(newData, {
      onSuccess: (data) => navigate(`/customer/auctions/${data.data.publicId}`),
    })
  }
  const onSubmitGuest = (data) => {
    const duration = daysBetween(data.duration)
    let newData = {
      ...data,
      oneDay: Array.isArray(data.dateFrom) ? false : true,
      dateFrom: Array.isArray(data.dateFrom) ? data.dateFrom[0] : data.dateFrom,
      ...(Array.isArray(data.dateFrom) &&
        data.dateFrom.length === 2 && { dateTo: data.dateFrom[1] }),
      image1: data.files[0]
        ? { name: data.files[0]?.name, data: data.files[0]?.data }
        : '',
      image2: data.files[1]
        ? { name: data.files[1]?.name, data: data.files[1]?.data }
        : '',
      image3: data.files[2]
        ? { name: data.files[2]?.name, data: data.files[2]?.data }
        : '',
      image4: data.files[3]
        ? { name: data.files[3]?.name, data: data.files[3]?.data }
        : '',
      image5: data.files[4]
        ? { name: data.files[4]?.name, data: data.files[4]?.data }
        : '',
      locationRegion: data.locationRegion?.id ?? 0,
      locationCity: data.locationCity?.id ?? 0,
      locationCountry: data.locationCountry?.id ?? 0,
      locationState: data?.locationState.id ?? 0,
      title: '',
      auction: true,
      duration,
    }
    delete newData.files

    registerUser.mutate(
      {
        firstName: data.firstName,
        email: data.email,
        phone: data.phone,
        password: data.password,
        lastName: data.lastName,
      },
      {
        onSuccess: (data) => {
          newData = {
            ...newData,
            userPublicId: data.data.publicId,
          }
          createAucitonAsGuest.mutate(newData, {
            onSuccess: () => navigate('/add-order/order-confirmation'),
          })
        },
      }
    )
  }

  const loginUser = useLoginQuery()
  const login = async (data) => {
    loginUser.mutate(data)
    setCurrentPage(2)
  }

  const [activeCard, setActiveCard] = useState('')
  const [open, setOpen] = useState(false)

  function changeCard(card) {
    setActiveCard((activeCard) => card)
  }

  return (
    <Container>
      <ProgressBar currentPage={currentPage} addOrder />
      {!userId && currentPage === 2 && activeCard !== 'registrationOpen' && (
        <ContentContainer>
          {open && (
            <LoginPopup
              open={open}
              setOpen={setOpen}
              actionAfterLogin={login}
            />
          )}
          <Headline secondary medium>
            {t('add-order.have-account')}
          </Headline>
          <Text small secodnary center margin="14px 0">
            {t('add-order.have-account-text-1')}
            <br />
            {t('add-orderhave-account-text-2')}
          </Text>
          <Box>
            <Card
              onClick={() => changeCard('registration')}
              active={activeCard === 'registration'}
            >
              <Text medium width="100%" margin="14px">
                {t('add-order.account-title')}
              </Text>
              <Text small secodnary width="100%" center>
                {t('add-order.account-text-1')} <br />
                {t('add-order.account-text-2')}
              </Text>
            </Card>
            <Card
              onClick={() => changeCard('login')}
              active={activeCard === 'login'}
            >
              <Text medium width="100%" margin="1.5vw 0">
                {t('add-order.account-login')}
              </Text>
              <Text small secodnary width="100%" center>
                {t('add-order.account-login-1')}
              </Text>
            </Card>
          </Box>
          <ButtonBox>
            <Button
              label={t('add-order.next')}
              right
              primary
              light
              onClick={(e) => {
                e.preventDefault()
                if (activeCard === 'login') {
                  setOpen((open) => true)
                  return
                }
                if (activeCard === 'registration') {
                  setActiveCard('registrationOpen')
                  setOpen((open) => false)
                }
              }}
            />
          </ButtonBox>
        </ContentContainer>
      )}
      <FormProvider {...methods}>
        <AddOrderForm
          userId={userId}
          next={next}
          setCurrentPage={setCurrentPage}
          activeCard={activeCard}
          currentPage={currentPage}
          onSubmitLoggedIn={onSubmitLoggedIn}
          onSubmitGuest={onSubmitGuest}
          isLoading={createAuction.isLoading}
          isLoadingGuest={createAucitonAsGuest.isLoading}
        />
      </FormProvider>
    </Container>
  )
}

export default AddOrder
