import { Box, Content } from './AuctionList.styled'
import AuctionListItem from '../AuctionListItem/AuctionListItem'
import AuctionListItemLoader from '../AuctionListItem/AuctionListItemLoader'
const AuctionList = ({ auctions, pilot, customer, isLoading }) => {
  return (
    <Box>
      <Content>
        {isLoading && [1, 2, 3, 4].map((el) => <AuctionListItemLoader />)}

        {auctions && !isLoading && auctions?.map((auction, id) => <AuctionListItem auction={auction} key={auction.publicId} pilot={pilot} customer={customer} />)}
      </Content>
    </Box>
  )
}

export default AuctionList
