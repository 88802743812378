import {
  Container,
  ListItem,
  AuctionImage,
  Header,
  Content,
  GeneralInfo,
  GeneralInfoTop,
  GeneralInfoTopCategory,
  GeneralInfoTopTooltips,
  Details,
  DetailsRow,
  DetailsRowData,
  DetailsRowTitle,
  Description,
  DescriptionLeft,
  DescriptionRight,
} from './OfferListItem.styled'
import { CiMoneyBill } from 'react-icons/ci'
import { useTranslation } from 'react-i18next'
import { TfiTime } from 'react-icons/tfi'
import Text from '../Text/Text'
import Button from '../Button/Button'
import { FiSettings } from 'react-icons/fi'
import { FaEarthEurope } from 'react-icons/fa6'
import { BiCalendarX } from 'react-icons/bi'
import { FiMapPin } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import PilotOffers from '../PilotOffers/PilotOffers'
import { formatDate } from '@utils/formatDate'
import { useGetAuctionBidding } from '@services/biddings'
import { useGetServices } from '@services/dictionaries.services'
import {
  useGetCitiesByCountry,
  useGetCountries,
  useGetRegions,
  useGetState,
} from '@services/locations.services'
import { offers } from '@assets/offers'
const OfferListItem = ({ offer, pilot, customer }) => {
  const navigate = useNavigate()
  const { data: regions } = useGetRegions()

  const region = regions?.filter((el) => el.id === offer?.locationRegion)[0]
    ?.name

  const { data: countries } = useGetCountries(region)
  const country = countries?.filter((el) => el.id === offer.locationCountry)[0]
    ?.name
  const { data: cities } = useGetCitiesByCountry(
    countries?.filter((el) => el.id === offer.locationCountry)[0]?.id
  )
  const { data: states } = useGetState(
    countries?.filter((el) => el.id === offer.locationCountry)[0]?.id
  )
  const state = states?.filter((el) => el.id === offer.locationState)[0]?.name

  const city = cities?.filter((el) => el.id === offer.locationCity)[0]?.name

  const { data: biddings } = useGetAuctionBidding(offer.publicId)
  const sortedBiddings =
    biddings && [...biddings].sort((a, b) => b.amount - a.amount)
  const top3Biddings = sortedBiddings?.slice(0, 3)
  const { data: services } = useGetServices()
  const { t } = useTranslation()

  const expired = new Date().getTime() > new Date(offer?.rfqEndDate).getTime()

  return (
    <Container>
      <Header
        bgColor={offer?.auction === true ? 'green' : 'purple'}
        expired={expired}
      >
        <Text small light>
          {t('auction-list-item.offer')}
        </Text>
        <Text small white>
          <TfiTime />
          {t('auction-list-item.end')}: {formatDate(offer?.rfqEndDate)}
        </Text>
      </Header>
      <ListItem>
        {offer?.background && <AuctionImage src={offer?.background} />}
        <Content wFull={offer?.background}>
          <GeneralInfo>
            <GeneralInfoTop>
              <GeneralInfoTopCategory>
                {services &&
                  services?.data.filter(
                    (el) => el.service.id === offer?.serviceId
                  )[0]?.name}
              </GeneralInfoTopCategory>
              <GeneralInfoTopTooltips></GeneralInfoTopTooltips>
            </GeneralInfoTop>
          </GeneralInfo>
          <Details>
            <DetailsRow>
              <DetailsRowTitle>{t('auction-list-item.date')}:</DetailsRowTitle>
              <DetailsRowData>
                <BiCalendarX />
                {offer.oneDay
                  ? formatDate(offer?.dateFrom)
                  : `${formatDate(offer?.dateFrom)}-${formatDate(
                      offer?.dateTo
                    )}`}
              </DetailsRowData>
            </DetailsRow>
            <DetailsRow>
              <DetailsRowTitle>
                {t('auction-list-item.location')}:
              </DetailsRowTitle>
              <DetailsRowData>
                <FiMapPin />
                {region}, {country}, {state}, {city}
              </DetailsRowData>
            </DetailsRow>
            <DetailsRow>
              <DetailsRowTitle>
                {t('auction-list-item.languages')}:
              </DetailsRowTitle>
              <DetailsRowData>
                <FaEarthEurope />
                {offer?.language}
              </DetailsRowData>
            </DetailsRow>
            <DetailsRow>
              <DetailsRowTitle>
                {t('auction-list-item.currency')}:
              </DetailsRowTitle>
              <DetailsRowData>
                <CiMoneyBill />
                {offer.currency}
              </DetailsRowData>
            </DetailsRow>
          </Details>
          <Description>
            <DescriptionLeft>
              {offer.description.slice(0, 250)}...
            </DescriptionLeft>
            {customer && (
              <DescriptionRight>
                <Button
                  outlined
                  dark
                  label={t('auction-list-item.show')}
                  onClick={() =>
                    navigate(`/customer/auctions/${offer.publicId}`)
                  }
                />
                <Button
                  outlined
                  dark
                  label={<FiSettings />}
                  onClick={() =>
                    navigate(`/customer/auctions/${offer.publicId}/edit-offer`)
                  }
                />
              </DescriptionRight>
            )}
            {pilot && (
              <DescriptionRight>
                <Button
                  outlined
                  dark
                  label={t('auction-list-item.show')}
                  onClick={() =>
                    navigate(`/customer/auctions/${offer.publicId}`)
                  }
                />
              </DescriptionRight>
            )}
          </Description>
        </Content>
      </ListItem>
      {top3Biddings &&
        top3Biddings?.map((el) => {
          return (
            <PilotOffers
              offer={el}
              customer={customer}
              pilot={pilot}
              key={el.publicId}
            />
          )
        })}
    </Container>
  )
}

export default OfferListItem
