import React from 'react'
import ContentLoader from 'react-content-loader'

const OfferLoader = (props) => (
  <ContentLoader speed={2} width={700} height={600} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
    <rect x="170" y="2" rx="3" ry="3" width="100" height="20" />
    <rect x="170" y="30" rx="3" ry="3" width="200" height="35" />
    <rect x="170" y="80" rx="6" ry="6" width="525" height="32" />
    <rect x="170" y="120" rx="6" ry="6" width="525" height="32" />
    <rect x="170" y="160" rx="6" ry="6" width="525" height="32" />
    <rect x="170" y="200" rx="6" ry="6" width="525" height="50" />

    <rect x="0" y="260" rx="6" ry="6" width="700" height="160" />

    <rect x="0" y="450" rx="50" ry="50" width="45" height="45" />
    <rect x="60" y="460" rx="3" ry="3" width="120" height="25" />
    <rect x="600" y="460" rx="3" ry="3" width="100" height="25" />

    <rect x="0" y="500" rx="50" ry="50" width="45" height="45" />
    <rect x="60" y="510" rx="3" ry="3" width="120" height="25" />
    <rect x="600" y="510" rx="3" ry="3" width="100" height="25" />

    <rect x="0" y="560" rx="50" ry="50" width="45" height="45" />
    <rect x="60" y="570" rx="3" ry="3" width="120" height="25" />
    <rect x="600" y="570" rx="3" ry="3" width="100" height="25" />

    <rect x="0" y="2" rx="6" ry="6" width="160" height="250" />
  </ContentLoader>
)

export default OfferLoader
