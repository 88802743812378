import { Container, DropdownIcon } from './LanguageDropdown.styled'
import Text from '../Text/Text'
import { Dropdown, DropdownLink } from '@components/layout/Navbar/Navbar.styled'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
const LanguageDropdown = () => {
  const [visible, setVisible] = useState(false)
  const { t, i18n } = useTranslation()

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang)
  }
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text white onClick={() => setVisible((prev) => !prev)}>
          {i18n.language.toUpperCase()}
        </Text>
        <DropdownIcon />
      </div>
      {visible && (
        <Dropdown onClick={() => setVisible((prev) => !prev)}>
          <Text medium margin="0 0 1vw 0">
            {t('languages.title')}
          </Text>
          <Text medium onClick={() => changeLanguageHandler('pl')}>
            <DropdownLink>{t('languages.pl')}</DropdownLink>
          </Text>
          <Text medium onClick={() => changeLanguageHandler('en')}>
            <DropdownLink>{t('languages.en')}</DropdownLink>
          </Text>
        </Dropdown>
      )}
    </Container>
  )
}

export default LanguageDropdown
