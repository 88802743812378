import styled from 'styled-components'

export const Container = styled.div`
  width: 100wv;
  height: fit-content;
  padding: 1.5vw 4.5vw 4.5vw 4.5vw;
  display: flex;
  flex-direction: column;
`
export const Right = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3vw;
`

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.5vw;
`

export const Content = styled.div`
  width: 70.625vw;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  padding: 2vw;
`

export const UserInfo = styled.div`
  width: 100%;
  display: flex;
  gap: 5vw;
`
export const ImageContainer = styled.form`
  width: 15vw;
  display: flex;
  align-items: center;
  gap: 1vw;
  justify-content: flex-start;
  flex-direction: column;
`

export const Image = styled.img`
  height: 100%;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
`
export const Form = styled.form`
  width: 100%;
  margin-bottom: 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5vw;
  gap: 0.3vw;
`
export const Details = styled.div`
  width: 100%;
  margin-bottom: 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
export const EditInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
