import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Title = styled.h3`
  font-size: 3vw;
  text-align: center !important;
  color: #3d3d3d;
  margin-bottom: 3vw;
`
