import styled from 'styled-components'

export const Container = styled.div`
  width: 100wv;
  height: fit-content;
  padding: 1.5vw 4.5vw 4.5vw 4.5vw;
  display: flex;
  flex-direction: column;
`

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 12vw 0 12vw;
  margin-top: 0.5vw;
  height: fit-content;
`
