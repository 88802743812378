import { Container, Box } from './PilotAuction.styled'
import BidNow from '@components/ui/BidNow/BidNow'
import AddOffer from '@components/ui/AddOffer/AddOffer'

import Auction from '@components/ui/Auction/Auction'
import TrackingBar from '@components/layout/TrackingBar/TrackingBar'
import { useParams } from 'react-router-dom'
import { useGetBidding } from '@services/biddings'
import { useContext } from 'react'
import { AuthContext } from '@context/AuthContext'

const PilotAuction = () => {
  const params = useParams()
  const {
    user: { token },
  } = useContext(AuthContext)
  const { data, isLoading } = useGetBidding(token, params.id)
  const expired = new Date().getTime() > new Date(data?.rfqEndDate).getTime()

  return (
    <Container>
      <TrackingBar
        locations={[
          { name: 'DroneConnect', path: '/' },
          { name: 'auction', pathn: '/pilot/auctions' },
          { name: 'project', path: '/' },
        ]}
      />

      <Box>
        {!isLoading && data?.auction && !expired && (
          <BidNow top="0" right="0" currency={data?.currency} />
        )}
        {!data?.auction && !isLoading && !expired && (
          <AddOffer top="0" right="0" currency={data?.currency} />
        )}

        <Auction pilot isLoading={isLoading} data={data} />
      </Box>
    </Container>
  )
}

export default PilotAuction
