import styled from 'styled-components'

export const ContentContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    min-width: 100%;
    min-height: fit-content;
    gap: 24px;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  form {
    display: flex;
    align-items: start;
    justify-content: flex-start;
  }
  width: 100%;

  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 20% 60% 20%;
  gap: 42px;
  @media only screen and (max-width: 600px) {
    display: flex;
    padding: 0px 24px;
  }
`

export const Box = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
`

export const Card = styled.div`
  background-color: white;
  height: 200px;
  width: 200px;
  box-shadow: 0px 5px 58px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding 8px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-color: ${(props) => (props.active ? '#FF771C' : 'transparent')};
  &:hover {
    border: 1px solid #FF771C
  }
  @media only screen and (max-width: 600px) {
    width: 50%;
    height: 200px;
  }
`

export const ButtonBox = styled.div`
  margin: 2.5vw 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

export const Form = styled.form`
  min-width: 100%;
  justify-content: center;
  display: flex;
`
