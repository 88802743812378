import { Container, Box, Badge } from './Services.styled'
import Headline from '@components/ui/Headline/Headline'
import Button from '@components/ui/Button/Button'
import { useNavigate } from 'react-router-dom'
import { useGetServices } from '@services/dictionaries.services'
import { useTranslation } from 'react-i18next'

const Services = () => {
  const navigte = useNavigate()
  const { data } = useGetServices()
  const { t } = useTranslation()
  return (
    <Container>
      <Headline primary large>
        {t('home.services.title')}
      </Headline>
      <Box>
        {data?.data?.map((el) => (
          <Badge>{el.name}</Badge>
        ))}
      </Box>
      <Button
        label="Uruchom aukcję"
        primary
        light
        onClick={() => {
          navigte('/add-order')
        }}
      />
    </Container>
  )
}

export default Services
