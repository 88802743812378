import { Content, Title, ButtonContainer } from './AccountConfirmationLink.styled'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useConfirmAccount } from '@services/auth.services'
import Spinner from '@components/ui/Spinner/Spinner'
const AccountConfirmationLink = () => {
  const navigate = useNavigate()
  const params = useParams()

  const confirmAccount = useConfirmAccount()

  return (
    <Content>
      <Title>Welcome!</Title>
      <Text medium margin="0 0 3vw 0">
        Cieszymy się, że zaczynasz. Po pierwsze, musisz potwierdzić swoje konto. <br />
        Naciśnij przycick poniżej:
      </Text>

      <ButtonContainer>
        {!confirmAccount.isSuccess && !confirmAccount.isLoading && (
          <Button
            primary
            light
            label="Potwierdź Konto"
            onClick={(e) => {
              e.preventDefault()
              confirmAccount.mutate(params.id)
            }}
          />
        )}

        {confirmAccount.isLoading && <Spinner />}
        {confirmAccount.isSuccess && !confirmAccount.isLoading && (
          <div>
            <Text secondary margin="0 0 20px 10px" medium>
              Konto potwierdzone!
            </Text>
            <Button
              primary
              light
              label="Wróc do strony głównej "
              onClick={(e) => {
                e.preventDefault()
                navigate('/')
              }}
            />
          </div>
        )}
      </ButtonContainer>

      <Text medium margin="1.5vw 0 1vw 0">
        Jeśli masz jakiekolwiek pytania, prosimy odpisać na nasz e-mail - zawsze chętnie pomagamy.{' '}
      </Text>
      <Text small>Pozdrawiamy,</Text>
      <Text small>Zespół DroneConnect</Text>
    </Content>
  )
}

export default AccountConfirmationLink
