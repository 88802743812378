import styled from 'styled-components'

export const SelectEl = styled.select`
  background-color: #ffffff;
  min-width: 197px;
  width: ${(props) => props.width && `${props.width} !important` };

  height: 40px;
  border: 1px solid transparent;
  border: ${(props) => props.hasError && '0.0625vw solid #E32224'};
  padding-left: 0.625vw;
  border: ${(props) => props.hasError && '0.0625vw solid #E32224'};

  color: #3d3d3d;
  color: ${(props) => props.color && props.color};
  border-radius: 0.5vw;
  font-size: 16px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    border: 1px solid #ff771c;
    outline: none;
  }
`

export const Group = styled.div`
  position: relative;
  min-width: fit-content;
  width: ${(props) => props.width && `${props.width} !important` };

  svg {
    position: absolute;
    right: 1vw;
    top: 0.7vw;
    height: 1vw;
    width: 1vw;
    color: #ff771c;
  }
`
