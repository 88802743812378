import {
  Details,
  DetailsRow,
  EditInformation,
  Form,
} from '../PilotAccount.styled'
import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Separator from '@components/ui/Separator/Separator'
import Button from '@components/ui/Button/Button'
import Label from '@components/form/Label/Label'
import Select from '@components/form/Select/Select'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Input from '@components/form/Input/Input'
import { useContext, useState } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { useUpdateUser } from '@services/user.services'
import { AuthContext } from '@context/AuthContext'
import { useForm } from 'react-hook-form'
import useToast from '@hooks/useToast'
import { useTranslation } from 'react-i18next'

const AccountInfo = ({ pilot }) => {
  const { showToast, ToastComponent } = useToast()

  const { handleSubmit, register } = useForm({
    defaultValues: async () => ({
      firstName: pilot?.user?.firstName,
      lastName: pilot?.user?.lastName,
      email: pilot?.user?.email,
      phone: pilot?.user?.phone,
      ...pilot,
    }),
  })
  const { t } = useTranslation()

  const [mode, setMode] = useState('view')
  const {
    user: { token },
  } = useContext(AuthContext)
  const updateUserMutation = useUpdateUser(pilot?.user?.publicId, token)

  const onSubmit = (data) => {
    updateUserMutation.mutate(data, {
      onSuccess: () => {
        setMode('view')
        showToast('success', 'Success', t('customer-account.toast-updated'))
      },
    })
  }

  return (
    <Card width="100%" form padding="24px">
      {ToastComponent}
      <Text large margin="0 0 8px 0">
        {t('customer-account.title')}
      </Text>
      {mode === 'view' && (
        <Details>
          <DetailsRow>
            <Text small>{t('customer-account.first-name')}</Text>

            <Text medium>{pilot?.user?.firstName}</Text>
          </DetailsRow>
          <DetailsRow>
            <Text small>{t('customer-account.last-name')}</Text>
            <Text medium>{pilot?.user?.lastName}</Text>
          </DetailsRow>
          <DetailsRow>
            <Text small>{t('customer-account.email')}</Text>
            <Text medium>{pilot?.user?.email}</Text>
          </DetailsRow>
          <DetailsRow>
            <Text small>{t('customer-account.phone')}</Text>
            <Text medium>{pilot?.user?.phone}</Text>
          </DetailsRow>
          <Separator />
        </Details>
      )}
      {mode === 'edit' && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup type="text">
            <Label>{t('customer-account.first-name')}</Label>
            <Input width="25vw" {...register('firstName')} />
          </FormGroup>
          <FormGroup type="text">
            <Label>{t('customer-account.last-name')}</Label>
            <Input width="25vw" {...register('lastName')} />
          </FormGroup>
          <FormGroup type="text">
            <Label small>{t('customer-account.email')}</Label>
            <Input width="25vw" {...register('email')} />
          </FormGroup>
          <FormGroup type="checkbox">
            <FormGroup type="text" margin="0">
              <Label>{t('customer-account.phone')}</Label>
              <Input
                type="text"
                width="11.875vw"
                name="phoneNumber"
                {...register('phone')}
              />
            </FormGroup>
            <Separator />
          </FormGroup>

          <EditInformation>
            {mode === 'edit' && (
              <Button
                label={
                  updateUserMutation.isLoading ? (
                    <p>
                      {t('customer-account.loading')}
                      <i
                        className="pi pi-spin pi-spinner"
                        style={{ fontSize: '14px' }}
                      ></i>{' '}
                    </p>
                  ) : (
                    t('customer-account.save')
                  )
                }
                light
                primary
                type="submit"
              />
            )}
            {mode === 'edit' && (
              <Button
                label={t('customer-account.cancel')}
                light
                outlined
                blue
                onClick={() => setMode('view')}
              />
            )}
          </EditInformation>
        </Form>
      )}
      {mode === 'view' && (
        <EditInformation>
          <Button
            label={
              <p>
                <FiEdit2 /> {t('customer-account.edit')}
              </p>
            }
            light
            outlined
            blue
            onClick={(e) => {
              e.preventDefault()
              setMode((mode) => 'edit')
            }}
          />
        </EditInformation>
      )}
    </Card>
  )
}

export default AccountInfo
