import { useFormContext } from 'react-hook-form'
import ProjectInfo from '@components/ui/ProjectInfo/ProjectInfo'
import AuctionInfo from '@components/ui/AuctionInfo/AuctionInfo'
import Registration from '@components/ui/Registration/Registration'
import { Form } from './AddOrder.styled'
const AddOrderForm = ({
  userId,
  next,
  setCurrentPage,
  currentPage,
  activeCard,
  onSubmitLoggedIn,
  onSubmitGuest,
  isLoading,
  isLoadingGuest,
}) => {
  const { handleSubmit } = useFormContext()
  return userId ? (
    <Form onSubmit={handleSubmit(onSubmitLoggedIn)}>
      {currentPage === 1 && (
        <ProjectInfo next={next} setCurrentPage={setCurrentPage} />
      )}

      {currentPage === 2 && (
        <AuctionInfo isLoading={isLoading} setCurrentPage={setCurrentPage} />
      )}
    </Form>
  ) : (
    <Form onSubmit={handleSubmit(onSubmitGuest)}>
      {currentPage === 1 && (
        <ProjectInfo next={next} setCurrentPage={setCurrentPage} />
      )}
      {currentPage === 2 && activeCard === 'registrationOpen' && (
        <Registration next={next} />
      )}
      {currentPage === 3 && (
        <AuctionInfo
          isLoading={isLoadingGuest}
          setCurrentPage={setCurrentPage}
        />
      )}
    </Form>
  )
}

export default AddOrderForm
