import { useTranslation } from 'react-i18next'
import { FilterList, FilterListItem } from './FilterBar.styled'

const FilterBar = ({ activeFilter, setActiveFilter }) => {
  const { t } = useTranslation()
  return (
    <FilterList>
      <FilterListItem
        active={activeFilter === 'all'}
        onClick={(e) => {
          setActiveFilter((value) => 'all')
        }}
      >
        <p> {t('auction-bar.all')}</p>
      </FilterListItem>
      <FilterListItem
        active={activeFilter === 'auctions'}
        onClick={(e) => {
          setActiveFilter((value) => 'auctions')
        }}
      >
        <p> {t('auction-bar.auctions')}</p>
      </FilterListItem>
      <FilterListItem
        active={activeFilter === 'offers'}
        onClick={(e) => {
          setActiveFilter((value) => 'offers')
        }}
      >
        <p> {t('auction-bar.offers')}</p>
      </FilterListItem>
      {/* <FilterListItem
        active={activeFilter === 'in-progress'}
        onClick={(e) => {
          setActiveFilter((value) => 'in-progress')
        }}
      >
        W realizacji{' '}
      </FilterListItem>
      <FilterListItem
        active={activeFilter === 'finished'}
        onClick={(e) => {
          setActiveFilter((value) => 'finished')
        }}
      >
        Zakończone{' '}
      </FilterListItem>
      <FilterListItem
        active={activeFilter === 'in-explaining'}
        onClick={(e) => {
          setActiveFilter((value) => 'in-explaining')
        }}
      >
        Wyjaśniane{' '}
      </FilterListItem> */}
    </FilterList>
  )
}

export default FilterBar
