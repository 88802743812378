import styled from 'styled-components'
import logo from '../../../assets/logo_black.svg'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: #e5e7e9;
`
export const BlackLogo = styled.div`
  background-image: url(${logo});
  background-size: 4vw 4vw;
  background-repeat: no-repeat;
  width: 4vw;
  height: 4vw;
`

export const LogoContainer = styled.div`
  margin-bottom: 5vw;
  margin-right: 25vw;
`
export const Header = styled.div`
  width: 100%;
  height: 10vw;
  background-color: #ff771c;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  background-color: white;
  z-index: 9;
  height: 35vw;
  border-radius: 1vw;
  padding: 32px;
  margin-top: 5vw;
  width: 100%;
  max-width: 1024px !important;
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2vw;
  opacity: 0.2;

  gap: 3vw;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2vw;
  svg {
    color: black;
    cursor: pointer;
    height: 1.8vw;
    width: 1.8vw;
  }
  img {
    opacity: 0.2;
  }
`
export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.3vw;
`
