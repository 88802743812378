import { useContext, useState, useEffect, useCallback, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { AiOutlineFile } from 'react-icons/ai'

import { Tooltip } from 'primereact/tooltip'
import { useDropzone } from 'react-dropzone'

import {
  Dropzone,
  AttachmentList,
  AttachmentListItem,
  AttachmentDetails,
} from '@pages/pilot/RegisterPilot/Licenses/Licenses.styled'
import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import FormError from '@components/form/FormError/FormError'
import { Container, Box } from '../CustomerAuction/CustomerAuction.styled'
import TrackingBar from '@components/layout/TrackingBar/TrackingBar'
import {
  RadioContainer,
  RadioButton,
  RadioButtonLabel,
} from '../../../components/ui/OfferInfo/OfferInfo.styled'
import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useGetServices } from '@services/dictionaries.services'
import { AuthContext } from '@context/AuthContext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'

import {
  useDeleteAuction,
  useGetAuctionQuery,
  useUpdateAuction,
} from '@services/auctions.services'
import { Grid } from '@pages/pilot/PilotAuctionList/PilotAuctionList.styled'
import useToast from '@hooks/useToast'
import { EditInformation } from '../CustomerAccount/CustomerAccount.styled'
import { currencies } from '@utils/currencies'
import { useTranslation } from 'react-i18next'

const EditAuction = ({ defaultValues, offer, id, token, locations }) => {
  const [updatedLocations, setUpdatedLocations] = useState({
    regions: [...locations.regions],
    countries: [...locations.countries],
    state: [...locations.states],
    citiesByCountry: [...locations.citiesByCountry],
    citiesByState: [...locations.citiesByState],
  })

  const deleteAuction = useDeleteAuction(id, token)

  const attachments = [
    offer.image1,
    offer.image2,
    offer.image3,
    offer.image4,
    offer.image5,
  ].filter((el) => el !== '')
  const navigate = useNavigate()
  const updateAuction = useUpdateAuction(id, token)
  const [fileName, setFileName] = useState('')
  const [files, setFiles] = useState([...(attachments ?? [])])
  const [currency, setCurrency] = useState(
    currencies.filter((el) => el.code === offer.currency)[0]
  )
  function daysUntilFutureDate(futureDate) {
    const today = new Date()
    const future = new Date(futureDate)
    const difference = future.getTime() - today.getTime()
    const days = Math.ceil(difference / (1000 * 3600 * 24))
    return days
  }
  useEffect(() => {
    setValue(
      'currency',
      currencies.filter((el) => el.code === offer.currency)[0]
    )
  }, [currency])
  const {
    register,
    watch,
    control,
    setValue,
    handleSubmit,

    formState: { errors, isDirty },
  } = useForm({
    defaultValues: { ...offer, ...defaultValues },
  })

  const convertFileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = async () => {
        resolve(reader.result)
      }

      reader.onerror = async (error) => {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  }
  const inputRef = useRef(null)

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const base64files = [...files]
      const updatedFiles = acceptedFiles.map(async (file) => {
        const base64 = await convertFileToBase64(file)
        return { name: file.name, data: base64 }
      })

      const resolvedFiles = await Promise.all(updatedFiles)
      const newFiles = base64files.concat(resolvedFiles)
      setFiles([...newFiles])

      setValue('files', newFiles)
    },
    [files, setValue]
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const [active, setActive] = useState(offer?.oneDay ? 'oneDay' : 'fewDays')
  const { data: services } = useGetServices()
  const [category, setCategory] = useState(
    services?.data?.filter((el) => el.service.id === offer?.serviceId)[0]
  )
  const { showToast, ToastComponent } = useToast()

  const onSubmit = (data) => {
    const newData = {
      ...offer,
      ...data,
    }

    updateAuction.mutate(newData, {
      onSuccess: () => {
        showToast(
          'success',
          'Success',
          t('add-auction.project-info.auction-updated')
        )
        setTimeout(() => {
          navigate('/customer/auctions')
        }, 2000)
      },
    })
  }

  const { t } = useTranslation()

  const handleRemoveFile = (e, data) => {
    e.preventDefault()
    const updatedFiles = files.filter((file) => file !== data)
    setFiles([...updatedFiles])
    setValue('files', [...updatedFiles])
  }

  function downloadBase64File(base64Data, name) {
    const linkSource = base64Data
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = name
    downloadLink.click()
  }

  return (
    <Container>
      {ToastComponent}
      <TrackingBar
        locations={[
          { name: 'DroneConnect', path: '/' },
          { name: 'my-auctions', path: '/customer/auctions' },
          { name: 'edit' },
        ]}
      />
      <Grid style={{ marginTop: 50, marginBottom: 50 }}>
        <div></div>
        <Card width="58vw" form medium>
          <Text margin="8px 0 26px 0" medium>
            {t('add-auction.project-info.edit-auction')}
          </Text>
          {defaultValues && (
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <RadioContainer>
                <RadioButton
                  active={active === 'oneDay'}
                  value={true}
                  disabled
                  onClick={() => setActive((active) => 'oneDay')}
                >
                  <RadioButtonLabel>
                    {' '}
                    {t('add-auction.project-info.one-day')}
                  </RadioButtonLabel>
                </RadioButton>
                <RadioButton
                  active={active === 'fewDays'}
                  value={false}
                  disabled
                  onClick={() => setActive((active) => 'fewDays')}
                >
                  <RadioButtonLabel>
                    {' '}
                    {t('add-auction.project-info.few-days')}
                  </RadioButtonLabel>
                </RadioButton>
              </RadioContainer>

              {active === 'oneDay' ? (
                <FormGroup type="text">
                  <Label> {t('add-auction.project-info.service-date')}</Label>
                  <Controller
                    name="dateFrom"
                    control={control}
                    disabled
                    rules={{
                      required: t('add-auction.project-info.field-required'),
                    }}
                    render={({ field }) => (
                      <Calendar
                        {...field}
                        onChange={(e) => field.onChange(e.value)}
                        showIcon
                        disabled
                        minDate={new Date()}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="dateFrom"
                    render={({ message }) => <FormError message={message} />}
                  />
                </FormGroup>
              ) : (
                <FormGroup type="checkbox">
                  <FormGroup
                    type="text"
                    width="fit-content"
                    margin="0 0.625vw 0px 0"
                  >
                    <Label> {t('add-auction.project-info.service-date')}</Label>
                    <Controller
                      name="dateFrom"
                      control={control}
                      disabled
                      rules={{ required: 'To pole jest wymagane' }}
                      render={({ field }) => (
                        <Calendar
                          {...field}
                          onChange={(e) => field.onChange(e.value)}
                          showIcon
                          disabled
                          minDate={new Date()}
                          selectionMode="range"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="dateFrom"
                      render={({ message }) => <FormError message={message} />}
                    />
                  </FormGroup>
                </FormGroup>
              )}

              <FormGroup type="text">
                <Label> {t('add-auction.project-info.category')}</Label>
                <Dropdown
                  disabled
                  onChange={(e) => {
                    setCategory(e.value)
                    setValue('serviceId', e.value.service.id)
                  }}
                  options={services?.data}
                  optionLabel="name"
                  placeholder={t('add-auction.project-info.pic-category')}
                  className="w-full md:w-14rem"
                  filter
                  value={category}
                />
                <ErrorMessage
                  errors={errors}
                  name="serviceId"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
              <FormGroup type="text">
                <Label> {t('add-auction.project-info.location')}</Label>
                <div
                  style={{
                    display: 'flex',
                    gap: 5,
                    flexDirection: 'column',
                    marginTop: 5,
                  }}
                >
                  <Controller
                    name="locationRegion"
                    control={control}
                    disabled
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        options={locations?.regions ?? []}
                        optionLabel="name"
                        filter
                        disabled
                        placeholder={t('add-auction.project-info.pick-region')}
                        className="w-full md:w-14rem"
                      />
                    )}
                  />

                  <Controller
                    name="locationCountry"
                    control={control}
                    disabled
                    defaultValue={0}
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        options={locations?.countries ?? []}
                        optionLabel="name"
                        filter
                        placeholder={t('add-auction.project-info.pick-country')}
                        className="w-full md:w-14rem"
                      />
                    )}
                  />
                  <Controller
                    name="locationState"
                    control={control}
                    disabled
                    defaultValue={0}
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        options={locations?.states ?? []}
                        optionLabel="name"
                        filter
                        disabled
                        placeholder={t('add-auction.project-info.pick-state')}
                        className="w-full md:w-14rem"
                      />
                    )}
                  />

                  {watch('locationState') ? (
                    <Controller
                      name="locationCity"
                      control={control}
                      disabled
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          options={locations?.citiesByState ?? []}
                          optionLabel="name"
                          filter
                          disabled
                          placeholder={t('add-acution.project-info.pick-city')}
                          className="w-full md:w-14rem"
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      name="locationCity"
                      control={control}
                      disabled
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          options={locations?.citiesByCountry ?? []}
                          optionLabel="name"
                          filter
                          disabled
                          placeholder={t('add-acution.project-info.pick-city')}
                          className="w-full md:w-14rem"
                        />
                      )}
                    />
                  )}
                </div>
              </FormGroup>
              <FormGroup type="text">
                <Label>{t('add-acution.project-info.language')}</Label>
                <Input
                  type="text"
                  width="23.75vw"
                  name="language"
                  disabled
                  {...register('language', {
                    required: 'To pole jest wymagane ',
                  })}
                  hasError={errors.hasOwnProperty('language')}
                />
                <ErrorMessage
                  errors={errors}
                  name="language"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
              <FormGroup type="text">
                <Label
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10,
                  }}
                >
                  <Tooltip target=".description">
                    <div style={{ fontSize: 12, width: 300 }}>
                      {t('add-auction.project-info.tooltip-1')}
                      <br />
                      <br />
                      {t('add-auction.project-info.tooltip-2')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-3')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-4')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-5')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-6')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-7')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-8')}
                      <br />
                      {t('add-auction.project-info.tooltip-9')}
                    </div>
                  </Tooltip>
                  <span style={{ display: 'inline' }}>
                    {' '}
                    {t('add-auction.project-info.description')}
                  </span>
                  <i
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{
                      display: 'inline',
                      marginLeft: 10,
                      cursor: 'pointer',
                    }}
                    className="pi pi-info-circle description"
                  />
                </Label>

                <textarea
                  width="23.75vw"
                  name="description"
                  {...register('description', {
                    required: 'To pole jest wymagane ',
                  })}
                  hasError={errors.hasOwnProperty('description')}
                />
                <ErrorMessage
                  errors={errors}
                  name="description"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
              <FormGroup type="text">
                <Label>{t('add-auction.project-info.background-photo')}</Label>
                <Text secondary small italic>
                  {t('add-auction.project-info.background-photo-desc')}
                </Text>
                <Controller
                  name="background"
                  disabled
                  control={control}
                  render={({ field }) => (
                    <div
                      className="file-upload-container"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '24px',
                      }}
                    >
                      <label className="file-upload-label">
                        <input
                          ref={inputRef}
                          type="file"
                          disabled
                          onChange={(e) => {
                            const file = e.target.files[0]
                            setFileName(e.target.files[0]?.name)
                            const reader = new FileReader()

                            reader.onloadend = () => {
                              field.onChange(reader.result)
                            }

                            reader.readAsDataURL(file)
                          }}
                          className="file-upload-input"
                        />
                        {watch('background') ? (
                          <span className="file-name">{fileName}</span>
                        ) : (
                          <span className="placeholder-text">
                            {t('add-auction.project-info.pick-file')}
                          </span>
                        )}
                        <i className="pi pi-upload" />
                      </label>
                      {watch('background') && (
                        <Button
                          dark
                          margin="7px 0 0 0"
                          outlined
                          onClick={(e) => {
                            e.preventDefault()
                            setValue('background', '')
                          }}
                          disabled
                          label={
                            <p>
                              <RiDeleteBin6Line />{' '}
                              {t('add-auction.project-info.delete')}
                            </p>
                          }
                        />
                      )}
                    </div>
                  )}
                />
              </FormGroup>

              <FormGroup type="text">
                <Label>{t('add-auction.project-info.attachments')}</Label>
                <Text secondary small italic>
                  {t('add-auction.project-info.attachments-desc')}
                </Text>

                <Dropzone {...getRootProps()} className="dropzone" disabled>
                  <input {...getInputProps()} className="dropzone" />
                  <Text medium secondary>
                    {t('add-auction.project-info.drop-files')}
                  </Text>
                  <Button
                    label={t('add-auction.project-info.upload')}
                    primary
                    light
                    type="button"
                    disabled
                  />
                </Dropzone>

                <AttachmentList
                  edit
                  style={{
                    flexWrap: 'wrap',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {files?.map((el, idx) => (
                    <div>
                      <AttachmentListItem
                        onClick={() =>
                          downloadBase64File(
                            el,
                            `${t('add-auction.project-info.attachment')}-${
                              idx + 1
                            }`
                          )
                        }
                      >
                        <AiOutlineFile className="icon" />
                        <AttachmentDetails>
                          <p>
                            {' '}
                            {t('add-auction.project-info.attachment')}-{idx + 1}
                          </p>
                          <span>2MB</span>
                        </AttachmentDetails>
                      </AttachmentListItem>
                      <Button
                        dark
                        outlined
                        disabled
                        onClick={(e) => handleRemoveFile(e, el)}
                        label={
                          <p>
                            <RiDeleteBin6Line />{' '}
                            {t('add-auction.project-info.delete')}
                          </p>
                        }
                        margin="1vw 0 0 0"
                        width="fit-content"
                      />
                    </div>
                  ))}
                </AttachmentList>
              </FormGroup>
              <FormGroup type="checkbox">
                <FormGroup
                  type="text"
                  width="fit-content"
                  margin="0 0.635vw 0px 0"
                >
                  <Label>{t('add-auction.project-info.max-budget')}</Label>

                  <Input
                    type="text"
                    width="8.75vw"
                    name="maxBudget"
                    disabled
                    {...register('maxBudget', {
                      required: 'To pole jest wymagane',
                    })}
                    hasError={errors.hasOwnProperty('maxBudget')}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="maxBudget"
                    render={({ message }) => <FormError message={message} />}
                  />
                </FormGroup>
                <FormGroup
                  type="text"
                  width="fit-content"
                  margin="0 10px 0px 0"
                >
                  <Label>{t('add-auction.project-info.currency')}</Label>

                  <Dropdown
                    value={currency}
                    onChange={(e) => setCurrency(e.value.code)}
                    options={currencies}
                    optionLabel="code"
                    disabled
                    placeholder={
                      currency ?? t('add-auction.project-info.pick-currency')
                    }
                    className="w-full md:w-14rem"
                    filter
                  />
                  <ErrorMessage
                    errors={errors}
                    name="currency"
                    render={({ message }) => <FormError message={message} />}
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup type="text">
                <Label>{t('add-auction.project-info.auction-end-time')}</Label>
                <Controller
                  name="duration"
                  control={control}
                  disabled
                  rules={{ required: 'To pole jest wymagane' }}
                  render={({ field }) => (
                    <Calendar
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.value)
                      }}
                      showIcon
                      minDate={new Date()}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="duration"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>

              <EditInformation style={{ width: '100%' }}>
                <div style={{ display: 'flex', gap: 16 }}>
                  <Button
                    label={
                      updateAuction.isLoading ? (
                        <p>
                          {t('add-auction.project-info.loading')}
                          <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: '14px' }}
                          ></i>{' '}
                        </p>
                      ) : (
                        t('add-auction.project-info.update')
                      )
                    }
                    primary
                    light
                    type="submit"
                  />
                  <Button
                    label={t('add-auction.project-info.rev')}
                    outlined
                    blue
                    light
                    onClick={() => navigate('/customer/auctions')}
                  />
                </div>
                <Button
                  label={
                    deleteAuction.isLoading ? (
                      <p>
                        {t('add-auction.project-info.deleting')}
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: '14px' }}
                        ></i>{' '}
                      </p>
                    ) : (
                      t('add-auction.project-info.delete-auction')
                    )
                  }
                  style={{ background: '#E32224', color: 'white' }}
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault()
                    deleteAuction.mutate(null, {
                      onSuccess: () => {
                        showToast(
                          'success',
                          'Success',
                          t('add-auction.project-info.delete-toast-auction')
                        )
                        setTimeout(() => {
                          navigate('/customer/auctions')
                        }, 2000)
                      },
                    })
                  }}
                />
              </EditInformation>
            </form>
          )}
        </Card>
        <div></div>
      </Grid>
    </Container>
  )
}

export default EditAuction
