import { Txt } from './Text.styled'

const Text = ({
  children,
  small,
  medium,
  large,
  onClick,
  primary,
  secondary,
  inline,
  block,
  center,
  margin,
  italic,
  error,
  success,
  white,
}) => {
  return (
    <Txt
      italic={italic}
      small={small}
      medium={medium}
      large={large}
      onClick={onClick}
      primary={primary}
      secondary={secondary}
      inline={inline}
      block={block}
      center={center}
      margin={margin}
      error={error}
      success={success}
      white={white}
    >
      {children}
    </Txt>
  )
}

export default Text
