import { useState } from 'react'
import { Controller } from 'react-hook-form'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import { ImageContainer, ImagePlaceholder, Image } from './FileUploader.styled'

const FileUploader = ({ name, setValue, currentAvatar, isSuccess }) => {
  const [selectedImage, setSelectedImage] = useState(null)

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onloadend = () => {
      setSelectedImage(reader.result)
    }

    reader.readAsDataURL(file)
    if (file) {
      const readerBase64 = new FileReader()
      readerBase64.onloadend = () => {
        setValue(name, readerBase64.result)
      }
      readerBase64.readAsDataURL(file)
    }
  }

  const handleRemoveImage = () => {
    setSelectedImage(null)
  }

  return (
    <ImageContainer>
      {currentAvatar && !selectedImage && (
        <Image src={currentAvatar} alt="Selected" />
      )}
      {currentAvatar && selectedImage && (
        <>
          <Image src={selectedImage} alt="Selected" />
          {isSuccess && (
            <Button
              label="Usuń zdjęcie"
              outlined
              blue
              type="button"
              onClick={handleRemoveImage}
            />
          )}
        </>
      )}

      {!currentAvatar && selectedImage && (
        <>
          <Image src={selectedImage} alt="Selected" />
          {isSuccess && (
            <Button
              label="Usuń zdjęcie"
              outlined
              blue
              type="button"
              onClick={handleRemoveImage}
            />
          )}
        </>
      )}
      {!currentAvatar && !selectedImage && <ImagePlaceholder />}

      <label
        htmlFor="fileInput"
        className="btn"
        style={{ width: '150px', textAlign: 'center' }}
      >
        Dodaj zdjęcie
      </label>

      <input
        id="fileInput"
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => {
          handleImageChange(e)
        }}
      />

      <Text small secondary inline>
        Dozwolone typy plików: jpg, jpeg, png. Max wielkość pliku: X MB
      </Text>
    </ImageContainer>
  )
}

export default FileUploader
