import styled from 'styled-components'

export const Group = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => props.type === 'text' && 'column'};
  flex-direction: ${(props) => props.type === 'checkbox' && 'row'};
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  align-items: ${(props) => props.type === 'checkbox' && 'center'};
  margin-bottom: 14px;
  margin: ${(props) => props.margin && props.margin};
  width: fit-content;
  width: ${(props) => props.width && props.width};
  select {
    background-color: #ffffff;
    height: 40px;
    border: 1px solid transparent;
    color: #6c757d;
    border-radius: 8px;
    padding-left: 12px;

    font-size: 14px;

    &:focus {
      border: 1px solid #ff771c;
      outline: none;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
`
