import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import PasswordRequirements from '@components/ui/PasswordRequirements/PasswordRequirements'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import Select from '@components/form/Select/Select'
import FormError from '@components/form/FormError/FormError'
import { useFormContext } from 'react-hook-form'
import { motion } from 'framer-motion'
import { ErrorMessage } from '@hookform/error-message'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

const Registration = ({ next }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Card width="58vw" form>
        <Text margin="0.625vw 0 2vw 0" medium>
          {t('registration.description')}
        </Text>

        {/* TODO: avatar */}

        <FormGroup type="text">
          <Label>{t('registration.first-name')}</Label>
          <Input
            type="text"
            width="13.75vw"
            name="firstName"
            {...register('firstName', {
              required: t('registration.field-required'),
            })}
            hasError={errors.hasOwnProperty('firstName')}
          />
          <ErrorMessage
            errors={errors}
            name="firstName"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        <FormGroup type="text">
          <Label>{t('registration.last-name')}</Label>
          <Input
            type="text"
            width="23.75vw"
            name="lastName"
            {...register('lastName', {
              required: t('registration.field-required'),
            })}
            hasError={errors.hasOwnProperty('lastName')}
          />
          <ErrorMessage
            errors={errors}
            name="lastName"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        <FormGroup type="text">
          <Label>{t('registration.e-mail')}</Label>
          <Input
            type="email"
            width="23.75vw"
            name="email"
            {...register('email', {
              required: t('registration.field-required'),
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t('registration.invalid-email'),
              },
            })}
            hasError={errors.hasOwnProperty('email')}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        <FormGroup type="checkbox">
          <FormGroup type="text" margin="0">
            <Label>{t('registration.phone-number')}</Label>
            <Input
              type="text"
              width="11.875vw"
              name="phone"
              {...register('phone', {
                required: false,
              })}
            />
          </FormGroup>
        </FormGroup>

        <FormGroup type="checkbox">
          <Input
            type="checkbox"
            width="1.2vw"
            value={true}
            name="company"
            {...register('company')}
          />
          <Label>{t('registration.as-company')}</Label>
        </FormGroup>

        {watch('company') && (
          <>
            <FormGroup type="text">
              <Label>{t('registration.company-name')}</Label>

              <Input
                type="text"
                width="23.75vw"
                name="companyName"
                {...register('companyName')}
              />
            </FormGroup>
            <FormGroup type="text">
              <Label>{t('registration.tax-id')}</Label>

              <Input
                type="text"
                width="11.875vw"
                name="nip"
                {...register('nip')}
              />
            </FormGroup>
            <FormGroup type="text">
              <Label>{t('registration.country')}</Label>

              <Input
                type="text"
                width="23.75vw"
                name="country"
                {...register('country')}
              />
            </FormGroup>
            <FormGroup type="checkbox">
              <FormGroup
                type="text"
                width="fit-content"
                margin="0 0.635vw 0px 0"
              >
                <Label>{t('registration.street')}</Label>
                <Input
                  type="text"
                  width="11.875vw"
                  {...register('addresss1')}
                  name="address1"
                />
              </FormGroup>
              <FormGroup type="text" margin="0">
                <Label>{t('registration.home-number')}</Label>
                <Input
                  type="text"
                  width="11.875vw"
                  name="address2"
                  {...register('address2')}
                />
              </FormGroup>
            </FormGroup>
            <FormGroup type="checkbox">
              <FormGroup
                type="text"
                width="fit-content"
                margin="0 0.635vw 0px 0"
              >
                <Label>{t('registration.city')}</Label>
                <Input
                  type="text"
                  width="11.875vw"
                  {...register('city')}
                  name="city"
                />
              </FormGroup>
              <FormGroup type="text" margin="0">
                <Label>{t('registration.post-number')}</Label>
                <Input
                  type="text"
                  width="11.875vw"
                  name="postalCode"
                  {...register('postalCode')}
                />
              </FormGroup>
            </FormGroup>
          </>
        )}

        <FormGroup type="text">
          <Label>{t('registration.password')}</Label>
          <Input
            type="password"
            width="23.75vw"
            name="password"
            {...register('password', {
              required: t('registration.filed-required'),
            })}
            hasError={errors.hasOwnProperty('password')}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        <PasswordRequirements watch={watch} />

        <FormGroup type="checkbox">
          <Input
            width="1vw"
            type="checkbox"
            name="privacyPolicyAgreement"
            {...register('privacyPolicyAgreement', {
              required: 'To pole jest wymagane',
            })}
          />
          <Label>{t('registration.privacy-policy-agreement')}</Label>
          <ErrorMessage
            errors={errors}
            name="privacyPolicyAgreement"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        <Button
          label={t('registration.next')}
          primary
          light
          onClick={(e) => {
            e.preventDefault()
            next([
              'firstName',
              'lastName',
              'phone',
              'email',
              'password',
              'privacyPolicyAgreement',
            ])
          }}
        />
      </Card>
    </motion.div>
  )
}

export default Registration
