import {
  Container,
  Header,
  Content,
  Footer,
  Top,
  Bottom,
  LogoContainer,
  BlackLogo,
} from './SecondaryLayout.styled'
import Text from '../../ui/Text/Text'
import { BsFacebook, BsLinkedin, BsTwitter } from 'react-icons/bs'
import { Logo } from '../Navbar/Navbar.styled'

const SecondaryLayout = ({ children }) => {
  return (
    <Container>
      <Header>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Header>
      <Content>{children}</Content>
      <Footer>
        <Top>
          <BsFacebook />
          <BsLinkedin />
          <BsTwitter />
        </Top>
        <div className="logo">
          <BlackLogo />{' '}
        </div>
        <Bottom>
          <Text small>Copyright © 2023,</Text>
          <Text small>DRONE CONNECT</Text>
          <Text small>Wroclaw, Poland</Text>
        </Bottom>
      </Footer>
    </Container>
  )
}

export default SecondaryLayout
