import ProgressBar, {
  useHandleProgressBar,
} from '@components/layout/ProgressBar/ProgressBar'
import { FormProvider, useForm } from 'react-hook-form'
import Text from '@components/ui/Text/Text'
import { Content, Form, FormContent } from './RegisterCustomer.styled'
import Spinner from '@components/ui/Spinner/Spinner'
import Button from '@components/ui/Button/Button'
import { useRegisterUser } from '@services/auth.services'
import PasswordRequirements from '@components/ui/PasswordRequirements/PasswordRequirements'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'

import FormError from '@components/form/FormError/FormError'
import { ErrorMessage } from '@hookform/error-message'
import { useNavigate } from 'react-router'
import FileUploader from '@components/form/FileUploader/FileUploader'
import { useTranslation } from 'react-i18next'

const RegisterCustomer = () => {
  const navigate = useNavigate()
  const methods = useForm()
  const {
    trigger,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = methods

  const { currentPage } = useHandleProgressBar(trigger)
  const registerUser = useRegisterUser()
  async function onSubmit(data) {
    const newData = {
      ...data,
      pilot: false,
      isCompany: data.isCompany === false ? false : true,
    }

    registerUser.mutate(newData, {
      onSuccess: () => navigate('/account-confirmation/created'),
    })
  }

  const { t } = useTranslation()

  return (
    <Content>
      <ProgressBar currentPage={currentPage} registerCustomer />
      <FormProvider {...methods}>
        {currentPage === 1 && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormContent>
              <FormGroup width="100%">
                <FileUploader
                  setValue={setValue}
                  currentAvatar={null}
                  name="avatar"
                />
              </FormGroup>

              <FormGroup type="text" margin="0 0 24px 0">
                <Label>{t('registration.first-name')}</Label>
                <Input
                  type="text"
                  width="13.75vw"
                  name="firstName"
                  {...register('firstName', {
                    required: t('registration.field-required'),
                  })}
                  hasError={errors.hasOwnProperty('firstName')}
                />
                <ErrorMessage
                  errors={errors}
                  name="firstName"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>

              <FormGroup type="text" margin="0 0 24px 0">
                <Label>{t('registration.last-name')}</Label>
                <Input
                  type="text"
                  width="23.75vw"
                  name="lastName"
                  {...register('lastName', {
                    required: t('registration.field-required'),
                  })}
                  hasError={errors.hasOwnProperty('lastName')}
                />
                <ErrorMessage
                  errors={errors}
                  name="lastName"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>

              <FormGroup type="text" margin="0 0 24px 0">
                <Label>{t('registration.e-mail')}</Label>
                <Input
                  type="email"
                  width="23.75vw"
                  name="email"
                  {...register('email', {
                    required: t('registration.field-required'),
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Niepoprawny adres e-mail',
                    },
                  })}
                  hasError={errors.hasOwnProperty('email')}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>

              <FormGroup type="text" margin="0 0 24px 0">
                <Label>{t('registration.phone-number')}</Label>
                <Input
                  type="text"
                  width="11.875vw"
                  name="phone"
                  {...register('phone', {
                    required: false,
                  })}
                />
              </FormGroup>

              <FormGroup type="checkbox" margin="0 0 24px 0">
                <Input
                  type="checkbox"
                  width="1.2vw"
                  value={true}
                  name="isCompany"
                  {...register('isCompany')}
                />
                <Label>{t('registration.as-company')}</Label>
              </FormGroup>

              {watch('isCompany') && (
                <>
                  <FormGroup type="text" margin="0 0 24px 0">
                    <Label>{t('registration.company-name')}</Label>

                    <Input
                      type="text"
                      width="23.75vw"
                      name="companyDetails.companyName"
                      {...register('companyDetails.companyName', {
                        required: watch('isCompany')
                          ? t('registration.field-required')
                          : false,
                      })}
                      hasError={errors.hasOwnProperty(
                        'companyDetails.companyName'
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="companyDetails.companyName"
                      render={({ message }) => <FormError message={message} />}
                    />
                  </FormGroup>
                  <FormGroup type="text" margin="0 0 24px 0">
                    <Label>{t('registration.tax-id')}</Label>

                    <Input
                      type="text"
                      width="11.875vw"
                      name="companyDetails.vatCode"
                      {...register('companyDetails.vatCode', {
                        required: watch('isCompany')
                          ? t('registration.field-required')
                          : false,
                      })}
                      hasError={errors.hasOwnProperty('companyDetails.vatCode')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="companyDetails.vatCode"
                      render={({ message }) => <FormError message={message} />}
                    />
                  </FormGroup>
                  <FormGroup type="text" margin="0 0 24px 0">
                    <Label>{t('registration.country')}</Label>

                    <Input
                      type="text"
                      width="23.75vw"
                      name="companyDetails.countryCode"
                      {...register('companyDetails.countryCode', {
                        required: watch('isCompany')
                          ? t('registration.field-required')
                          : false,
                      })}
                      hasError={errors.hasOwnProperty(
                        'companyDetails.countryCode'
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="companyDetails.countryCode"
                      render={({ message }) => <FormError message={message} />}
                    />
                  </FormGroup>
                  <FormGroup type="checkbox" margin="0 0 24px 0">
                    <FormGroup type="text" width="fit-content">
                      <Label>{t('registration.street')}</Label>
                      <Input
                        type="text"
                        width="11.875vw"
                        {...register('companyDetails.addressLine1', {
                          required: watch('isCompany')
                            ? t('registration.field-required')
                            : false,
                        })}
                        name="companyDetails.addressLine1"
                        hasError={errors.hasOwnProperty(
                          'companyDetails.addressLine1'
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="companyDetails.addressLine1"
                        render={({ message }) => (
                          <FormError message={message} />
                        )}
                      />
                    </FormGroup>
                    <FormGroup type="text">
                      <Label>{t('registration.home-number')}</Label>
                      <Input
                        type="text"
                        width="11.875vw"
                        name="companyDetails.addressLine2"
                        {...register('companyDetails.addressLine2', {
                          required: watch('isCompany')
                            ? t('registration.field-required')
                            : false,
                        })}
                        hasError={errors.hasOwnProperty(
                          'companyDetails.addressLine2'
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="companyDetails.addressLine2"
                        render={({ message }) => (
                          <FormError message={message} />
                        )}
                      />
                    </FormGroup>
                  </FormGroup>
                  <FormGroup type="text">
                    <FormGroup
                      type="text"
                      width="fit-content"
                      margin="0 0 24px 0"
                    >
                      <Label>{t('registration.city')}</Label>
                      <Input
                        type="text"
                        width="11.875vw"
                        {...register('companyDetails.city', {
                          required: watch('isCompany')
                            ? t('registration.field-required')
                            : false,
                        })}
                        name="companyDetails.city"
                        hasError={errors.hasOwnProperty('companyDetails.city')}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="companyDetails.city"
                        render={({ message }) => (
                          <FormError message={message} />
                        )}
                      />
                    </FormGroup>
                    <FormGroup type="text" margin="0 0 24px 0">
                      <Label>{t('registration.post-code')}</Label>
                      <Input
                        type="text"
                        width="11.875vw"
                        name="companyDetails.postCode"
                        {...register('companyDetails.postCode', {
                          required: watch('isCompany')
                            ? t('registration.field-required')
                            : false,
                        })}
                        hasError={errors.hasOwnProperty(
                          'companyDetails.postCode'
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="companyDetails.postCode"
                        render={({ message }) => (
                          <FormError message={message} />
                        )}
                      />
                    </FormGroup>
                  </FormGroup>
                </>
              )}

              <FormGroup type="text" margin="0 0 24px 0">
                <Label>{t('registration.password')}</Label>
                <Input
                  type="password"
                  width="23.75vw"
                  name="password"
                  {...register('password', {
                    required: t('registration.field-required'),
                  })}
                  hasError={errors.hasOwnProperty('password')}
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>

              <PasswordRequirements
                watch={watch}
                name="password"
                email="email"
              />

              <FormGroup type="checkbox" margin="30px 0 0 0">
                <Input
                  width="1vw"
                  type="checkbox"
                  name="privacyPolicyAgreement"
                  {...register('privacyPolicyAgreement', {
                    required: t('registration.field-required'),
                  })}
                />
                <Label>{t('registration.privacy-policy-agreement')}</Label>
                <ErrorMessage
                  errors={errors}
                  name="privacyPolicyAgreement"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
              <FormGroup type="checkbox" margin="20px 0 30px 0">
                <Input
                  width="1vw"
                  type="checkbox"
                  name="notifications"
                  {...register('notifications', {
                    required: t('registration.field-required'),
                  })}
                />
                <Label>{t('registration.notifications')}</Label>
                <ErrorMessage
                  errors={errors}
                  name="notifications"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>

              {!registerUser.isLoading && (
                <Button
                  label={t('registration.next')}
                  primary
                  light
                  type="submit"
                />
              )}
              {registerUser.isLoading && <Spinner size="small" />}
            </FormContent>
          </Form>
        )}
      </FormProvider>
      <div />
    </Content>
  )
}

export default RegisterCustomer
