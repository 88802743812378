import {
  Container,
  Popup,
  Box,
  IconContainer,
  MainContainer,
} from './LoginPopup.styled'
import { useNavigate } from 'react-router-dom'
import Card from '@components/ui/Card/Card'
import Headline from '@components/ui/Headline/Headline'
import Text from '@components/ui/Text/Text'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import FormError from '@components/form/FormError/FormError'
import Button from '@components/ui/Button/Button'
import { GrClose } from 'react-icons/gr'
import { useForm } from 'react-hook-form'
import { motion } from 'framer-motion'
import { ErrorMessage } from '@hookform/error-message'
import { useTranslation } from 'react-i18next'
const LoginPopup = ({ open, setOpen, actionAfterLogin }) => {
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  function onSubmit(data) {
    actionAfterLogin(data)
    setOpen((open) => false)
  }
  const { t } = useTranslation()

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container>
        <Popup>
          <Card width="31vw" center>
            <MainContainer>
              <IconContainer onClick={() => setOpen((open) => false)}>
                <GrClose />
              </IconContainer>
              <Headline medium secondary margin="8px 0" center>
                {t('login-modal.title')}
              </Headline>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup type="text" width="100%">
                  <Label>{t('login-modal.email')}</Label>
                  <Input
                    type="email"
                    name="email"
                    width="100%"
                    {...register('email', {
                      required: t('login-modal.field-required'),
                    })}
                    hasError={errors.hasOwnProperty('email')}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => <FormError message={message} />}
                  />
                </FormGroup>
                <FormGroup type="text" width="100%">
                  <Label>{t('login-modal.password')}: </Label>
                  <Input
                    type="password"
                    name="password"
                    width="100%"
                    {...register('password', {
                      required: t('login-modal.filed-required'),
                    })}
                    hasError={errors.hasOwnProperty('password')}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => <FormError message={message} />}
                  />
                </FormGroup>
                <Button
                  label={t('login-modal.login')}
                  type="submit"
                  primary
                  light
                />
              </form>
              <Box>
                <Text large>{t('login-modal.do-not-have-account')}</Text>
                <Text
                  primary
                  large
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/registration')
                  }}
                >
                  {t('login-modal.register')}
                </Text>
              </Box>
            </MainContainer>
          </Card>
        </Popup>
      </Container>
    </motion.div>
  )
}

export default LoginPopup
