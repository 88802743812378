import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 5vw 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.625vw;
  width: 70vw;
  p {
    text-align: center;
  }
  svg {
    width: 3vw;
    color: #26c35c;
    height: 3vw;
  }
`
