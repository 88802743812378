import styled from 'styled-components'

export const Container = styled.div`
  background-color: #00022a;
  max-width: 100vw;
  display: flex;
  padding: 64px 32px;
  display: flex;
  justify-content: center;
  gap: 16px;
  position: relative;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  svg {
    height: 24px !important;
    width: 24px;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  div {
    @media only screen and (max-width: 600px) {
      width: 300px;
      height: fit-content;
    }
  }
  h3 {
    font-size: 16px;
  }
`
export const Subtitle = styled.h5`
  margin-bottom: 16px;
  margin-top: 16px;
  color: #ffa426;
  font-size: 14px;
  font-weight: 400;
`
export const Copy = styled.p`
  color: #3d3d3d;
  font-weight: 400;
  font-size: 14px;
  width: 16.5vw;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 16px 8px;
  }
`
