import React from 'react'
import { Link } from 'react-router-dom'

import { Container, Text, Separator } from './TrackingBar.styled'
import { useTranslation } from 'react-i18next'

const TrackingBar = ({
  locations = [
    { name: 'DroneConnect', path: '/drone' },
    { name: 'my-accounts', path: '/account' },
  ],
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      {locations.map((location, index) => (
        <React.Fragment key={index}>
          <Link to={location.path}>
            <Text>{t(`tracking-bar.${location.name}`)}</Text>
          </Link>
          {index !== locations.length - 1 && <Separator />}
        </React.Fragment>
      ))}
    </Container>
  )
}

export default TrackingBar
