import { api } from './api.service'

export const getAuctions = async (token) => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/auctions/admin`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
  return res
}

export const getUserAuctions = async (token) => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/auctions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const getAuction = async (id, token) => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/auctions/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const createAuction = async (token, data) => {
  const res = await api.post(
    `${process.env.REACT_APP_API_URL}/auctions`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}
export const updateAuction = async (token, data, id) => {
  const res = await api.put(
    `${process.env.REACT_APP_API_URL}/auctions/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}

export const createAuctionAsGuest = async (data) => {
  const res = await api.post(
    `${process.env.REACT_APP_API_URL}/auctions/admin`,
    data,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    }
  )
  return res
}

export const deleteAuction = async (id, token) => {
  const res = await api.delete(
    `${process.env.REACT_APP_API_URL}/auctions/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}
