import { Text } from './Headline.styled'

const Headline = ({
  children,
  large,
  small,
  medium,
  primary,
  secondary,
  margin,
  center,
}) => {
  return (
    <Text
      large={large}
      medium={medium}
      small={small}
      primary={primary}
      secondary={secondary}
      margin={margin}
      center={center}
    >
      {children}
    </Text>
  )
}

export default Headline
