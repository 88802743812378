import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  padding: 2vw 10vw 2vw 2vw;
  height: fit-content;
`

export const ListItem = styled.li`
  width: 100%;
  display: flex;
  height: 200px;
`

export const AuctionImage = styled.div`
  width: 15.37vw;
  width: 15.37vw;
  background-image: ${(props) => props.src && `url(${props.src})`};
  background-size: cover;
  background-position: center;
`

export const AuctionEndTime = styled.div`
  color: white;
  background-color: #9245f6;
  padding: 0.25vw 0.5vw;
  border-radius: 0.25vw;
  font-size: 0.75vw;
  font-weight: 300;
  display: flex;
  gap: 0.5vw;
  margin-bottom: 1vw;
  flex-wrap: nowrap;
  width: 11.5vw;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 0.8vw;
  }
`

export const ContentBox = styled.div`
  width: calc(100% - 12.37vw);
  height: 100%;
  padding: 0 1.5vw;
  display: flex;
  flex-direction: column;
`

export const GeneralInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
  gap: 0.5vw;
  margin-bottom: 1.5vw;
`
export const GeneralInfoTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
export const GeneralInfoTopCategory = styled.div`
  color: #3b82f6;
  background-color: rgba(59, 130, 246, 0.1);
  font-size: 0.8vw;
  padding: 0.25vw 0.5vw;
  border-radius: 0.25vw;
`
export const GeneralInfoTopTooltips = styled.div`
  display: flex;
  width: fit-content;
  gap: 1.5vw;
  align-items: center;
  color: #3d3d3d;
`
export const GeneralInfoTopTooltip = styled.div`
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  gap: 0.5vw;
`
export const GeneralInfoBottom = styled.div`
  color: #3d3d3d;
  font-size: 1.2vw;
  margin-top: 0.5vw;
`

export const Details = styled.div`
  height: 40%;
  margin-bottom: 1.5vw;
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
`
export const DetailsRow = styled.div`
  font-size: 1vw;
  display: flex;
`

export const DetailsRowTitle = styled.p`
  color: #6c757d;
  width: 20%;
  font-weight: 400;
  line-height: 1.3vw;
`
export const DetailsRowData = styled.p`
  color: #3d3d3d;
  display: flex;
  align-items: center;
  gap: 0.5vw;
  line-height: 1.2vw;
`

export const Description = styled.div`
  color: white;
  display: flex;
  color: #3d3d3d;
  align-items: center;
  gap: 1.5vw;
  justify-content: space-between;
  font-size: 1vw;
  line-height: 1.5vw;
`

export const AttachmentsList = styled.li`
  list-style: none;
  color: #3d3d3d;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8vw;
`

export const AttachmentListItem = styled.ul`
  display: flex;
  align-items: center;
  gap: 3vw;
  font-size: 1vw;
`
