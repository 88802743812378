import {
  Container,
  Box,
  Title,
  Subtitle,
  ButtonContainer,
  CtaSection,
} from './Hero.styled'
import { Tooltip } from 'primereact/tooltip'
import Button from '@components/ui/Button/Button'
import HeroImage from '@assets/hero_big.png'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Hero = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Container>
      <Box>
        <Title>
          {t('home.hero.title-1')}
          <br />
          {t('home.hero.title-2')} <span> {t('home.hero.title-3')}</span>
        </Title>
        <Subtitle>
          {t('home.hero.subtitle-1')}
          <br />
          {t('home.hero.subtitle-2')}{' '}
        </Subtitle>
        <CtaSection>
          <ButtonContainer>
            <Button
              primary
              large
              light
              label={t('home.hero.order-service')}
              margin="3vw 0 0 0"
              onClick={(e) => {
                e.preventDefault()
                navigate('/add-order')
              }}
            />
            <Tooltip target=".auction-info">
              <div style={{ fontSize: 12, width: 300 }}>
                {t('home.hero.add-auction')}
              </div>
            </Tooltip>

            <div className="auction-info">
              <AiOutlineQuestionCircle />
              <p>{t('home.hero.more-info')}</p>
            </div>
          </ButtonContainer>
          <ButtonContainer>
            <Button
              outlined
              large
              light
              label={t('home.hero.collect-offer')}
              margin="3vw 0 0 0.5vw"
              onClick={(e) => {
                e.preventDefault()
                navigate('/collect-offers')
              }}
            />
            <Tooltip target=".offer-info">
              <div style={{ fontSize: 12, width: 300 }}>
                {t('home.hero.add-offer')}
              </div>
            </Tooltip>
            <div className="offer-info">
              <AiOutlineQuestionCircle />
              <p>{t('home.hero.more-info')}</p>
            </div>
          </ButtonContainer>
        </CtaSection>
      </Box>
      <Box>
        <img src={HeroImage} />
      </Box>
    </Container>
  )
}

export default Hero
