import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import FormGroup from '@components/form/FormGroup/FormGroup'
import { useEffect, useState } from 'react'
import { currencies } from '@utils/currencies'
import { Dropdown } from 'primereact/dropdown'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import FormError from '@components/form/FormError/FormError'
import { useFormContext, Controller } from 'react-hook-form'
import { motion } from 'framer-motion'
import { ErrorMessage } from '@hookform/error-message'
import { Calendar } from 'primereact/calendar'
import { useTranslation } from 'react-i18next'

const AuctionInfo = ({ isLoading, setCurrentPage }) => {
  const { t } = useTranslation()
  const [currency, setCurrency] = useState(null)
  const {
    register,
    setValue,
    control,
    formState: { errors, isDirty },
  } = useFormContext()

  useEffect(() => {
    setValue('currency', currency)
  }, [currency])

  const today = new Date()
  let month = today.getMonth()
  let year = today.getFullYear()
  let day = today.getDate()
  let prevMonth = month === 0 ? 11 : month - 14
  let prevYear = prevMonth === 11 ? year - 1 : year - 1
  let minDate = new Date()
  minDate.setDate(day)
  minDate.setMonth(month)
  minDate.setFullYear(prevYear)
  let maxDate = new Date()

  maxDate.setDate(today.getDate() + 14)

  const validateDate = (value) => {
    if (value < today) {
      return 'Selected date cannot be in the past.'
    }
    return true
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%' }}
    >
      <Card width="100%">
        <Text margin="12px 0 24px 0" small>
          {t('add-auction.auction-info.title')}
        </Text>
        <FormGroup type="checkbox">
          <FormGroup type="text" width="fit-content" margin="0 0.635vw 0px 0">
            <Label>{t('add-auction.auction-info.max-budget')}</Label>

            <Input
              type="number"
              width="8.75vw"
              name="maxBudget"
              {...register('maxBudget', {
                required: t('add-auction.project-info.field-required'),
              })}
              hasError={errors.hasOwnProperty('maxBudget')}
            />
            <ErrorMessage
              errors={errors}
              name="maxBudget"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>
          <FormGroup type="text" width="fit-content" margin="0 10px 0px 0">
            <Label>{t('add-auction.auction-info.currency')}</Label>

            <Dropdown
              value={currency}
              onChange={(e) => setCurrency(e.value.code)}
              options={currencies}
              optionLabel="code"
              placeholder={
                currency ?? t('add-auction.auction-info.pick-currency')
              }
              className="w-full md:w-14rem"
              filter
            />

            {!currency && (
              <FormError
                message={t('add-auction.project-info.field-required')}
              />
            )}
          </FormGroup>
        </FormGroup>
        <FormGroup type="text">
          <Label>{t('add-auction.auction-info.end-date')}</Label>
          <Controller
            name="duration"
            control={control}
            rules={{
              required: t('add-auction.project-info.field-required'),
              validate: validateDate,
            }}
            render={({ field }) => (
              <Calendar
                {...field}
                onChange={(e) => field.onChange(e.value)}
                showIcon
                minDate={minDate}
                maxDate={maxDate}
                readOnlyInput
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="duration"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>
        {!isLoading && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button
              label={t('add-auction.auction-info.back')}
              outlined
              dark
              onClick={(e) => {
                e.preventDefault()
                setCurrentPage((prev) => prev - 1)
              }}
            />
            <Button
              label={t('add-auction.auction-info.add')}
              primary
              light
              type="submit"
            />
          </div>
        )}
        {isLoading && (
          <Button
            label={
              <p>
                {t('add-auction.auction-info.loading')}
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: '14px' }}
                ></i>
              </p>
            }
            primary
            light
          />
        )}
      </Card>
    </motion.div>
  )
}

export default AuctionInfo
