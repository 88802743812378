import { Container, Content, ButtonContainer, IconContainer } from './OfferConfirmation.styled'
import { useNavigate } from 'react-router-dom'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import { motion } from 'framer-motion'
import { BsCheck2 } from 'react-icons/bs'

const OfferConfirmation = ({ guest }) => {
  const navigate = useNavigate()

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container>
        <Content>
          <IconContainer>
            <BsCheck2 />
          </IconContainer>

          <Text large>Twoja aukcja została dodana</Text>

          <Text small center>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex ea commodo consequat.
          </Text>

          <ButtonContainer>
            <Button primary light label="Strona główna" onClick={() => navigate('/')} />
          </ButtonContainer>
        </Content>
      </Container>
    </motion.div>
  )
}

export default OfferConfirmation
