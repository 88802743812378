import ProgressBar, {
  useHandleProgressBar,
} from '@components/layout/ProgressBar/ProgressBar'
import { FormProvider, useForm } from 'react-hook-form'
import { Content } from './RegisterPilot.styled'
import AccountInformation from './AccountInformation/AccountInformation'
import Licenses from './Licenses/Licenses'
import Preferences from './Preferences/Preferences'
import { useNavigate } from 'react-router'
import { useRegisterPilot } from '@services/auth.services'
import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const RegisterPilot = () => {
  const schema = yup.object().shape({
    pilot: yup.object().shape({
      operatorExpiryDate: yup.date().required('To pole jest wymagane'),
      agreement: yup
        .boolean()
        .test('true', 'To pole jest wymagane', (val) => val === true),
      licenseExpiryDate: yup.date().required('To pole jest wymagane'),
      operatorLicenseNumber: yup.string().required('To pole jest wymagane'),
      pilotLicenseNumber: yup.string().required('To pole jest wymagane'),
      encodedLicenseCategoriesJson: yup
        .array()
        .required('To pole jest wymagane'),
    }),
    user: yup.object().shape({
      firstName: yup.string().required('To pole jest wymagane'),
      lastName: yup.string().required('To pole jest wymagane'),
      email: yup
        .string()
        .email('Podaj poprawny adres e-mail')
        .required('To pole jest wymagane'),
      isCompany: yup.boolean(),
      privacyPolicyAgreement: yup
        .boolean()
        .test('true', 'To pole jest wymagane', (val) => val === true),
      notifications: yup
        .boolean()
        .test('true', 'To pole jest wymagane', (val) => val === true),
      companyDetails: yup.object().when('isCompany', {
        is: true,
        then: yup.object().shape({
          companyName: yup.string().required('To pole jest wymagane'),
          vatCode: yup.string().required('To pole jest wymagane'),
          countryCode: yup.string().required('To pole jest wymagane'),
          addressLine1: yup.string().required('To pole jest wymagane'),
          addressLine2: yup.string().required('To pole jest wymagane'),
          city: yup.string().required('To pole jest wymagane'),
          postCode: yup.string().required('To pole jest wymagane'),
        }),
      }),

      password: yup
        .string()
        .required('To pole jest wymagane')
        .matches(/[a-z]/, 'Hasło musi zawierać co najmniej jedną małą literę')
        .matches(/[A-Z]/, 'Hasło musi zawierać co najmniej jedną wielką literę')
        .matches(/\d/, 'Hasło musi zawierać co najmniej jedną cyfrę')
        .min(8, 'Hasło musi zawierać co najmniej 8 znaków'),
    }),
  })

  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const { trigger, handleSubmit, watch } = methods
  const { currentPage, next, setCurrentPage } = useHandleProgressBar(trigger)
  const navigate = useNavigate()
  const registerPilot = useRegisterPilot()

  const onSubmit = async (data) => {
    const newData = {
      ...data,
      pilot: {
        ...data.pilot,
        pilot: true,
        preferredLocationRegion: data.pilot.preferredLocationRegion.id ?? 0,
        preferredLocationCountry: data.pilot.preferredLocationCountry.id ?? 0,
        preferredLocationState: data.pilot.preferredLocationState.id ?? 0,
        preferredLocationCity: data.pilot.preferredLocationCity.id ?? 0,
      },
    }
    registerPilot.mutate(newData)
  }

  useEffect(() => {
    if (registerPilot.isSuccess) {
      setTimeout(() => navigate('/account-confirmation/created'), 2000)
    }
  }, [registerPilot.isSuccess])
  return (
    <Content>
      <ProgressBar currentPage={currentPage} registerPilot />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {currentPage === 1 && <AccountInformation next={next} />}
          {currentPage === 2 && (
            <Licenses next={next} setCurrentPage={setCurrentPage} />
          )}
          {currentPage === 3 && (
            <Preferences
              next={next}
              isLoading={registerPilot.isLoading}
              isError={registerPilot.isError}
              setCurrentPage={setCurrentPage}
            />
          )}
        </form>
      </FormProvider>
    </Content>
  )
}

export default RegisterPilot
