import {
  Nav,
  Logo,
  Box,
  NavLink,
  NavButton,
  Dropdown,
  DropdownLink,
  DropdownButton,
} from './Navbar.styled.js'
import { DropdownIcon } from '../../ui/LanguageDropdown/LanguageDropdown.styled.js'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Button from '../../ui/Button/Button'
import Text from '../../ui/Text/Text'
import LanguageDropdown from '../../ui/LanguageDropdown/LanguageDropdown'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { FaRegAddressCard } from 'react-icons/fa'
import { FiUser } from 'react-icons/fi'
import { MdLogout } from 'react-icons/md'
import { AiOutlineStar } from 'react-icons/ai'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext.js'
import LoginModal from '@components/ui/LoginModal/LoginModal.js'
import RegisterModal from '../../ui/RegisterModal/RegisterModal.js'
import {
  removeToken,
  removeUserId,
  removeAccountType,
} from '../../../api/sessionStorage.js'
import { useGetUser } from '../../../services/user.services.js'
import { useTranslation } from 'react-i18next'

const Navbar = () => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false)
  const { t } = useTranslation()
  const {
    user: { isLoggedIn, accountType, token, userId },
    setUser,
  } = useContext(AuthContext)
  const navigate = useNavigate()

  const { data: userData } = useGetUser(userId)
  const logout = () => {
    setUser((prev) => ({
      ...prev,
      isLoggedIn: false,
      accountType: '',
      token: '',
      userId: '',
    }))
    removeToken()
    removeUserId()
    removeAccountType()
  }

  return (
    <Nav>
      <Box>
        <a href="/">
          <Logo />
        </a>
        <Button
          primary
          light
          label={t('navbar.create-auction')}
          margin="0 0 0 4vw"
          onClick={(e) => {
            e.preventDefault()
            navigate('/add-order')
          }}
        />
        <Button
          outlined
          light
          label={t('navbar.collect-offers')}
          margin="0 0 0 1vw"
          onClick={(e) => {
            e.preventDefault()
            navigate('/collect-offers')
          }}
        />
      </Box>
      {isLoggedIn ? (
        <Box gap="2vw">
          <NavButton
            onClick={() =>
              setIsOpenDropdown((isOpenDropdown) => !isOpenDropdown)
            }
          >
            <p>
              {userData?.data?.firstName} {userData?.data?.lastName}
              <DropdownIcon />
            </p>
          </NavButton>

          <LanguageDropdown />
          {isOpenDropdown && (
            <Dropdown
              onClick={() =>
                setIsOpenDropdown((isOpenDropdown) => !isOpenDropdown)
              }
            >
              {accountType === 'customer' && (
                <>
                  <Text medium margin="0 0 1vw 0">
                    {t('navbar.customer')}
                  </Text>
                  <DropdownLink to="/add-order">
                    <IoIosAddCircleOutline />
                    {t('navbar.create-auction')}
                  </DropdownLink>
                  <DropdownLink to="/collect-offers">
                    <IoIosAddCircleOutline />
                    {t('navbar.collect-offers')}
                  </DropdownLink>
                  <DropdownLink to="/customer/auctions">
                    <FaRegAddressCard />
                    {t('navbar.create-my-projects')}
                  </DropdownLink>
                </>
              )}
              {accountType === 'pilot' && (
                <>
                  <Text medium margin="0 0 1vw 0">
                    {t('navbar.pilot')}
                  </Text>
                  <DropdownLink to="/pilot/auctions">
                    <FaRegAddressCard />
                    {t('navbar.show-auctions')}
                  </DropdownLink>
                  <DropdownLink to="/pilot/bids">
                    <AiOutlineStar />
                    {t('navbar.biddings')}
                  </DropdownLink>
                </>
              )}
              <Text medium margin="1vw 0 1vw 0">
                {t('navbar.account')}
              </Text>
              {accountType === 'pilot' && (
                <DropdownLink to="/pilot/account">
                  <FiUser style={{ fontSize: 16 }} />
                  {t('navbar.my-account')}
                </DropdownLink>
              )}
              {accountType === 'customer' && (
                <DropdownLink to="/customer/account">
                  <FiUser style={{ fontSize: 16 }} />
                  {t('navbar.my-account')}
                </DropdownLink>
              )}
              <DropdownButton
                small
                onClick={(e) => {
                  e.preventDefault()
                  logout()
                  navigate('/')
                }}
              >
                <MdLogout style={{ fontSize: 16 }} />
                {t('navbar.logout')}
              </DropdownButton>
            </Dropdown>
          )}
        </Box>
      ) : (
        <Box gap="2vw">
          <NavLink
            onClick={(e) => {
              e.preventDefault()
              setIsLoginModalOpen(true)
            }}
          >
            {t('navbar.login')}
          </NavLink>
          <NavLink
            onClick={(e) => {
              e.preventDefault()
              setIsRegisterModalOpen(true)
            }}
          >
            {t('navbar.register')}
          </NavLink>
          <LanguageDropdown />
        </Box>
      )}
      {isLoginModalOpen && (
        <LoginModal
          isAuthModalOpen={isLoginModalOpen}
          setIsRegisterModalOpen={setIsRegisterModalOpen}
          setIsAuthModalOpen={setIsLoginModalOpen}
        />
      )}
      {isRegisterModalOpen && (
        <RegisterModal
          isRegisterModalOpen={isRegisterModalOpen}
          setIsRegisterModalOpen={setIsRegisterModalOpen}
          setIsLoginModalOpen={setIsLoginModalOpen}
        />
      )}
    </Nav>
  )
}

export default Navbar
