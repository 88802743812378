import { Container, Grid } from './PilotAuctionList.styled'
import SearchBar from '@components/layout/SearchBar/SearchBar'
import AuctionList from '@components/ui/AuctionList/AuctionList'
import { useContext, useState } from 'react'
import PaginationBar from '@components/layout/PaginationBar/PaginationBar'
import { useEffect, ueState } from 'react'
import { useGetAuctionsQuery } from '@services/auctions.services'
import { AuthContext } from '@context/AuthContext'
import Spinner from '@components/ui/Spinner/Spinner'
import AuctionListItemLoader from '@components/ui/AuctionListItem/AuctionListItemLoader'
import Text from '@components/ui/Text/Text'
import { useTranslation } from 'react-i18next'
import TrackingBar from '@components/layout/TrackingBar/TrackingBar'
import MainLayout from '@components/layout/MainLayout/MainLayout'
const PilotAuctionList = () => {
  const {
    user: { token },
  } = useContext(AuthContext)

  const { data, isLoading } = useGetAuctionsQuery(token)

  const [filters, setFilters] = useState({
    category: '',
    keyWord: '',
    location: '',
  })
  const [visibleItems, setVisibleItems] = useState(5)

  const [items, setItems] = useState([...(data ?? [])])

  useEffect(() => {
    if (data) {
      setItems(data.slice(0, visibleItems))
    }
  }, [data, visibleItems])

  const showMoreItems = () => {
    setVisibleItems((prev) => prev + 5)
  }

  function filterData() {
    const filteredItems = data.filter((item) => {
      if (filters.category && filters.category.length > 0) {
        const categoryMatch = filters.category.some(
          (catFilter) => item.serviceId === catFilter.service.id
        )
        if (!categoryMatch) {
          return false
        }
      }

      if (filters.location) {
        if (item.location.toLowerCase() !== filters.location.toLowerCase()) {
          return false
        }
      }

      if (filters.keyWord) {
        const keywordMatch = Object.values(item).some(
          (value) =>
            typeof value === 'string' &&
            value.toLowerCase().includes(filters.keyWord.toLowerCase())
        )

        if (!keywordMatch) {
          return false
        }
      }
      return true
    })
    setItems([...filteredItems])
  }

  const { t } = useTranslation()
  return (
    <>
      <Grid>
        <div></div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
            paddingTop: '24px',
          }}
        >
          {' '}
          <p bold style={{ fontSize: '18px !improtant', fontWeight: 600 }}>
            {t('auctions-list.title')}
          </p>
          <p style={{ fontSize: '14px', fontWeight: 400 }}>
            {t('auctions-list.found')}: {data?.length}
          </p>
        </div>
        <div></div>
      </Grid>
      <Grid>
        <div />
        <Container>
          {isLoading &&
            [1, 2, 3, 4, 5].map((el) => (
              <div style={{ marginTop: 24 }}>
                <AuctionListItemLoader />
              </div>
            ))}

          <AuctionList pilot auctions={data} isLoading={isLoading} />
          {/* {!isLoading && data && (
            <PaginationBar
              showMoreItems={showMoreItems}
              items={items}
              visibleItems={visibleItems}
            />
          )} */}
        </Container>
        <div />
      </Grid>
    </>
  )
}

export default PilotAuctionList
