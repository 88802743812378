import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getAuctionAttachment,
  getAuctionRelatedProjects,
  bidOffer,
  collectOffers,
  getAuctions,
  getAuction,
  createAuction,
  getUserAuctions,
  createAuctionAsGuest,
  updateAuction,
  deleteAuction,
} from '../api/auctions'

export const useGetAuctionsQuery = (token) => {
  return useQuery({
    queryKey: ['acutions'],
    queryFn: async () => {
      const res = await getAuctions(token)
      return res.data
    },
  })
}

export const useGetAuctionQuery = (id, token) => {
  return useQuery({
    queryKey: ['acutions', id],
    queryFn: async () => {
      const res = await getAuction(id, token)
      return res.data
    },
  })
}

export const useGetUserAuctionQuery = (token) => {
  return useQuery({
    queryKey: ['acutions'],
    queryFn: async () => {
      const res = await getUserAuctions(token)
      return res.data
    },
  })
}

export const useCreateAuction = (token) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['auctions'],
    mutationFn: async (data) => await createAuction(token, data),
    onSuccess: () => queryClient.invalidateQueries(['auctions']),
  })
}

export const useCreateAuctionWithRegistration = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['auctions'],
    mutationFn: async (token, data) => await createAuction(token, data),
    onSuccess: () => queryClient.invalidateQueries(['auctions']),
  })
}

export const useUpdateAuction = (id, token) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['auction', id],
    mutationFn: async (data) => await updateAuction(token, data, id),
    onSuccess: () => queryClient.invalidateQueries(['auction', id]),
  })
}

export const useCreateAuctionAsGuest = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['auctions'],
    mutationFn: async (data) => await createAuctionAsGuest(data),
    onSuccess: () => queryClient.invalidateQueries(['auctions']),
  })
}

export const useDeleteAuction = (id, token) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['auctions'],
    mutationFn: async () => await deleteAuction(id, token),
    onSuccess: () => queryClient.invalidateQueries(['auctions']),
  })
}
