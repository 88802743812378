import styled from 'styled-components'

export const Btn = styled.button`
  border-radius: 4px;
  border: none;
  border: ${(props) => props.outlined && '1px solid #ffffff'};
  border: ${(props) => props.outlined && props.dark && '1px solid #3d3d3d'};
  border: ${(props) => props.blue && '1px solid #00022A'};
  background-color: ${(props) => props.primary && '#FF771C'};
  background-color: ${(props) => props.outlined && 'transparent'};
  color: ${(props) => props.light && '#ffffff'};
  color: ${(props) => props.dark && '#3D3D3D'};
  color: ${(props) => props.blue && '#00022A'};
  font-size: 14px;
  width: ${(props) => props.width && props.width};
  font-weight: 500;
  font-family: 'Lato';
  padding: 8px 16px;
  margin: ${(props) => props.margin && props.margin};
  cursor: pointer;
  padding: ${(props) => props.large && '8px 16px'};
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    gap: 8px;
    color: ${(props) => props.light && '#ffffff'};
    color: ${(props) => props.dark && '#3D3D3D'};
    color: ${(props) => props.blue && '#00022A'};
    svg {
      color: ${(props) => props.light && '#ffffff'};
      color: ${(props) => props.dark && '#3D3D3D'};
      color: ${(props) => props.blue && '#00022A'};
      height: 14px;
      width: 14px;
    }
  }
`
