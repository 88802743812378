import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 80px 0px;
  display: flex;
  justify-content: center;
`

export const IconContainer = styled.div`
  height: 63px;
  width: 63px;
  border-radius: 50%;
  border: 1px solid #26c35c;
  color: #26c35c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  svg {
    font-size: 40px;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 750px;
  justify-content: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 40px;
`
