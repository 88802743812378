import { Controller } from 'react-hook-form'
import {
  useGetCitiesByCountry,
  useGetCitiesByState,
  useGetCountries,
  useGetRegions,
  useGetState,
} from '@services/locations.services'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Label from '@components/form/Label/Label'
import { Dropdown } from 'primereact/dropdown'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Locations = ({ control, watch, locations, setValue }) => {
  const { data: regions } = useGetRegions()

  const defaultRegion = regions.filter((el) => el.name === locations.region)[0]
  const { data: countries } = useGetCountries(defaultRegion.name)
  const defaultCountry = countries?.filter(
    (el) => el.name === locations.country
  )[0]

  const { data: states } = useGetState(defaultCountry.id)
  const defaultState = states?.filter((el) => el.name === locations.state)[0]

  const { data: citiesByState } = useGetCitiesByState(defaultState.id)

  const { data: citiesByCountry } = useGetCitiesByCountry(defaultCountry.id)

  const defaultCityByState = citiesByState?.filter(
    (el) => el.name === locations.city
  )[0]
  const defaultCityByCountry = citiesByCountry?.filter(
    (el) => el.name === locations.city
  )[0]

  const { t } = useTranslation()

  useEffect(() => {
    setValue('preferredLocationRegion', defaultRegion ?? '')
    setValue('preferredLocationCountry', defaultCountry ?? '')
    setValue('preferredLocationState', defaultState ?? '')
    setValue(
      'preferredLocationCity',
      watch('preferredLocationState')
        ? defaultCityByState
        : defaultCityByCountry ?? ''
    )
  }, [])

  return (
    <FormGroup type="text">
      <Label>{t('locations.title')}</Label>
      <div
        style={{
          display: 'flex',
          gap: 5,
          flexDirection: 'column',
          marginTop: 5,
        }}
      >
        <Controller
          name="preferredLocationRegion"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              options={regions ?? []}
              optionLabel="name"
              filter
              placeholder={t('locations.pick-region')}
              className="w-full md:w-14rem"
            />
          )}
        />

        <Controller
          name="preferredLocationCountry"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              options={countries ?? []}
              optionLabel="name"
              filter
              placeholder={t('locations.pick-country')}
              className="w-full md:w-14rem"
            />
          )}
        />
        <Controller
          name="preferredLocationState"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              options={states ?? []}
              optionLabel="name"
              filter
              placeholder={t('locations.pick-state')}
              className="w-full md:w-14rem"
            />
          )}
        />

        {watch('preferredLocationState') ? (
          <Controller
            name="preferredLocationCity"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                options={citiesByState ?? []}
                optionLabel="name"
                filter
                placeholder={t('locations.pick-city')}
                className="w-full md:w-14rem"
              />
            )}
          />
        ) : (
          <Controller
            name="preferredLocationCity"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                options={citiesByCountry ?? []}
                optionLabel="name"
                filter
                placeholder={t('locations.pick-city')}
                className="w-full md:w-14rem"
              />
            )}
          />
        )}
      </div>
    </FormGroup>
  )
}

export default Locations
