import {
  Details,
  DetailsRow,
  EditInformation,
  Form,
} from './CustomerAccount.styled'
import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Separator from '@components/ui/Separator/Separator'
import Button from '@components/ui/Button/Button'
import Label from '@components/form/Label/Label'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Input from '@components/form/Input/Input'
import { useContext, useState } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { useUpdateUser } from '@services/user.services'
import { AuthContext } from '@context/AuthContext'
import { useForm } from 'react-hook-form'
import useToast from '@hooks/useToast'
import { useTranslation } from 'react-i18next'

const CompanyInfo = ({ customer }) => {
  const { showToast, ToastComponent } = useToast()

  const { handleSubmit, register } = useForm({
    defaultValues: async () => ({
      isCompany: customer.isCompany,
      companyDetails: {
        companyName: customer.companyDetails?.companyName,
        vatCode: customer.companyDetails?.vatCode,
        addressLine1: customer.companyDetails?.addressLine1,
        addressLine2: customer.companyDetails?.addressLine2,
        postCode: customer.companyDetails?.postCode,
        city: customer.companyDetails?.city,
      },
    }),
  })
  const [mode, setMode] = useState('view')
  const {
    user: { token },
  } = useContext(AuthContext)
  const updateUserMutation = useUpdateUser(customer?.publicId, token)
  const { t } = useTranslation()

  const onSubmit = (data) => {
    const userData = {
      ...customer,
      ...data,
    }
    updateUserMutation.mutate(userData, {
      onSuccess: () => {
        setMode('view')
        showToast('success', 'Success', t('customer-account.toast-updated'))
      },
    })
  }

  return (
    <Card width="100%" form padding="2vw">
      {ToastComponent}

      <Text large margin="0 0 2vw 0vw">
        {t('customer-account.company-data')}
      </Text>
      {mode === 'view' && (
        <Details>
          <DetailsRow>
            <Text small>{t('customer-account.as-company')}</Text>
            <Text medium>{customer?.isCompany ? 'Tak' : 'Nie'}</Text>
          </DetailsRow>
          {customer?.isCompany && (
            <>
              <DetailsRow>
                <Text small>{t('customer-account.company-name')}</Text>
                <Text medium>{customer?.companyDetails?.companyName}</Text>
              </DetailsRow>
              <DetailsRow>
                <Text small>N{t('customer-account.company-tax-id')}IP</Text>
                <Text medium>{customer?.companyDetails?.vatCode}</Text>
              </DetailsRow>
              <DetailsRow>
                <Text small>{t('customer-account.company-address')}</Text>
                <Text medium>
                  {customer?.companyDetails?.addressLine1}{' '}
                  {customer?.companyDetails?.addressLine2} <br />{' '}
                  {customer?.companyDetails?.postCode}{' '}
                  {customer?.companyDetails?.city} <br />{' '}
                  {customer?.companyDetails?.countryCode}
                </Text>
              </DetailsRow>
            </>
          )}
          <Separator />
        </Details>
      )}
      {mode === 'edit' && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup type="checkbox" margin="0 0 2vw 0">
            <Input type="checkbox" {...register('isCompany')} />
            <Label>{t('customer-account.as-company')}</Label>
          </FormGroup>
          <FormGroup type="text">
            <Label small>{t('customer-account.company-name')}</Label>
            <Input width="25vw" {...register('companyDetails.companyName')} />
          </FormGroup>
          <FormGroup type="text">
            <Label small>{t('customer-account.company-tax-id')}</Label>
            <Input width="18.3vw" {...register('companyDetails.vatCode')} />
          </FormGroup>
          <FormGroup type="checkbox">
            <FormGroup type="text" margin="0">
              <Label>{t('customer-account.company-address')}</Label>
              <Input
                type="text"
                width="17.5vw"
                {...register('companyDetails.addressLine1')}
              />
            </FormGroup>
          </FormGroup>
          <FormGroup type="checkbox">
            <FormGroup type="text" margin="0">
              <Label>{t('customer-account.company-address-2')}</Label>
              <Input
                type="text"
                width="17.5vw"
                {...register('companyDetails.addressLine2')}
              />
            </FormGroup>
          </FormGroup>
          <FormGroup type="checkbox">
            <FormGroup type="text" width="fit-content" margin="0 0.635vw 0px 0">
              <Label>{t('customer-account.city')}</Label>
              <Input width="17.5vw" {...register('companyDetails.city')} />
            </FormGroup>
            <FormGroup type="text" margin="0">
              <Label>{t('customer-account.post-code')}</Label>
              <Input
                type="text"
                width="7vw"
                {...register('companyDetails.postCode')}
              />
            </FormGroup>
          </FormGroup>
          <Separator />

          <EditInformation>
            {mode === 'edit' && (
              <Button
                label={
                  updateUserMutation.isLoading ? (
                    <p>
                      {t('chacustomernge-account.loading')}
                      <i
                        className="pi pi-spin pi-spinner"
                        style={{ fontSize: '14px' }}
                      ></i>{' '}
                    </p>
                  ) : (
                    t('customer-account.save')
                  )
                }
                light
                primary
                type="submit"
              />
            )}
            {mode === 'edit' && (
              <Button
                label={t('customer-account.cancel')}
                light
                outlined
                blue
                onClick={() => setMode('view')}
              />
            )}
          </EditInformation>
        </Form>
      )}
      {mode === 'view' && (
        <EditInformation>
          <Button
            label={
              <p>
                <FiEdit2 /> {t('customer-account.edit')}
              </p>
            }
            light
            outlined
            blue
            onClick={(e) => {
              e.preventDefault()
              setMode((mode) => 'edit')
            }}
          />
        </EditInformation>
      )}
    </Card>
  )
}

export default CompanyInfo
