import {
  Content,
  ListItem,
  AuctionImage,
  AuctionEndTime,
  ContentBox,
  GeneralInfo,
  GeneralInfoTop,
  GeneralInfoTopCategory,
  Details,
  DetailsRow,
  DetailsRowData,
  DetailsRowTitle,
  Description,
  AttachmentsList,
  AttachmentListItem,
  List,
} from './Auction.styled'
import { BsCash } from 'react-icons/bs'
import { Galleria } from 'primereact/galleria'
import {
  useGetCitiesByCountry,
  useGetCountries,
  useGetRegions,
  useGetState,
} from '@services/locations.services'

import React, { useRef } from 'react'
import { FaEarthEurope } from 'react-icons/fa6'
import Text from '../Text/Text'
import Button from '../Button/Button'
import Separator from '../Separator/Separator'
import { TfiTime } from 'react-icons/tfi'
import { BiEuro, BiCalendarX } from 'react-icons/bi'
import { FiMapPin } from 'react-icons/fi'
import { RxDownload } from 'react-icons/rx'
import { AiOutlineEye } from 'react-icons/ai'

import { useParams } from 'react-router-dom'
import Offer from '../Offer/Offer'
import { useGetServices } from '@/services/dictionaries.services'
import { formatDate } from '@utils/formatDate'
import { useGetAuctionBidding } from '@/services/biddings'
import PilotOffers from '../PilotOffers/PilotOffers'
import AuctionLoader from './AuctionLoader'
import { useTranslation } from 'react-i18next'
const Auction = ({ pilot, customer, isLoading, data }) => {
  const { t } = useTranslation()

  const { data: regions } = useGetRegions()

  const region = regions?.filter((el) => el.id === data?.locationRegion)[0]
    ?.name

  const { data: countries } = useGetCountries(region)
  const country = countries?.filter((el) => el.id === data.locationCountry)[0]
    ?.name
  const { data: cities } = useGetCitiesByCountry(
    countries?.filter((el) => el.id === data.locationCountry)[0]?.id
  )
  const { data: states } = useGetState(
    countries?.filter((el) => el.id === data.locationCountry)[0]?.id
  )
  const state = states?.filter((el) => el.id === data.locationState)[0]?.name

  const city = cities?.filter((el) => el.id === data.locationCity)[0]?.name

  const params = useParams()
  const galleria = useRef(null)
  const services = useGetServices()

  function downloadBase64File(base64Data, filename) {
    const linkSource = base64Data
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = filename
    downloadLink.click()
  }

  const expired = new Date().getTime() > new Date(data?.rfqEndDate).getTime()

  const itemTemplate = (item) => {
    return (
      <img
        src={item.itemImageSrc}
        alt={item.alt}
        style={{ width: '100%', display: 'block' }}
      />
    )
  }

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.thumbnailImageSrc}
        alt={item.alt}
        style={{ display: 'block' }}
      />
    )
  }

  const attachments = [
    data?.image1,
    data?.image2,
    data?.image3,
    data?.image4,
    data?.image5,
  ].filter((el) => el !== '')
  const { data: biddings, isLoading: biddingsLoading } = useGetAuctionBidding(
    params.id
  )
  let sortedBiddings

  if (pilot) {
    sortedBiddings =
      biddings && [...biddings].sort((a, b) => a.amount - b.amount)?.slice(0, 1)
  } else {
    sortedBiddings = biddings
  }

  if (!isLoading && data?.auction === false)
    return (
      <Offer
        offer={data}
        biddings={sortedBiddings}
        pilot={pilot}
        customer={customer}
      />
    )

  const items = attachments.map((el) => ({ itemImageSrc: el }))

  return (
    <Content>
      {isLoading && <AuctionLoader />}
      {!isLoading && data && (
        <>
          <Text large>{t('auction.project')}</Text>
          <ListItem>
            {data?.background && <AuctionImage src={data?.background} />}
            <ContentBox>
              <GeneralInfo>
                <AuctionEndTime style={{ backgroundColor: expired && 'red' }}>
                  <TfiTime />
                  {t('auction.end')}: {formatDate(data?.rfqEndDate)}
                </AuctionEndTime>

                <GeneralInfoTop>
                  <GeneralInfoTopCategory>
                    {' '}
                    {services?.data?.data &&
                      services?.data?.data?.filter(
                        (el) => el?.service?.id === data?.service?.id
                      )[0]?.name}{' '}
                  </GeneralInfoTopCategory>
                </GeneralInfoTop>
              </GeneralInfo>
              <Details>
                {!pilot && (
                  <DetailsRow>
                    <DetailsRowTitle>
                      {t('auction.max-budget')}:
                    </DetailsRowTitle>
                    <DetailsRowData>
                      <BsCash />
                      {data?.maxBudget} {data?.currency}
                    </DetailsRowData>
                  </DetailsRow>
                )}

                <DetailsRow>
                  <DetailsRowTitle>{t('auction.date')}:</DetailsRowTitle>
                  <DetailsRowData>
                    <BiCalendarX />
                    {data.oneDay
                      ? formatDate(data?.dateFrom)
                      : `${formatDate(data?.dateFrom)}-${formatDate(
                          data?.dateTo
                        )}`}
                  </DetailsRowData>
                </DetailsRow>

                <DetailsRow>
                  <DetailsRowTitle>{t('auction.location')}:</DetailsRowTitle>
                  <DetailsRowData>
                    <FiMapPin />
                    {region}, {country}, {state}, {city}
                  </DetailsRowData>
                </DetailsRow>
                <DetailsRow>
                  <DetailsRowTitle>{t('auction.languages')}:</DetailsRowTitle>
                  <DetailsRowData>
                    <FaEarthEurope />
                    {data?.language}
                  </DetailsRowData>
                </DetailsRow>
              </Details>
            </ContentBox>
          </ListItem>

          <Description> {data?.description} </Description>

          <Separator margin="0" />

          <Text large>{t('auction.attachments')}</Text>
          <AttachmentsList>
            {attachments?.map((el, index) => (
              <AttachmentListItem key={index}>
                {t('auction.attachment')}
                {index + 1}
                <Galleria
                  ref={galleria}
                  value={items}
                  numVisible={2}
                  style={{ maxWidth: '90%' }}
                  circular
                  fullScreen
                  item={itemTemplate}
                  thumbnail={thumbnailTemplate}
                />
                <Button
                  onClick={() => galleria.current.show()}
                  blue
                  outlined
                  label={
                    <p>
                      <AiOutlineEye />
                      {t('auction.preview')}
                    </p>
                  }
                />
                <Button
                  blue
                  outlined
                  onClick={() => downloadBase64File(el, 'image.jpg')}
                  label={
                    <p>
                      <RxDownload /> {t('auction.download')}
                    </p>
                  }
                />
              </AttachmentListItem>
            ))}
            {attachments.length === 0 && (
              <Text small secondary margin="10px">
                {t('auction.no-attachments')}
              </Text>
            )}
          </AttachmentsList>

          <Separator margin="0" />
          <Text large>{t('auction.bids')}</Text>
          {!sortedBiddings?.length && !biddingsLoading && (
            <Text small secondary margin="10px">
              {t('auction.no-bids')}
            </Text>
          )}
          {biddingsLoading && (
            <Text>
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: '14px' }}
              ></i>
            </Text>
          )}
          <List>
            {sortedBiddings?.map((el) => (
              <PilotOffers customer={customer} pilot={pilot} offer={el} />
            ))}
          </List>
        </>
      )}
    </Content>
  )
}

export default Auction
