import OfferListItem from '../OfferListItem/OfferListItem'
import { Box, Content } from './OfferList.styled'

const OfferList = ({ offers, pilot, customer }) => {
  return (
    <Box>
      <Content>
        {offers.map((offer) => (
          <OfferListItem offer={offer?.project} key={offer?.project?.publicId} pilot={pilot} customer={customer} />
        ))}
      </Content>
    </Box>
  )
}

export default OfferList
