import React from 'react'
import ContentLoader from 'react-content-loader'

const AuctionListItemLoader = (props) => (
  <ContentLoader speed={2} width={900} height={200} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
    <rect x="170" y="2" rx="3" ry="3" width="100" height="20" />
    <rect x="170" y="30" rx="3" ry="3" width="200" height="35" />
    <rect x="170" y="80" rx="6" ry="6" width="500" height="50" />

    <rect x="170" y="145" rx="3" ry="3" width="725" height="105" />
    <rect x="0" y="2" rx="6" ry="6" width="160" height="250" />
  </ContentLoader>
)

export default AuctionListItemLoader
