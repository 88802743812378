import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 1em !important;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Title = styled.h3`
  font-size: 2em;
  text-align: center !important;
  color: #3d3d3d;
  margin-bottom: 3vw;
`
