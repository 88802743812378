import styled from 'styled-components'

export const ContentContainer = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  padding: 16px 12px;
  display: flex;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  form {
    display: flex;
    align-items: start;
    justify-content: flex-start;
  }
  width: 100%;

  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 20% 60% 20%;
  gap: 42px;
  @media only screen and (max-width: 600px) {
    display: flex;
    padding: 0px 24px;
  }
  form {
    padding: 0;
    margin: 0;
    width: fit-content;
    @media only screen and (max-width: 600px) {
      min-width: 100%;
    }
  }
`

export const Box = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 14px;
`

export const Card = styled.div`
  background-color: white;
  height: 13.5vw;
  width: 13.5vw;
  box-shadow: 0px 5px 58px rgba(0, 0, 0, 0.07);
  border-radius: 1.2vw;
  padding 0.635vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0.0625vw solid transparent;
  border-color: ${(props) => (props.active ? '#FF771C' : 'transparent')};
  &:hover {
    border: 0.0625vw solid #FF771C
  }
`

export const ButtonBox = styled.div`
  margin: 2.5vw 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 43vw;
`

export const Form = styled.form`
  width: 100%;
  justify-content: center;
  max-width: 1300px;
  display: flex;
`
