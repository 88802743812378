import { api } from './api.service'

export const getRegions = async () => {
  const res = await api.get(
    `${process.env.REACT_APP_API_URL}/locations/Regions`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    }
  )
  return res.data
}

export const getCountries = async (region) => {
  const res = await api.get(
    `${process.env.REACT_APP_API_URL}/locations/Countries/${region}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    }
  )
  return res.data
}

export const getStates = async (country) => {
  const res = await api.get(
    `${process.env.REACT_APP_API_URL}/locations/States/${country}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    }
  )
  return res.data
}

export const getCitiesByCountry = async (country) => {
  const res = await api.get(
    `${process.env.REACT_APP_API_URL}/locations/CitiesByCountry/${country}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    }
  )
  return res.data
}

export const getCitiesByState = async (state) => {
  const res = await api.get(
    `${process.env.REACT_APP_API_URL}/locations/CitiesByState/${state}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    }
  )
  return res.data
}
