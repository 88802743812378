import { useQuery } from 'react-query'
import {
  getRegions,
  getCountries,
  getStates,
  getCitiesByCountry,
  getCitiesByState,
} from '@api/locations'

export const useGetRegions = () => {
  return useQuery({
    queryKey: ['regions'],
    queryFn: async () => await getRegions(),
  })
}

export const useGetCountries = (region) => {
  return useQuery({
    queryKey: ['countries', region],
    queryFn: async () => await getCountries(region),
    enabled: !!region,
  })
}

export const useGetState = (country) => {
  return useQuery({
    queryKey: ['states', country],
    queryFn: async () => await getStates(country),
    enabled: !!country,
  })
}

export const useGetCitiesByCountry = (country) => {
  return useQuery({
    queryKey: ['cities', country],
    queryFn: async () => await getCitiesByCountry(country),
    enabled: !!country,
  })
}

export const useGetCitiesByState = (state) => {
  return useQuery({
    queryKey: ['cities', state],
    queryFn: async () => await getCitiesByState(state),
    enabled: !!state,
  })
}
