import styled from 'styled-components'

export const Container = styled.div`
width: 100%;
display: grid;
grid-template-rows: repeat(1, 1fr);
grid-template-columns: 20% 60% 20%;
gap: 42px;
padding-bottom: 64px
`

