import styled from 'styled-components'

export const FilterList = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`
export const FilterListItem = styled.div`
  border-bottom: ${(props) => (props.active ? '2px solid #FF771C' : '2px solid #e2e8f0')};
  width: 100%;

  font-size: 1em;

  padding: 20px;
  color: ${(props) => (props.active ? '#FF771C' : '#6c757d')};
  cursor: pointer;
  p {
    text-align: center;
  }
`
