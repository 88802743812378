import styled from 'styled-components'
import logo from '../../../assets/logo_white.svg'
import { Link } from 'react-router-dom'

export const Nav = styled.nav`
  max-width: 100vw;
  background-color: #00022a;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  display: flex;
  padding: 32px 48px;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  z-index: 10;
  @media only screen and (max-width: 600px) {
    padding: 24px;
  }
`

export const Logo = styled.div`
  background-image: url(${logo});
  background-size: 160px 48px;
  background-repeat: no-repeat;
  color: white;
  width: 160px;
  height: 48px;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    width: 90px;
    height: 52px;
    background-size: 90px 52px;
  }
`

export const Box = styled.div`
  min-width: fit-content;
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap && props.gap};
  font-weight: 400;
  font-size: 0.8rem;

  position: relative;
  button {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  @media only screen and (max-width: 600px) {
    gap: 20px;
  }
`

export const NavButton = styled.button`
  font-weight: 400;
  font-size: 0.8rem;

  border: none;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #ff771c;
  }
  p {
    display: flex;
  }
`

export const Dropdown = styled.div`
  position: absolute;
  top: 3vw;
  left: -7vw;
  height: fit-content;
  width: fit-content;
  background-color: white;
  border-radius: 0.5vw;
  padding: 1vw 3vw 1.5vw 1vw;
  border: 0.0625vw solid #dee2e6;
  z-index: 10 !important;
`

export const DropdownLink = styled(Link)`
  font-size: 0.8rem;

  text-decoration: none;
  font-weight: 300;
  color: #3d3d3d;
  display: flex;
  gap: 1vw;
  align-items: center;
  margin-top: 0.5vw;
  cursor: pointer;
  &:hover {
    color: #ff771c;
    svg {
      color: #ff771c;
    }
  }
  svg {
    font-size: 1rem;
  }
`
export const DropdownButton = styled.button`
  font-size: 0.8rem;
  text-decoration: none;
  background-color: transparent;
  border: none;
  margin-top: 0.5vw;
  color: #3d3d3d;
  display: flex;
  align-items: center;
  gap: 1vw;
  cursor: pointer;
  font-weight: 300;
  &:hover {
    color: #ff771c;
  }
  svg {
    font-weight: 300;
    font-size: 1.5vw;
  }
`

export const NavLink = styled(Link)`
  font-weight: 400;
  font-size: 0.8rem;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #ff771c;
  }
`
