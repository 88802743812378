import styled from 'styled-components'

export const Container = styled.div`
  width: fit-ocntent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 30px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`

export const StepWrapper = styled.div`
  min-width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`
export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: fit-content;
`
export const Step = styled.div`
  background-color: ${(props) => props.active && '#FF771C'};
  background-color: ${(props) => props.unfilled && 'white'};
  background-color: ${(props) => props.filled && 'white'};
  color: ${(props) => props.unfilled && '#FF771C'};
  color: ${(props) => props.active && 'white'};
  color: ${(props) => props.filled && '#26C35C'};
  border: ${(props) => props.unfilled && '0.0625vw solid #FF771C'};
  border: ${(props) => props.filled && '0.0625vw solid #26C35C'};
  border: ${(props) => props.active && '0.0625vw solid #FF771C'};
  width: 2vw;
  min-width: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  height: 2vw;
  font-size: 0.8vw;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  svg {
    color: #26c35c;
    font-size: 0.8vw;
  }
`
