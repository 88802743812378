export const currencies = [
  { code: 'USD', symbol: '$' },
  { code: 'EUR', symbol: '€' },
  { code: 'JPY', symbol: '¥' },
  { code: 'GBP', symbol: '£' },
  { code: 'AUD', symbol: 'A$' },
  { code: 'CAD', symbol: 'CA$' },
  { code: 'CHF', symbol: 'CHF' },
  { code: 'CNY', symbol: '元' },
  { code: 'SEK', symbol: 'kr' },
  { code: 'NZD', symbol: 'NZ$' },
  { code: 'NOK', symbol: 'kr' },
  { code: 'BRL', symbol: 'R$' },
  { code: 'RUB', symbol: '₽' },
  { code: 'INR', symbol: '₹' },
  { code: 'ZAR', symbol: 'R' },
  { code: 'TRY', symbol: '₺' },
  { code: 'MXN', symbol: 'Mex$' },
  { code: 'SGD', symbol: 'S$' },
  { code: 'HKD', symbol: 'HK$' },
  { code: 'PLN', symbol: 'zł' },
  { code: 'DKK', symbol: 'kr' },
  { code: 'THB', symbol: '฿' },
  { code: 'IDR', symbol: 'Rp' },
  { code: 'HUF', symbol: 'Ft' },
  { code: 'CZK', symbol: 'Kč' },
  { code: 'ILS', symbol: '₪' },
  { code: 'CLP', symbol: 'CLP$' },
  { code: 'PHP', symbol: '₱' },
  { code: 'AED', symbol: 'د.إ' },
  { code: 'COP', symbol: 'COL$' },
  { code: 'MYR', symbol: 'RM' },
  { code: 'RON', symbol: 'lei' },
  { code: 'ARS', symbol: '$' },
  { code: 'NPR', symbol: 'रू' },
  { code: 'VND', symbol: '₫' },
  { code: 'KRW', symbol: '₩' },
  { code: 'UAH', symbol: '₴' },
  { code: 'BDT', symbol: '৳' },
  { code: 'DZD', symbol: 'د.ج' },
  { code: 'EGP', symbol: 'ج.م' },
  { code: 'IQD', symbol: 'ع.د' },
  { code: 'KWD', symbol: 'د.ك' },
  { code: 'LBP', symbol: 'ل.ل' },
  { code: 'LYD', symbol: 'ل.د' },
  { code: 'MAD', symbol: 'د.م.' },
  { code: 'OMR', symbol: 'ر.ع.' },
  { code: 'QAR', symbol: 'ر.ق' },
  { code: 'SAR', symbol: 'ر.س' },
  { code: 'TND', symbol: 'د.ت' },
  { code: 'YER', symbol: '﷼' },
  { code: 'AFN', symbol: '؋' },
  { code: 'ALL', symbol: 'Lek' },
  { code: 'AMD', symbol: '֏' },
  { code: 'AOA', symbol: 'Kz' },
  { code: 'AWG', symbol: 'Afl.' },
  { code: 'AZN', symbol: '₼' },
  { code: 'BAM', symbol: 'KM' },
  { code: 'BBD', symbol: 'Bds$' },
  { code: 'BGN', symbol: 'лв' },
  { code: 'BHD', symbol: 'ب.د' },
  { code: 'BIF', symbol: 'FBu' },
  { code: 'BMD', symbol: 'BD$' },
  { code: 'BND', symbol: 'B$' },
  { code: 'BOB', symbol: 'Bs' },
  { code: 'BSD', symbol: 'B$' },
  { code: 'BTN', symbol: 'Nu.' },
  { code: 'BWP', symbol: 'P' },
  { code: 'BYN', symbol: 'Br' },
  { code: 'BZD', symbol: 'BZ$' },
  { code: 'CDF', symbol: 'FC' },
  { code: 'CRC', symbol: '₡' },
  { code: 'CUP', symbol: '₱' },
  { code: 'CVE', symbol: 'Esc' },
  { code: 'DJF', symbol: 'Fdj' },
  { code: 'DOP', symbol: 'RD$' },
  { code: 'ERN', symbol: 'Nfk' },
  { code: 'ETB', symbol: 'Br' },
  { code: 'FJD', symbol: 'FJ$' },
  { code: 'FKP', symbol: 'FK£' },
  { code: 'GEL', symbol: '₾' },
  { code: 'GHS', symbol: 'GH₵' },
  { code: 'GIP', symbol: '£' },
  { code: 'GMD', symbol: 'D' },
  { code: 'GNF', symbol: 'FG' },
  { code: 'GTQ', symbol: 'Q' },
  { code: 'GYD', symbol: 'G$' },
  { code: 'HNL', symbol: 'L' },
  { code: 'HRK', symbol: 'kn' },
  { code: 'HTG', symbol: 'G' },
  { code: 'JMD', symbol: 'J$' },
  { code: 'JOD', symbol: 'د.ا' },
  { code: 'KES', symbol: 'KSh' },
  { code: 'KGS', symbol: 'с' },
  { code: 'KHR', symbol: '៛' },
  { code: 'KID', symbol: 'CI$' },
  { code: 'KMF', symbol: 'CF' },
  { code: 'KPW', symbol: '₩' },
  { code: 'KWD', symbol: 'د.ك' },
  { code: 'KYD', symbol: 'CI$' },
  { code: 'KZT', symbol: '₸' },
  { code: 'LAK', symbol: '₭' },
  { code: 'LKR', symbol: 'Rs' },
  { code: 'LRD', symbol: 'L$' },
  { code: 'LSL', symbol: 'M' },
  { code: 'MDL', symbol: 'L' },
  { code: 'MKD', symbol: 'ден' },
  { code: 'MMK', symbol: 'Ks' },
  { code: 'MNT', symbol: '₮' },
  { code: 'MOP', symbol: 'MOP$' },
  { code: 'MRU', symbol: 'UM' },
  { code: 'MUR', symbol: 'Rs' },
  { code: 'MVR', symbol: 'MVR' },
  { code: 'MWK', symbol: 'MK' },
  { code: 'NAD', symbol: 'N$' },
  { code: 'NGN', symbol: '₦' },
  { code: 'NIO', symbol: 'C$' },
  { code: 'NP', symbol: 'NPR' },
  { code: 'NZD', symbol: 'NZ$' },
  { code: 'OMR', symbol: 'ر.ع.' },
  { code: 'PAB', symbol: 'B/.' },
  { code: 'PEN', symbol: 'S/' },
  { code: 'PGK', symbol: 'K' },
  { code: 'PHP', symbol: '₱' },
  { code: 'PKR', symbol: 'Rs' },
  { code: 'PYG', symbol: '₲' },
  { code: 'QAR', symbol: 'ر.ق' },
  { code: 'RON', symbol: 'lei' },
  { code: 'RSD', symbol: 'din.' },
  { code: 'RUB', symbol: '₽' },
  { code: 'RWF', symbol: 'FRw' },
  { code: 'SAR', symbol: 'ر.س' },
  { code: 'SBD', symbol: 'SI$' },
  { code: 'SCR', symbol: 'SR' },
  { code: 'SDG', symbol: 'SDG' },
  { code: 'SEK', symbol: 'kr' },
  { code: 'SGD', symbol: 'S$' },
  { code: 'SHP', symbol: '£' },
  { code: 'SLL', symbol: 'Le' },
  { code: 'SOS', symbol: 'Sh' },
  { code: 'SRD', symbol: 'SRD$' },
  { code: 'SSP', symbol: '£' },
  { code: 'STN', symbol: 'Db' },
  { code: 'SYP', symbol: '£S' },
  { code: 'SZL', symbol: 'E' },
  { code: 'TJS', symbol: 'ЅМ' },
  { code: 'TMT', symbol: 'T' },
  { code: 'TND', symbol: 'د.ت' },
  { code: 'TOP', symbol: 'T$' },
  { code: 'TTD', symbol: 'TT$' },
  { code: 'TVD', symbol: 'TVD$' },
  { code: 'TWD', symbol: 'NT$' },
  { code: 'TZS', symbol: 'TSh' },
  { code: 'UAH', symbol: '₴' },
  { code: 'UGX', symbol: 'USh' },
  { code: 'UYU', symbol: '$U' },
  { code: 'UZS', symbol: 'soʻm' },
  { code: 'VES', symbol: 'Bs.S.' },
  { code: 'VND', symbol: '₫' },
  { code: 'VUV', symbol: 'Vt' },
  { code: 'WST', symbol: 'WS$' },
  { code: 'XAF', symbol: 'FCFA' },
  { code: 'XCD', symbol: 'EC$' },
  { code: 'XDR', symbol: 'SDR' },
  { code: 'XOF', symbol: 'CFA' },
  { code: 'XPF', symbol: 'CFP' },
  { code: 'YER', symbol: '﷼' },
  { code: 'ZAR', symbol: 'R' },
  { code: 'ZMW', symbol: 'ZK' },
  { code: 'ZWL', symbol: 'Z$' },
];
