import styled from 'styled-components'

export const Container = styled.div`
  max-width: 100%;
  padding: 24px 32px;
  background-color: #00022a;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  h3 {
    font-size: 16px;
  }
`

export const Box = styled.div`
  margin: 16px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  gap: 12px;
  flex-wrap: wrap;
`

export const Badge = styled.div`
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 32px;
  padding: 12px 16px;
  font-size: 12px;
  line-height: 1.1vw;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.15s ease;
  &:hover {
    background-color: #ff771c;
  }
`
