import { Container, Content, Header } from './AuctionsOffersList.styled'
import { useContext, useEffect, useState } from 'react'
import TrackingBar from '@components/layout/TrackingBar/TrackingBar'
import Text from '@components/ui/Text/Text'
import FilterBar from './FilterBar/FilterBar'
import AuctionList from '@components/ui/AuctionList/AuctionList'

import OfferList from '@components/ui/OfferList/OfferList'
import { useGetUserAuctionQuery } from '@services/auctions.services'
import { AuthContext } from '@context/AuthContext'
import AuctionListItemLoader from '@components/ui/AuctionListItem/AuctionListItemLoader'
import { useTranslation } from 'react-i18next'

const AuctionsOffersList = () => {
  const [activeFilter, setActiveFilter] = useState('all')
  const {
    user: { userId, token },
  } = useContext(AuthContext)
  const { data, isLoading } = useGetUserAuctionQuery(token)
  const auctions = data?.map((element) => ({ ['project']: element }))

  const { t } = useTranslation()
  return (
    <Container>
      <TrackingBar
        locations={[
          { name: 'DroneConnect', path: '/customer/auctions' },
          { name: 'auctions-and-offers' },
        ]}
      />
      <Content>
        <Header>
          <Text large>{t('customer-auctions.title')}</Text>
          <FilterBar
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        </Header>
        {!isLoading && activeFilter === 'all' && (
          <AuctionList
            customer
            auctions={[
              ...auctions?.filter((el) => el?.project?.auction === true),
            ]}
          />
        )}
        {!isLoading && activeFilter === 'all' && (
          <OfferList
            customer
            offers={[
              ...(auctions?.filter((el) => el?.project?.auction === false) ??
                []),
            ]}
          />
        )}

        {!isLoading && activeFilter === 'auctions' && (
          <AuctionList
            customer
            auctions={[
              ...(auctions?.filter((el) => el?.project?.auction === true) ??
                []),
            ]}
          />
        )}
        {!isLoading && activeFilter === 'offers' && (
          <OfferList
            customer
            offers={[
              ...(auctions?.filter((el) => el?.project?.auction === false) ??
                []),
            ]}
          />
        )}

        {!isLoading && !data?.length && <p>Brak wyników wyszukiwania.</p>}
        {isLoading && (
          <AuctionListItemLoader style={{ marginTop: 40, marginLeft: 50 }} />
        )}
      </Content>
    </Container>
  )
}

export default AuctionsOffersList
