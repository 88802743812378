import {
  Container,
  ListItem,
  AuctionImage,
  Header,
  Content,
  GeneralInfo,
  GeneralInfoTop,
  GeneralInfoBottom,
  GeneralInfoTopCategory,
  GeneralInfoTopTooltips,
  GeneralInfoTopTooltip,
  Details,
  DetailsRow,
  DetailsRowData,
  DetailsRowTitle,
  Description,
  DescriptionLeft,
  DescriptionRight,
  Tooltip,
} from './AuctionListItem.styled'
import { CiMoneyBill } from 'react-icons/ci'
import { TfiTime } from 'react-icons/tfi'
import Text from '../Text/Text'
import Button from '../Button/Button'
import { useContext, useState } from 'react'
import { ImAttachment } from 'react-icons/im'
import { FiSettings } from 'react-icons/fi'
import { BsPeople } from 'react-icons/bs'
import { BiEuro, BiCalendarX } from 'react-icons/bi'
import { FiMapPin } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import PilotOffers from '../PilotOffers/PilotOffers'
import { FaEarthEurope } from 'react-icons/fa6'
import { formatDate } from '@utils/formatDate'
import { useGetServices } from '@services/dictionaries.services'
import { useGetAuctionBidding } from '@services/biddings'
import { AuthContext } from '@context/AuthContext'
import {
  useGetCitiesByCountry,
  useGetCountries,
  useGetRegions,
  useGetState,
} from '@services/locations.services'
import { BsCash } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
const AuctionListItem = ({ auction, pilot, customer }) => {
  const { data: regions } = useGetRegions()
  const { t } = useTranslation()
  let convertedAuction = {}
  if (auction.project) {
    convertedAuction = auction.project
  } else {
    convertedAuction = auction
  }

  const region = regions?.filter(
    (el) => el.id === convertedAuction?.locationRegion
  )[0]?.name

  const { data: countries } = useGetCountries(region)
  const country = countries?.filter(
    (el) => el.id === convertedAuction.locationCountry
  )[0]?.name
  const { data: cities } = useGetCitiesByCountry(
    countries?.filter((el) => el.id === convertedAuction.locationCountry)[0]?.id
  )
  const { data: states } = useGetState(
    countries?.filter((el) => el.id === convertedAuction.locationCountry)[0]?.id
  )
  const state = states?.filter(
    (el) => el.id === convertedAuction.locationState
  )[0]?.name

  const city = cities?.filter(
    (el) => el.id === convertedAuction.locationCity
  )[0]?.name

  const {
    user: { token },
  } = useContext(AuthContext)
  const navigate = useNavigate()
  const [showTooltip, setShowTooltip] = useState(false)
  const attachments = [
    convertedAuction?.image1,
    convertedAuction?.image2,
    convertedAuction?.image3,
    convertedAuction?.image4,
    convertedAuction?.image5,
  ].filter((el) => el !== '' && el !== null)
  const { data: services } = useGetServices()
  const { data: biddings } = useGetAuctionBidding(convertedAuction.publicId)
  let sortedBiddings

  if (pilot) {
    sortedBiddings =
      biddings && [...biddings].sort((a, b) => a.amount - b.amount)?.slice(0, 1)
  } else {
    sortedBiddings = biddings
  }

  const expired =
    new Date().getTime() > new Date(convertedAuction?.rfqEndDate).getTime()
  return (
    <Container>
      <Header
        className="ads"
        bgColor={convertedAuction?.auction === true ? 'green' : 'purple'}
        expired={expired}
      >
        <Text small light>
          {convertedAuction.auction === false
            ? t('auction-list-item.offer')
            : t('auction-list-item.auction')}
        </Text>
        <Text small white>
          <TfiTime />
          {t('auction-list-item.end')}:{' '}
          {formatDate(convertedAuction?.rfqEndDate)}
        </Text>
      </Header>
      <ListItem recommendation={false}>
        {convertedAuction?.background && (
          <AuctionImage src={convertedAuction?.background} />
        )}
        <Content wFull={convertedAuction?.background}>
          <GeneralInfo>
            <GeneralInfoTop>
              <GeneralInfoTopCategory>
                {services &&
                  services?.data.filter(
                    (el) => el.service.id === convertedAuction?.serviceId
                  )[0]?.name}
              </GeneralInfoTopCategory>
              <GeneralInfoTopTooltips>
                <GeneralInfoTopTooltip>
                  <ImAttachment />{' '}
                  <span style={{ fontSize: 12 }}>{attachments?.length}</span>
                </GeneralInfoTopTooltip>
                <GeneralInfoTopTooltip
                  onMouseEnter={(e) => {
                    setShowTooltip(true)
                  }}
                  onMouseLeave={(e) => {
                    setShowTooltip(false)
                  }}
                >
                  <BsPeople />
                  <span style={{ fontSize: 12 }}>{sortedBiddings?.length}</span>
                  {showTooltip && (
                    <Tooltip>
                      <Text large>{t('auction-list-item.offer-amount')}</Text>
                    </Tooltip>
                  )}
                </GeneralInfoTopTooltip>
              </GeneralInfoTopTooltips>
            </GeneralInfoTop>
            {convertedAuction?.title && (
              <GeneralInfoBottom>{convertedAuction?.title}</GeneralInfoBottom>
            )}
          </GeneralInfo>
          <Details>
            <DetailsRow>
              <DetailsRowTitle>{t('auction-list-item.date')}:</DetailsRowTitle>
              <DetailsRowData>
                <BiCalendarX />
                {convertedAuction.oneDay
                  ? formatDate(convertedAuction?.dateFrom)
                  : `${formatDate(convertedAuction?.dateFrom)}-${formatDate(
                      convertedAuction?.dateTo
                    )}`}
              </DetailsRowData>
            </DetailsRow>
            <DetailsRow>
              <DetailsRowTitle>
                {t('auction-list-item.location')}:
              </DetailsRowTitle>
              <DetailsRowData>
                <FiMapPin />
                {region}, {country}, {state}, {city}
              </DetailsRowData>
            </DetailsRow>
            <DetailsRow>
              <DetailsRowTitle>
                {t('auction-list-item.languages')}:
              </DetailsRowTitle>
              <DetailsRowData>
                <FaEarthEurope />
                {convertedAuction?.language}
              </DetailsRowData>
            </DetailsRow>
            {!pilot && convertedAuction?.auction && (
              <DetailsRow>
                <DetailsRowTitle>
                  {t('auction-list-item.max-budget')}:
                </DetailsRowTitle>
                <DetailsRowData>
                  <BsCash />
                  {convertedAuction?.maxBudget} {convertedAuction?.currency}
                </DetailsRowData>
              </DetailsRow>
            )}
            {!convertedAuction.auction && (
              <DetailsRow>
                <DetailsRowTitle>
                  {t('auction-list-item.currency')}:
                </DetailsRowTitle>
                <DetailsRowData>
                  <CiMoneyBill />
                  {auction.currency}
                </DetailsRowData>
              </DetailsRow>
            )}
          </Details>
          <Description>
            <DescriptionLeft>
              {convertedAuction?.description?.slice(0, 150)}...
            </DescriptionLeft>
            {customer && (
              <DescriptionRight>
                <Button
                  outlined
                  dark
                  label={t('auction-list-item.show')}
                  onClick={() =>
                    navigate(`/customer/auctions/${convertedAuction?.publicId}`)
                  }
                />
                <Button
                  outlined
                  dark
                  label={<FiSettings />}
                  onClick={() =>
                    navigate(
                      `/customer/auctions/${convertedAuction?.publicId}/edit-auction`
                    )
                  }
                />
              </DescriptionRight>
            )}
            {pilot && (
              <DescriptionRight>
                <Button
                  outlined
                  dark
                  label={t('auction-list-item.show')}
                  onClick={() =>
                    navigate(`/pilot/bids/${convertedAuction?.publicId}`)
                  }
                />
              </DescriptionRight>
            )}
          </Description>
        </Content>
      </ListItem>
      {sortedBiddings &&
        sortedBiddings?.map((el) => {
          return (
            <PilotOffers
              offer={el}
              customer={customer}
              pilot={pilot}
              key={el.publicId}
            />
          )
        })}
    </Container>
  )
}

export default AuctionListItem
