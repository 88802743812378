import { useQueryClient, useMutation, useQuery } from 'react-query'
import {
  changePassword,
  getUser,
  updateUser,
  getPilot,
  updatePilot,
} from '../api/user'

export const useGetUser = (id) => {
  return useQuery({
    queryKey: [id, 'user', 'pilot'],
    queryFn: () => getUser(id),
    enabled: id ? true : false,
  })
}

export const useGetPilot = (token, id) => {
  return useQuery({
    queryKey: [id, 'pilot'],
    queryFn: () => getPilot(token),
  })
}

export const useUpdateUser = (id, token) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [id, 'user', 'pilot'],
    mutationFn: async (data) => await updateUser(id, data, token),
    onSuccess: () => queryClient.invalidateQueries(id),
  })
}

export const useUpdatePilot = (id) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [id, 'pilot'],
    mutationFn: async (pilot) => {
      return await updatePilot(id, pilot)
    },
    onSuccess: () => queryClient.invalidateQueries(id, 'user', 'pilot'),
  })
}

export const useForgotPassword = (id) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [id, 'pilot'],
    mutationFn: async (email) => {
      return await updatePilot(id, email)
    },
    onSuccess: () => queryClient.invalidateQueries(id, 'user', 'pilot'),
  })
}
