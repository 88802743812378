import styled from 'styled-components'
import confirmationImage from '../../../assets/email_confirmation.svg'
export const Container = styled.div`
  width: 100%;
  padding: 5vw 0px;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625vw;
`
export const Image = styled.div`
  background-image: url(${confirmationImage});
  background-size: 23.125vw 16.875vw;
  background-repeat: no-repeat;
  height: 16.875vw;
  width: 23.125vw;
  margin-top: 4.8vw;
`
