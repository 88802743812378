import ContentLoader from 'react-content-loader'

const PilotAccountLoader = (props) => (
  <ContentLoader speed={2} width={1200} height={9000} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
    <rect x="120" y="20" rx="100" ry="140" width="140" height="140" />
    <rect x="300" y="23" rx="3" ry="3" width="1000" height="250" />
    <rect x="120" y="170" rx="0" ry="0" width="135" height="20" />
    <rect x="120" y="200" rx="0" ry="0" width="135" height="20" />

    <rect x="300" y="300" rx="3" ry="3" width="1000" height="250" />
    <rect x="300" y="300" rx="3" ry="3" width="1000" height="250" />
  </ContentLoader>
)

export default PilotAccountLoader
