import styled from 'styled-components';

export const ErrMessage = styled.p`
  font-size: 0.625vw;
  position: absolute;
  bottom: -1vw;
  left: 0;
  color: #E32224; 
  display: flex;
  align-items: center;
  gap: 0.125vw;
  line-height: 0.625vw;
  svg {
    color: #E32224;
    height: 0.625vw;
    width: 0.625vw;
  }
`