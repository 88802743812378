export function formatDate(inputDate) {
  const date = new Date(inputDate)

  const day = addZero(date.getDate())
  const month = addZero(date.getMonth() + 1)
  const year = date.getFullYear()

  const formattedDate = `${day}.${month}.${year}`

  return formattedDate
}

function addZero(number) {
  return number < 10 ? `0${number}` : number
}
