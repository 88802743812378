import React, { useState } from 'react'
import { InputEl, Container } from './Input.styled'

const Input = React.forwardRef(
  ({ type, width, icon, hasError, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleTogglePassword = () => {
      setShowPassword(!showPassword)
    }

    const isPasswordInput =
      rest.name && rest.name.toLowerCase().includes('password')

    return (
      <Container width={width} type={type}>
        <InputEl
          type={isPasswordInput && showPassword ? 'text' : type}
          width={width}
          icon={icon}
          ref={ref}
          hasError={hasError}
          {...rest}
        />
        {isPasswordInput && (
          <button type="button" onClick={handleTogglePassword}>
            {showPassword ? (
              <i className="pi pi-eye-slash" />
            ) : (
              <i className="pi pi-eye" />
            )}
          </button>
        )}
      </Container>
    )
  }
)

export default Input
