import styled from 'styled-components'

export const Container = styled.div`
  bacground-color: #f5f5f5;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  display: flex;
  padding: 200px 32px 8px 32px;
  display: flex;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 600px) {
    padding-top: 64px;
  }
  h3 {
    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
`

export const Background = styled.div`
  width: 100%;
  position: absolute;
  height: 120px;
  top: 0;
  left: 0;
  background-color: #00022a;
  clip-path: polygon(100% 0, 100% 16%, 49% 80%, 0 16%, 0 0);
  @media only screen and (max-width: 600px) {
    height: 50px;
  }
`
export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin: 16px 0;
    width: 80%;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
`
