import styled from 'styled-components'
export const Container = styled.div`
  position: relative;
  width: ${(props) => props.width && props.width};
  min-width: fit-content;
  button {
    position: absolute;
    right: 8px;
    top: 10px;
    background: none;
    border: none;
    color: #ff771c;
    cursor: pointer;
  }
  @media only screen and (max-width: 600px) {
    width: ${(props) =>
      props.type === 'checkbox' ? 'fit-content' : '100% !important'};
  }
`
export const InputEl = styled.input`
  background-color: #ffffff;
  width: ${(props) => props.width && props.width};
  min-width: 197px;
  height: 40px;
  border: 1px solid transparent;
  border: ${(props) => props.hasError && '0.0625vw solid #E32224'};
  padding-left: 8px;
  color: #3d3d3d;
  color: ${(props) => props.color && props.color};
  border-radius: 8px;
  font-size: 16px;
  &:focus {
    border: 1px solid #ff771c;
    outline: none;
  }
  &[type='checkbox'] {
    min-width: 0px !important;
    width: 22px !important;
    height: 22px !important;
    border-radius: 8px !important;
  }
  &[type='checkbox']:checked {
    background-color: #ff771c !important;
  }
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
`
