import { Content, Title, ButtonContainer } from './OfferConfirmationLink.styled'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import { useNavigate } from 'react-router'

const OfferConfirmationLink = () => {
  const navigate = useNavigate()
  return (
    <Content>
      <Title>Wycena</Title>
      <Text small margin="0 0 3vw 0">
        W odpowiedzi na złożoną przez Ciebie prośbę o ofertę informujemy, że przedział cenowy tego zlecenia wynosi:{' '}
        <Text medium inline>
          1 000 - 2 000 EUR
        </Text>
      </Text>
      <Text small margin="0 0 2vw 0">
        Jeśli chcesz poznać szczegóły lub/i zlecić zlecenie konieczne jest założenie konta w naszym serwisie
      </Text>
      <ButtonContainer>
        <Button
          primary
          light
          label="Zobacz szczegóły"
          onClick={(e) => {
            e.preventDefault()
            navigate('/collect-offers/account')
          }}
        />
      </ButtonContainer>

      <Text small margin="3vw 0 1vw 0">
        If that have any question, just reply to this email - we’re always happy to help out.
      </Text>
      <Text small>Cheers,</Text>
      <Text small>DroneConnect Team</Text>
    </Content>
  )
}

export default OfferConfirmationLink
