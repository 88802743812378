import PublicRoutes from './routing/PublicRoutes'
import PrivateRoutes from './routing/PrivateRoutes'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from './context/AuthContext'
import { getAccountType, getToken, getUserId } from './api/sessionStorage'
import 'primeicons/primeicons.css'

function App() {
  const { setUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  let loggedIn = false

  useEffect(() => {
    const token = getToken()
    const userId = getUserId()
    const accountType = getAccountType()
    if (token && userId && accountType) {
      loggedIn = true
      setUser((prev) => ({
        ...prev,
        token: token,
        userId: userId,
        isLoggedIn: true,
        accountType: accountType,
      }))
    }

    setLoading(false)
  }, [])
  if (!loading)
    return (
      <div className="App">
        <PublicRoutes />
        <PrivateRoutes />
      </div>
    )
}

export default App
