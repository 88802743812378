import { useQuery } from 'react-query'
import { getLicenseCategories, getServiceCategories, getServices } from '../api/dictionaries'

export const useGetServicesCategories = () => {
  return useQuery({
    queryKey: ['services_categories'],
    queryFn: async () => await getServiceCategories(),
  })
}

export const useGetServices = () => {
  return useQuery({
    queryKey: ['services'],
    queryFn: async () => await getServices(),
  })
}

export const useGetLicenseCategories = () => {
  return useQuery({
    queryKey: ['services_categories'],
    queryFn: async () => await getLicenseCategories(),
  })
}
