import { useState, useCallback, useEffect, useRef } from 'react'
import { Tooltip } from 'primereact/tooltip'
import { useDropzone } from 'react-dropzone'
import {
  Dropzone,
  AttachmentList,
  AttachmentListItem,
  AttachmentDetails,
} from '@pages/pilot/RegisterPilot/Licenses/Licenses.styled'
import { AiOutlineFile } from 'react-icons/ai'
import { RiDeleteBin6Line } from 'react-icons/ri'
import {
  useGetCitiesByCountry,
  useGetCitiesByState,
  useGetCountries,
  useGetRegions,
  useGetState,
} from '@services/locations.services'
import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import FormError from '@components/form/FormError/FormError'
import {
  RadioContainer,
  RadioButton,
  RadioButtonLabel,
} from './ProjectInfo.styled'
import { useFormContext, Controller } from 'react-hook-form'
import { motion } from 'framer-motion'
import { ErrorMessage } from '@hookform/error-message'
import { useGetServices } from '@services/dictionaries.services'
import { Calendar } from 'primereact/calendar'
import { MultiSelect } from 'primereact/multiselect'
import { Dropdown } from 'primereact/dropdown'
import { useTranslation } from 'react-i18next'

const ProjectInfo = ({ next }) => {
  const [fileName, setFileName] = useState('')
  const [category, setCategory] = useState(null)
  const { t } = useTranslation()
  const [files, setFiles] = useState([])
  const [active, setActive] = useState('oneDay')
  let today = new Date()
  let month = today.getMonth()
  let year = today.getFullYear()
  let prevMonth = month === 0 ? 11 : month - 1
  let prevYear = prevMonth === 11 ? year - 1 : year
  let nextMonth = month === 11 ? 0 : month + 1
  let nextYear = nextMonth === 0 ? year + 1 : year

  let minDate = new Date()

  minDate.setMonth(month)
  minDate.setFullYear(prevYear)

  const validateDate = (value) => {
    if (value < today) {
      return 'Selected date cannot be in the past.'
    }
    return true
  }
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const convertFileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = async () => {
        resolve(reader.result)
      }

      reader.onerror = async (error) => {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  }
  const inputRef = useRef(null)

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const base64files = [...files]
      const updatedFiles = acceptedFiles.map(async (file) => {
        const base64 = await convertFileToBase64(file)
        return { name: file.name, data: base64 }
      })

      const resolvedFiles = await Promise.all(updatedFiles)
      const newFiles = base64files.concat(resolvedFiles)
      setFiles([...newFiles])

      setValue('files', newFiles)
    },
    [files, setValue]
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { data: regions } = useGetRegions()
  const { data: countries } = useGetCountries(watch('locationRegion')?.name)
  const { data: states } = useGetState(watch('locationCountry')?.id)
  const { data: citiesByCountry } = useGetCitiesByCountry(
    watch('locationCountry')?.id
  )

  const { data: citiesByState } = useGetCitiesByState(
    watch('locationState')?.id
  )
  const handleRemoveFile = (name) => {
    const updatedFiles = files.filter((file) => file.name !== name)
    setFiles([...updatedFiles])
    setValue('files', [...updatedFiles])
  }
  const { data: services } = useGetServices()

  function getFileMimeType(fileName) {
    const fileExtension = fileName.split('.').pop().toLowerCase()

    switch (fileExtension) {
      case 'pdf':
        return 'application/pdf'
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg'
      default:
        return 'application/octet-stream'
    }
  }

  function downloadBase64File(base64Data, fileName) {
    const mimeType = getFileMimeType(fileName)
    const linkSource = `data:${mimeType};base64,${base64Data}`
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="w-full"
    >
      <Card width="w-full">
        <Text margin="14px 0 18px 0" large>
          {t('add-auction.project-info.title')}
        </Text>
        <RadioContainer>
          <RadioButton
            active={active === 'oneDay'}
            value={true}
            onClick={() => setActive((active) => 'oneDay')}
          >
            <RadioButtonLabel>
              {t('add-auction.project-info.one-day')}
            </RadioButtonLabel>
          </RadioButton>
          <RadioButton
            active={active === 'fewDays'}
            value={false}
            onClick={() => setActive((active) => 'fewDays')}
          >
            <RadioButtonLabel>
              {t('add-auction.project-info.few-days')}
            </RadioButtonLabel>
          </RadioButton>
        </RadioContainer>

        {active === 'oneDay' ? (
          <FormGroup type="text">
            <Label>{t('add-auction.project-info.service-date')}</Label>

            <Controller
              name="dateFrom"
              control={control}
              rules={{
                required: t('add-auction.project-info.field-required'),
                validate: validateDate,
              }}
              render={({ field }) => (
                <Calendar
                  {...field}
                  onChange={(e) => field.onChange(e.value)}
                  showIcon
                  minDate={minDate}
                />
              )}
            />

            <ErrorMessage
              errors={errors}
              name="dateFrom"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>
        ) : (
          <FormGroup type="checkbox">
            <FormGroup type="text" width="fit-content" margin="0 0.625vw 0px 0">
              <Label>{t('add-auction.project-info.service-date')}</Label>
              <Controller
                name="dateFrom"
                control={control}
                rules={{
                  required: t('add-auction.project-info.filed-required'),
                  validate: validateDate,
                }}
                render={({ field }) => (
                  <Calendar
                    {...field}
                    onChange={(e) => field.onChange(e.value)}
                    showIcon
                    minDate={minDate}
                    selectionMode="range"
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="dateFrom"
                render={({ message }) => <FormError message={message} />}
              />
            </FormGroup>
          </FormGroup>
        )}

        <FormGroup type="text">
          <Label>{t('add-auction.project-info.category')}</Label>
          <Dropdown
            onChange={(e) => {
              setCategory(e.value)
              setValue('serviceId', e.value.service.id)
            }}
            options={services?.data}
            optionLabel="name"
            placeholder={t('add-auction.project-info.pick-category')}
            className="w-full md:w-14rem"
            filter
            value={category}
          />

          <ErrorMessage
            errors={errors}
            name="serviceId"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>
        <FormGroup type="text">
          <Label>{t('add-auction.project-info.location')}</Label>
          <div
            style={{
              display: 'flex',
              gap: 5,
              flexDirection: 'column',
              marginTop: 5,
            }}
          >
            <FormGroup>
              <Controller
                name="locationRegion"
                control={control}
                rules={{
                  required: t('add-auction.project-info.field-required'),
                }}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={regions ?? []}
                    optionLabel="name"
                    filter
                    placeholder={t('add-auction.project-info.pick-region')}
                    className="w-full md:w-14rem"
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="locationRegion"
                render={({ message }) => <FormError message={message} />}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="locationCountry"
                control={control}
                rules={{
                  required: t('add-auction.project-info.field-required'),
                }}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={countries ?? []}
                    optionLabel="name"
                    filter
                    placeholder={t('add-auction.project-info.pick-country')}
                    className="w-full md:w-14rem"
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="locationCountry"
                render={({ message }) => <FormError message={message} />}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="locationState"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={states ?? []}
                    optionLabel="name"
                    filter
                    placeholder={t('add-auction.project-info.pick-state')}
                    className="w-full md:w-14rem"
                  />
                )}
              />

              <ErrorMessage
                errors={errors}
                name="locationState"
                render={({ message }) => <FormError message={message} />}
              />
            </FormGroup>
            {watch('locationState') ? (
              <FormGroup>
                <Controller
                  name="locationCity"
                  control={control}
                  rules={{
                    required: t('add-auction.project-info.field-required'),
                  }}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={citiesByState ?? []}
                      optionLabel="name"
                      filter
                      placeholder={t('add-auction.project-info.pick-city')}
                      className="w-full md:w-14rem"
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="locationCity"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
            ) : (
              <FormGroup>
                <Controller
                  name="locationCity"
                  control={control}
                  rules={{
                    required: t('add-auction.project-info.field-required'),
                  }}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={citiesByCountry ?? []}
                      optionLabel="name"
                      filter
                      placeholder={t('add-auction.project-info.pick-city')}
                      className="w-full md:w-14rem"
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="locationCity"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
            )}
          </div>
        </FormGroup>
        <FormGroup type="text">
          <Label>{t('add-auction.project-info.language')}</Label>
          <Input
            type="text"
            width="23.75vw"
            name="language"
            {...register('language', {
              required: t('add-auction.project-info.field-required'),
            })}
            hasError={errors.hasOwnProperty('language')}
          />
          <ErrorMessage
            errors={errors}
            name="language"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>
        <FormGroup type="text">
          <Label
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            <Tooltip target=".description">
              <div style={{ fontSize: 12, width: 300 }}>
                {t('add-auction.project-info.tooltip-1')}
                <br />
                <br />
                {t('add-auction.project-info.tooltip-2')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-3')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-4')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-5')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-6')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-7')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-8')}
                <br />
                {t('add-auction.project-info.tooltip-9')}
              </div>
            </Tooltip>
            <span style={{ display: 'inline' }}>
              {t('add-auction.project-info.description')}
            </span>
            <i
              data-pr-position="right"
              data-pr-at="right+5 top"
              data-pr-my="left center-2"
              style={{ display: 'inline', marginLeft: 10, cursor: 'pointer' }}
              className="pi pi-info-circle description"
            />
          </Label>

          <textarea
            width="23.75vw"
            name="description"
            {...register('description', {
              required: t('add-auction.project-info.field-required'),
            })}
            hasError={errors.hasOwnProperty('description')}
          />
          <ErrorMessage
            errors={errors}
            name="description"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        <FormGroup type="text">
          <Label>{t('add-auction.project-info.background-photo')}</Label>
          <Text secondary small italic>
            {t('add-auction.project-info.background-photo-desc')}
          </Text>

          <Controller
            name="background"
            control={control}
            render={({ field }) => (
              <div
                className="file-upload-container"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '24px',
                }}
              >
                <label className="file-upload-label">
                  <input
                    ref={inputRef}
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0]
                      setFileName(e.target.files[0]?.name)
                      const reader = new FileReader()

                      reader.onloadend = () => {
                        field.onChange(reader.result)
                      }

                      reader.readAsDataURL(file)
                    }}
                    className="file-upload-input"
                  />
                  {watch('background') ? (
                    <span className="file-name">{fileName}</span>
                  ) : (
                    <span className="placeholder-text">
                      {t('add-auction.project-info.pick-file')}
                    </span>
                  )}
                  <i className="pi pi-upload" />
                </label>
                {watch('background') && (
                  <Button
                    dark
                    margin="7px 0 0 0"
                    outlined
                    onClick={(e) => {
                      e.preventDefault()
                      setValue('background', '')
                    }}
                    label={
                      <p>
                        <RiDeleteBin6Line />{' '}
                        {t('add-auction.project-info.delete')}
                      </p>
                    }
                  />
                )}
              </div>
            )}
          />
        </FormGroup>

        <FormGroup type="text">
          <Label>{t('add-auction.project-info.attachments')}</Label>
          <Text secondary small italic>
            {t('add-auction.project-info.attachments-desc')}
          </Text>

          <Dropzone {...getRootProps()} className="dropzone">
            <input {...getInputProps()} className="dropzone" />
            <Text medium secondary>
              {t('add-auction.project-info.drop-files')}
            </Text>
            <Button
              label={t('add-auction.project-info.upload')}
              primary
              light
              type="button"
            />
          </Dropzone>

          <AttachmentList
            edit
            style={{ flexWrap: 'wrap', display: 'flex', flexDirection: 'row' }}
          >
            {files?.map((el) => (
              <div>
                <AttachmentListItem
                  onClick={() => downloadBase64File(el.data, el.name)}
                >
                  <AiOutlineFile className="icon" />
                  <AttachmentDetails>
                    <p>{el.name}</p>
                    <span>2MB</span>
                  </AttachmentDetails>
                </AttachmentListItem>
                <Button
                  dark
                  outlined
                  onClick={() => handleRemoveFile(el.name)}
                  label={
                    <p>
                      <RiDeleteBin6Line />{' '}
                      {t('add-auction.project-info.delete')}
                    </p>
                  }
                  margin="1vw 0 0 0"
                  width="fit-content"
                />
              </div>
            ))}
          </AttachmentList>
        </FormGroup>

        <Button
          label={t('add-auction.project-info.next')}
          primary
          light
          onClick={(e) => {
            e.preventDefault()

            if (active === 'oneDay') {
              next([
                'dateFrom',
                'serviceId',
                'description',
                'locationRegion',
                'locationCity',
                'locationCountry',
                'language',
              ])
            } else {
              next([
                'dateFrom',
                'dateTo',
                'serviceId',
                'locationRegion',
                'locationCity',
                'locationCountry',
                'description',
                'language',
              ])
            }
          }}
        />
      </Card>
    </motion.div>
  )
}

export default ProjectInfo
