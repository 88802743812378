import { api } from './api.service'

export const getUser = async (id) => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/users/${id}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
  return res
}

export const getPilot = async (token) => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/pilots`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const updateUser = async (id, data) => {
  const res = await api.put(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
      body: JSON.stringify(data),
    }
  )
  return res
}

export const forgotPassword = async (id, data) => {
  const res = await api.put(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
      body: JSON.stringify(data),
    }
  )
  return res
}

export const updatePilot = async (id, data) => {
  const res = await api.put(
    `${process.env.REACT_APP_API_URL}/pilots/${id}/update`,
    data,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
        'Content-Type': 'application/json',
      },
    }
  )
  return res
}
