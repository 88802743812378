import { Container } from './MainLayout.styled'
import Navbar from '../Navbar/Navbar'
import TrackingBar from '../TrackingBar/TrackingBar'
import { Container as Grid } from '@pages/common/AddOrder/AddOrder.styled'

const MainLayout = ({ children, locations, title, privateRoute }) => {
  const token = localStorage.getItem('dc_auth_token')

  if (!token && privateRoute) {
    return <p>You must be logged in to see this page</p>
  } else {
    return (
      <Container>
        <Navbar />
        <div style={{ paddingTop: 113, paddingBottom: 100 }}>
          {locations && title && (
            <div style={{ padding: '24px 64px' }} className="trackingbar">
              <TrackingBar locations={locations} />
            </div>
          )}
          {locations && title && (
            <Grid
              style={{
                padding: 0,
                height: 'fit-content',
                gridTemplateRows: '',
              }}
              className="grid"
            >
              <div></div>
              <div
                className="title"
                style={{
                  paddingBottom: '32px',
                  fontSize: '18px',
                  fontWeight: 600,
                  paddingLeft: '48px',
                }}
              >
                {title}
              </div>
            </Grid>
          )}
          {children}
        </div>
      </Container>
    )
  }
}

export default MainLayout
