import {
  Details,
  DetailsRow,
  DetailsBox,
  EditInformation,
  Form,
} from '../PilotAccount.styled'
import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Badge from '@components/ui/Badge/Badge'
import Separator from '@components/ui/Separator/Separator'
import Button from '@components/ui/Button/Button'
import Input from '@components/form/Input/Input'
import Select from '@components/form/Select/Select'
import Label from '@components/form/Label/Label'
import FormGroup from '@components/form/FormGroup/FormGroup'
import { FiEdit2 } from 'react-icons/fi'
import { useState, useEffect } from 'react'
import { MultiSelect } from 'primereact/multiselect'
import useToast from '@hooks/useToast'

import {
  useGetLicenseCategories,
  useGetServices,
} from '@services/dictionaries.services'
import { useForm } from 'react-hook-form'
import { useUpdatePilot } from '@services/user.services'
import {
  useGetCitiesByCountry,
  useGetCountries,
  useGetRegions,
  useGetState,
} from '@services/locations.services'
import Locations from './Locations'
import { useTranslation } from 'react-i18next'

const PilotServices = ({ pilot }) => {
  const { data: regions } = useGetRegions()
  const { showToast, ToastComponent } = useToast()
  let pilotServicesId = pilot.pilotServices.map((el) => el.serviceId)
  const { data } = useGetServices()

  const [selectedServices, setSelectedServices] = useState(
    data?.data?.filter((el) => pilotServicesId.includes(el.service.id))
  )

  const { register, handleSubmit, setValue, watch, control } = useForm({
    defaultValues: async () => ({
      depositPaymentAcceptance: pilot?.depositPaymentAcceptance,
      prePaymentAcceptance: pilot?.prePaymentAcceptance,
      postPaymentAcceptance: pilot?.postPaymentAcceptance,
      preferredLocations: pilot?.preferredLocations,
      pilotServices: pilot?.pilotServices,
      generalAgreementNote: pilot?.generalAgreementNote ?? '',
      preferredLocationRegion: pilot?.preferredLocationRegion ?? '',
      preferredLocationCountry: pilot?.preferredLocationCountry ?? '',
      preferredLocationCity: pilot?.preferredLocationCity ?? '',
      preferredLocationState: pilot?.preferredLocationState ?? '',
      generalAgreementNote: pilot?.generalAgreementNote ?? '',
    }),
  })

  useEffect(() => {
    const formatCategories = selectedServices?.map((item) => {
      return { serviceId: item.service?.id }
    })
    setValue('pilotServices', formatCategories)
  }, [selectedServices])

  const updatePilot = useUpdatePilot(pilot?.user?.publicId)

  const { t } = useTranslation()
  const onSubmit = (data) => {
    const updatedData = {
      ...pilot,
      ...data,
      preferredLocationRegion: data.preferredLocationRegion.id ?? 0,
      preferredLocationCountry: data.preferredLocationCountry.id ?? 0,
      preferredLocationState: data.preferredLocationState.id ?? 0,
      preferredLocationCity: data.preferredLocationCity.id ?? 0,
    }
    delete updatedData.user
    delete updatedData.publicId

    updatePilot.mutate(JSON.stringify(updatedData), {
      onSuccess: () => {
        setMode('view')
        showToast('success', 'Success', t('services.updated'))
      },
    })
  }
  const services = useGetServices()
  const [mode, setMode] = useState('view')

  const region = regions?.filter(
    (el) => el.id === pilot?.preferredLocationRegion
  )[0]?.name

  const { data: countries } = useGetCountries(region)

  const country = countries?.filter(
    (el) => el.id === pilot?.preferredLocationCountry
  )[0]?.name
  const { data: cities } = useGetCitiesByCountry(
    countries?.filter((el) => el.id === pilot?.preferredLocationCountry)[0]?.id
  )
  const { data: states } = useGetState(
    countries?.filter((el) => el.id === pilot?.preferredLocationCountry)[0]?.id
  )
  const state = states?.filter(
    (el) => el.id === pilot.preferredLocationState
  )[0]?.name

  const city = cities?.filter((el) => el.id === pilot.preferredLocationCity)[0]
    ?.name
  return (
    <Card width="100%" form padding="2vw">
      {ToastComponent}
      <Text large margin="0 0 2vw 1vw">
        {t('services.services')}
      </Text>
      {mode === 'view' && (
        <Details>
          <DetailsRow>
            <Text small> {t('services.category')}</Text>
            <DetailsBox>
              {pilot?.pilotServices &&
                pilot?.pilotServices?.map((el) => {
                  const serviceName = services?.data?.data?.filter(
                    (item) => item?.service?.id === el.serviceId
                  )[0]?.name
                  return <Badge green>{serviceName}</Badge>
                })}
            </DetailsBox>
          </DetailsRow>
          <DetailsRow>
            <Text small> {t('services.general-notes')}</Text>
            <DetailsBox>{pilot?.generalAgreementNote}</DetailsBox>
          </DetailsRow>
          <DetailsRow>
            <Text small> {t('services.preffered-locations')}</Text>
            <DetailsBox>
              <Badge grey>
                {region}, {country}, {state}, {city}
              </Badge>
            </DetailsBox>
          </DetailsRow>
          <DetailsRow>
            <Text small> {t('services.payments')}</Text>
            <DetailsBox>
              {pilot?.prePaymentAcceptance && (
                <Badge grey> {t('services.pre')}</Badge>
              )}
              {pilot?.postPaymentAcceptance && (
                <Badge grey> {t('services.post')}</Badge>
              )}
              {pilot?.depositPaymentAcceptance && (
                <Badge grey> {t('services.deposit')}</Badge>
              )}
            </DetailsBox>
          </DetailsRow>
        </Details>
      )}
      {mode === 'edit' && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup type="text">
            <Label> {t('services.category')}</Label>
            <MultiSelect
              value={selectedServices}
              onChange={(e) => setSelectedServices(e.value)}
              options={data?.data}
              optionLabel="name"
              placeholder={t('services.pick-category')}
              className="w-full md:w-20rem"
            />
          </FormGroup>
          <Locations
            control={control}
            watch={watch}
            locations={{ region, city, state, country }}
            setValue={setValue}
          />
          <FormGroup type="text">
            <Label small secondary margin="0 0 1vw 0">
              {t('services.general-notes')}
            </Label>
            <Input
              {...register('generalAgreementNote')}
              name="generalAgreementNote"
            />
          </FormGroup>
          <Text small secondary margin="0 0 1vw 0">
            {t('services.payments')}
          </Text>
          <FormGroup type="checkbox" margin="0">
            <Input
              type="checkbox"
              name="postPaymentAcceptance"
              {...register('postPaymentAcceptance')}
            />
            <Label>{t('services.post')}</Label>
          </FormGroup>
          <FormGroup type="checkbox" margin="0">
            <Input
              type="checkbox"
              name="prePaymentAcceptance"
              {...register('prePaymentAcceptance')}
            />
            <Label>{t('services.pre')}</Label>
          </FormGroup>
          <FormGroup type="checkbox" margin="0">
            <Input
              type="checkbox"
              name="depositPaymentAcceptance"
              {...register('depositPaymentAcceptance')}
            />
            <Label>{t('services.deposit')}</Label>
          </FormGroup>
          <Text small secondary margin="2vw 0 1vw 0">
            {t('services.agreements')}:
          </Text>
          {mode === 'edit' && (
            <EditInformation>
              {mode === 'edit' && (
                <Button
                  label={
                    updatePilot.isLoading ? (
                      <p>
                        {t('services.loading')}
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: '14px' }}
                        ></i>{' '}
                      </p>
                    ) : (
                      t('services.save')
                    )
                  }
                  light
                  primary
                  type="submit"
                />
              )}
              {mode === 'edit' && (
                <Button
                  label={t('services.cancel')}
                  light
                  outlined
                  blue
                  onClick={() => setMode('view')}
                />
              )}
            </EditInformation>
          )}
        </Form>
      )}
      {mode === 'view' && <Separator />}
      <EditInformation>
        {mode === 'view' && (
          <Button
            label={
              <p>
                <FiEdit2 /> Edytuj dane
              </p>
            }
            outlined
            blue
            onClick={(e) => {
              e.preventDefault()
              setMode((mode) => 'edit')
            }}
          />
        )}
      </EditInformation>
    </Card>
  )
}

export default PilotServices
