import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  height: fit-cntent;
  width: fit-content;
  top: 12vw;
  right: 3vw;
  z-index: 1;
`
