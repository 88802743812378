import { api } from './api.service'
import { saveAccountType, saveToken, saveUserId, saveUsername } from './sessionStorage'

export const login = async (data) => {
  const res = await api.post(`/users/Login`, data, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
  if (res.status === 200) {
    saveToken(res.data.token)
    saveUserId(res.data.publicUserId)
    saveUsername(res.data.email)
    saveAccountType(res.data.pilot === true ? 'pilot' : 'customer')
  }
  return res
}

export const registerUser = async (data) => {
  const res = await api.post(`${process.env.REACT_APP_API_URL}/users/Register`, data, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
  return res
}

export const confirmAccount = async (id) => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/users/${id}/confirmation`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
  return res.data
}

export const changePassword = async (id, data) => {
  const res = await api.put(`${process.env.REACT_APP_API_URL}/users/${id}/changePassword`, data, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
  return res.data
}
