import TrackingBar from '@components/layout/TrackingBar/TrackingBar'
import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Container, Box } from './CustomerAuction.styled'
import Auction from '@components/ui/Auction/Auction'
import { useGetAuctionQuery } from '@services/auctions.services'
import { AuthContext } from '@context/AuthContext'

const CustomerAuction = () => {
  const params = useParams()
  const {
    user: { token },
  } = useContext(AuthContext)
  const { data, isLoading } = useGetAuctionQuery(params.id, token)

  return (
    <Container>
      <TrackingBar
        locations={[
          { name: 'DroneConnect', path: '/' },
          { name: 'auctions-and-offers', path: '/customer/auctions' },
          { name: 'project' },
        ]}
      />
      <Box>
        <Auction data={data} isLoading={isLoading} customer />
      </Box>
    </Container>
  )
}

export default CustomerAuction
