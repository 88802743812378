import styled from 'styled-components'

export const ImageContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  justify-content: flex-start;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    align-items: center;
    justify-content: center;
  }
`

export const Image = styled.img`
  height: 100%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  object-fit: cover;
  color: white;
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
`

export const ImagePlaceholder = styled.div`
  height: 100%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6vw;
  color: white;
`
