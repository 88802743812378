import { Container, Box, Logo, Title, Card } from './Footer.styled'
import Button from '../../ui/Button/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const { t } = useTranslation()
  function downloadPdf(path) {
    const pdfUrl = `./${path}.pdf`

    const link = document.createElement('a')
    link.href = pdfUrl
    link.setAttribute('download', `${path}`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const navigate = useNavigate()
  return (
    <Container>
      <Box>
        <Logo />
        <p className="description">
          {t('footer.description-1')}
          <br />
          {t('footer.description-2')}
        </p>
      </Box>
      <Box>
        <Title>{t('footer.links')}</Title>
        <p className="link" onClick={() => downloadPdf('privacyPolicy')}>
          {t('footer.link-1')}
        </p>
        <p className="link" onClick={() => downloadPdf('customers')}>
          {t('footer.link-2')}
        </p>
        <p className="link" onClick={() => downloadPdf('pilot')}>
          {t('footer.link-3')}
        </p>
        <p className="link" onClick={() => downloadPdf('rodo-customer')}>
          {t('footer.link-4')}
        </p>
        <p className="link" onClick={() => downloadPdf('rodo-pilot')}>
          {t('footer.link-5')}
        </p>
      </Box>
      <Box>
        <Title>Pomoc</Title>
        <p className="link">+48 570 502 102</p>
        <p className="link">info@droneconnect.eu</p>
      </Box>
      <Box>
        <Card>
          <h4>{t('footer.text-1')}</h4>
          <p>{t('footer.text-2')}</p>
          <Button
            primary
            light
            label={t('footer.register')}
            onClick={() => navigate('/register/pilot')}
          />
        </Card>
      </Box>
    </Container>
  )
}

export default Footer
