import axios from 'axios'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      // removeToken()
      // removeUserGroups()
      // removeUsername()
      // window.location.replace('/')
    }
    return Promise.reject(error)
  }
)
