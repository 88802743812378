import styled from 'styled-components'

export const Text = styled.h3`
  font-size: ${(props) => props.large && '14px'};
  font-size: ${(props) => props.medium && '16px'};
  font-size: ${(props) => props.small && '12px'};
  font-weight: ${(props) => props.secondary && '600'};
  color: ${(props) => props.primary && '#FF771C'};
  color: ${(props) => props.secondary && '#3D3D3D'};
  margin: ${(props) => props.margin && props.margin};
  text-align: ${(props) => props.center && 'center'};
`
