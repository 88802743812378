import { Routes as Routing, Route, useLocation } from 'react-router-dom'
import MainLayout from '@components/layout/MainLayout/MainLayout'

import CustomerAccount from '@pages/customer/CustomerAccount/CustomerAccount'
import AuctionsOffersList from '@pages/customer/AuctionsOffersList/AuctionsOffersList'
import CustomerAuction from '@pages/customer/CustomerAuction/CustomerAuction'

import PilotAccount from '@pages/pilot/PilotAccount/PilotAccount'
import PilotAuctionList from '@pages/pilot/PilotAuctionList/PilotAuctionList'
import PilotAuction from '@pages/pilot/PilotAuction/PilotAuction'
import PilotBids from '@pages/pilot/PilotBids/PilotBids'
import OfferEdit from '@components/ui/OfferEdit/OfferEdit'
import AuctionEdit from '@components/ui/AuctionEdit/AuctionEdit'

const PrivateRoutes = () => {
  const location = useLocation()

  return (
    <Routing location={location} key={location.pathname}>
      <Route
        path="/customer/account"
        exact
        element={
          <MainLayout privateRoute>
            <CustomerAccount />
          </MainLayout>
        }
      />

      <Route
        path="/customer/auctions"
        exact
        element={
          <MainLayout privateRoute>
            <AuctionsOffersList />
          </MainLayout>
        }
      />
      <Route
        path="/customer/auctions/:id"
        exact
        element={
          <MainLayout privateRoute>
            <CustomerAuction />
          </MainLayout>
        }
      />
      <Route
        path="/customer/auctions/:id/edit-offer"
        exact
        element={
          <MainLayout privateRoute>
            <OfferEdit />
          </MainLayout>
        }
      />
      <Route
        path="/customer/auctions/:id/edit-auction"
        exact
        element={
          <MainLayout privateRoute>
            <AuctionEdit />
          </MainLayout>
        }
      />

      <Route
        path="/pilot/account"
        exact
        element={
          <MainLayout privateRoute>
            <PilotAccount />{' '}
          </MainLayout>
        }
      />
      <Route
        path="/pilot/auctions"
        exact
        element={
          <MainLayout privateRoute>
            <PilotAuctionList />
          </MainLayout>
        }
      />
      <Route
        path="/pilot/bids/:id"
        exact
        element={
          <MainLayout privateRoute>
            <PilotAuction />
          </MainLayout>
        }
      />
      <Route
        path="/pilot/bids"
        exact
        element={
          <MainLayout
            private
            title="Licytuję"
            locations={[
              { name: 'DroneConnect', path: '/' },
              { name: 'bids', path: '/' },
            ]}
            privateRoute
          >
            <PilotBids />
          </MainLayout>
        }
      />
    </Routing>
  )
}

export default PrivateRoutes
