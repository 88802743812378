import { useMutation, useQueryClient } from 'react-query'
import { changePassword, confirmAccount, login, registerUser } from '../api/auth'
import { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'

import { useNavigate } from 'react-router-dom'
import { updatePilot } from '../api/user'

export const useConfirmAccount = () => {
  return useMutation({
    mutationKey: 'confirmation',
    mutationFn: async (id) => confirmAccount(id),
  })
}

export const useRegisterUser = () => {
  return useMutation({
    mutationKey: ['register_user'],
    mutationFn: async (data) => await registerUser(data),
  })
}

export const useChangePassword = (id) => {
  return useMutation({
    mutationKey: ['change_password'],
    mutationFn: async (data) => await changePassword(id, data),
  })
}

export const useLoginQuery = () => {
  const queryClient = useQueryClient()
  const { setUser } = useContext(AuthContext)
  return useMutation({
    mutationKey: ['login'],
    mutationFn: async (data) => {
      const res = await login(data)
      setUser((prev) => ({
        ...prev,
        isLoggedIn: true,
        accountType: res.data.pilot ? 'pilot' : 'customer',
        token: res.data.token,
        userId: res.data.publicUserId,
      }))
      return res
    },
    onSuccess: () => queryClient.invalidateQueries(['user']),
  })
}

export const useRegisterPilot = () => {
  const navigate = useNavigate()

  let user
  let pilot
  return useMutation({
    mutationKey: ['user'],

    mutationFn: async (data) => {
      user = data.user
      pilot = {
        ...data.pilot,
        pilotServices: data.pilot.pilotServices,
        encodedLicenseCategoriesJson: `[${data.pilot.encodedLicenseCategoriesJson}]`,
        encodedFilesJson: data.pilot.encodedFilesJson ? data.pilot.encodedFilesJson : '',
        preferredLocations: data.pilot.preferredLocations ? data.pilot.preferredLocations : '',
      }
      delete pilot.serviceId
      return await registerUser(user)
    },
    onSuccess: async (data) => await updatePilot(data.data.publicId, pilot),
  })
}

export const useSignupClientQuery = () => {}
export const useSignupPilotQuery = () => {}
export const useLogoutQuery = () => {}
