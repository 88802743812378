import Text from '@components/ui/Text/Text'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Badge from '@components/ui/Badge/Badge'
import Button from '@components/ui/Button/Button'
import { useFormContext, Controller } from 'react-hook-form'
import { Form, FormContent } from '../RegisterPilot.styled'
import { useDropzone } from 'react-dropzone'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { AiOutlineFile } from 'react-icons/ai'
import { useCallback, useState } from 'react'
import {
  Details,
  DetailsRow,
  DetailsBox,
  AttachmentList,
  AttachmentListItem,
  AttachmentDetails,
  Dropzone,
} from './Licenses.styled'
import { useGetLicenseCategories } from '@services/dictionaries.services'
import FormError from '@components/form/FormError/FormError'
import { ErrorMessage } from '@hookform/error-message'
import { Calendar } from 'primereact/calendar'
import { scrollToTop } from '@utils/scrollToTop'
import { useTranslation } from 'react-i18next'

const Licenses = ({ next, setCurrentPage }) => {
  const [files, setFiles] = useState([])

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        resolve(reader.result)
      }

      reader.onerror = (error) => {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  }
  const [selectedLicenses, setSelectedLicenses] = useState([])
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext()

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const base64files = [...files]
      const updatedFiles = acceptedFiles.map(async (file) => {
        const base64 = await convertFileToBase64(file)
        return { name: file.name, data: base64 }
      })

      const resolvedFiles = await Promise.all(updatedFiles)
      const newFiles = base64files.concat(resolvedFiles)
      setFiles(newFiles)

      const encodedFilesJson = JSON.stringify(newFiles)
      setValue('pilot.encodedFilesJson', encodedFilesJson)
    },
    [files, setValue]
  )

  const license = useGetLicenseCategories()

  const handleSelectLicenses = (value) => {
    if (selectedLicenses.includes(value)) {
      const updatedLicenses = selectedLicenses.filter(
        (option) => option !== value
      )
      setSelectedLicenses(updatedLicenses)
      setValue('pilot.encodedLicenseCategoriesJson', updatedLicenses)
    } else {
      setValue('pilot.encodedLicenseCategoriesJson', [
        ...selectedLicenses,
        value,
      ])
      setSelectedLicenses([...selectedLicenses, value])
    }
  }

  const handleRemoveFile = (name) => {
    const updatedFiles = files.filter((file) => file.name !== name)
    setFiles([...updatedFiles])
    setValue('pilot.encodedFilesJson', [...updatedFiles])
  }

  function getFileMimeType(fileName) {
    const fileExtension = fileName.split('.').pop().toLowerCase()

    switch (fileExtension) {
      case 'pdf':
        return 'application/pdf'
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg'
      default:
        return 'application/octet-stream'
    }
  }

  function downloadBase64File(base64Data, fileName) {
    const mimeType = getFileMimeType(fileName)
    const linkSource = `data:${mimeType};base64,${base64Data}`
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  const { t } = useTranslation()
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  return (
    <Form>
      <FormContent>
        <Details>
          <FormGroup type="text">
            <Label small>{t('licenses.license-number')}</Label>
            <Input
              {...register('pilot.pilotLicenseNumber', {
                required: t('licenses.field-required'),
              })}
            />
            <ErrorMessage
              errors={errors}
              name="pilot.pilotLicenseNumber"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>
          <FormGroup type="text">
            <Label small>{t('licenses.license-expiry-date')}</Label>
            <Controller
              name="pilot.licenseExpiryDate"
              control={control}
              rules={{ required: 'To pole jest wymagane' }}
              render={({ field }) => (
                <Calendar
                  {...field}
                  onChange={(e) => field.onChange(e.value)}
                  showIcon
                  minDate={new Date()}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="pilot.licenseExpiryDate"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>
          <FormGroup type="text">
            <Label small>{t('licenses.operator-license-number')}</Label>
            <Input
              {...register('pilot.operatorLicenseNumber', {
                required: t('licenses.field-required'),
              })}
            />
            <ErrorMessage
              errors={errors}
              name="pilot.operatorLicenseNumber"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>
          <FormGroup type="text">
            <Label small>{t('licenses.operator-license-expiry-date')}</Label>
            <Controller
              name="pilot.operatorExpiryDate"
              control={control}
              rules={{ required: 'To pole jest wymagane' }}
              render={({ field }) => (
                <Calendar
                  {...field}
                  onChange={(e) => field.onChange(e.value)}
                  showIcon
                  minDate={new Date()}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="pilot.operatorExpiryDate"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>

          <DetailsRow
            {...register('pilot.encodedLicenseCategoriesJson', {
              required: t('licenses.field-required'),
            })}
          >
            <Label small>{t('licenses.license-category')}</Label>
            <DetailsBox
              style={{ display: 'flex', flexWrap: 'wrap', maxWidth: 500 }}
            >
              {license?.data?.data?.map((el, index) => (
                <>
                  <Badge
                    style={{ cursor: 'pointer' }}
                    orange={selectedLicenses.includes(el.licenseCategory.id)}
                    outlined={!selectedLicenses.includes(el.licenseCategory.id)}
                    onClick={() => handleSelectLicenses(el.licenseCategory.id)}
                  >
                    {el.name}
                  </Badge>
                </>
              ))}
            </DetailsBox>
            <ErrorMessage
              errors={errors}
              name="pilot.encodedLicenseCategoriesJson"
              render={({ message }) => (
                <p style={{ color: 'red', fontSize: 10 }}>
                  {t('licenses.one-required')}
                </p>
              )}
            />
            <Text small>{t('licenses.add-files')}</Text>
            <Text small italic>
              {t('licenses.files-desc')}
            </Text>
            <Dropzone {...getRootProps()}>
              <input {...getInputProps()} />
              <Text medium secondary>
                {t('licenses.drop-files')}
              </Text>
              <Button
                label={t('licenses.upload')}
                primary
                light
                type="button"
              />
            </Dropzone>
          </DetailsRow>
          <AttachmentList edit>
            {files?.map((el) => (
              <div>
                <AttachmentListItem
                  onClick={() => downloadBase64File(el.data, el.name)}
                >
                  <AiOutlineFile className="icon" />
                  <AttachmentDetails>
                    <p>{el.name}</p>
                    <span>2MB</span>
                  </AttachmentDetails>
                </AttachmentListItem>
                <Button
                  dark
                  outlined
                  onClick={() => handleRemoveFile(el.name)}
                  label={
                    <p>
                      <RiDeleteBin6Line /> {t('licenses.delete')}
                    </p>
                  }
                  margin="1vw 0 0 0"
                  width="fit-content"
                />
              </div>
            ))}
          </AttachmentList>
          <Text small margin="24px 0 12px 0">
            {t('licenses.agreements')}
          </Text>
          <FormGroup type="checkbox">
            <Input
              name="pilot.agreement"
              type="checkbox"
              {...register('pilot.agreement', {
                required: t('licenses.field-required'),
              })}
            />
            <Label>{t('licenses.agreement-1')}</Label>

            <ErrorMessage
              errors={errors}
              name="pilot.agreement"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>
        </Details>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            label="Wstecz"
            outlined
            blue
            onClick={(e) => {
              e.preventDefault()
              setCurrentPage((prev) => prev - 1)
            }}
          />
          <Button
            label={t('licenses.next')}
            primary
            light
            onClick={(e) => {
              e.preventDefault()
              next([
                'pilot.pilotLicenseNumber',
                'pilot.operatorLicenseNumber',
                'pilot.operatorExpiryDate',
                'pilot.licenseExpiryDate',
                'pilot.agreement',
                'pilot.encodedLicenseCategoriesJson',
              ])
              scrollToTop()
            }}
          />
        </div>
      </FormContent>
    </Form>
  )
}

export default Licenses
