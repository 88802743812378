import styled from 'styled-components'
import { RiCloseLine } from 'react-icons/ri'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center !important;
`
export const Content = styled.div`
  z-index: 999;
  background-color: #f5f7f9;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  width: 400px;

  box-shadow: 0vw 0.01vw 1vw rgba(0, 0, 0, 0.07) !important;
  @media only screen and (max-width: 600px) {
    width: 320px;
  }
`
export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 24;
`

export const Header = styled.div`
  padding: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`
export const Icon = styled(RiCloseLine)`
  height: 24px;
  width: 24px;
  color: #3d3d3d;
  cursor: pointer;
`

export const Form = styled.form`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Card = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
  text-align: center;
  cursor: pointer;
  border-color: ${(props) => (props.active ? '#ff771c' : '#ffffff')};
  border-style: solid;
  border-width: 2px;
  gap: 12px;
  .icon {
    color: #ff771c;
    width: 16px;
    height: 16px;
  }
  @media only screen and (max-width: 600px) {
    min-width: 100% !important;
  }
`

export const CardContainer = styled.div`
  display: flex;
  margin: 8px 0;
  justify-content: center;
  align-items: center;
  gap: 16px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 16px 0;
    width: 100%;
  }
`

export const Footer = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px;
  border-color: #e5e7e9;
  border-top-style: solid;
`
