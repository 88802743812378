import styled from 'styled-components'

export const Dropzone = styled.div`
  width: 40vw;
  margin: 1vw 0;
  background: #f0f2f4;
  height: 10vw;
  border: 2px dashed #cbcbcb;
  border-radius: 0.5vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.8vw;
`
export const Details = styled.div`
  width: 100%;
  margin-bottom: 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
export const Form = styled.div`
  width: 100%;
  margin-bottom: 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
export const EditInformation = styled.div`
  display: flex;
  width: 100%;
  gap: 1vw;
`

export const DetailsBox = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 32px;
`

export const AttachmentList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: ${(props) => props.edit && ' column'};
  gap: 1vw;
`
export const AttachmentListItem = styled.li`
  background-color: rgba(255, 164, 38, 0.1);
  border-radius: 0.5vw;
  display: flex;
  padding: 1vw;
  align-items: center;
  gap: 0.8vw;
  cursor: pointer;
  .icon {
    height: 4vw;
    width: 4vw;
  }
`
export const AttachmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  p {
    font-size: 1vw;
    color: #3d3d3d;
    font-weight: 600;
  }
  span {
    font-size: 0.8vw;
    color: #6c757d;
  }
`

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px
`
