import styled from 'styled-components'

export const Container = styled.div`
  margin: 70px 0 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

export const ProgressBarContainer = styled.div`
  height: 3px;
  background-color: #ebebeb;
  width: 300px;
  border-radius: 20px;
  overflow: hidden;
`

export const ProgressBarFiller = styled.div`
  width: ${(props) => props.filled && props.filled + '%'};
  height: 3px;
  transition: 'width 1s ease-in-out';
  background-color: #00022a;
`
