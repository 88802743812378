import styled from 'styled-components'
import {RiArrowDropDownLine} from 'react-icons/ri';

export const Container = styled.div`
  position: relative;
`
export const Text = styled.p`
  display: flex;
  align-items: center;
  gap: 0.1vw;
  cursor: pointer;
  &:hover {
    color: #FF771C;
  }
`
export const DropdownIcon = styled(RiArrowDropDownLine)`
  font-size: 1.3vw;
  color: #FF771C;
  cursor: pointer;
`

export const Dropdown = styled.div`
  position: absolute;
  height: 4vw;
  width: 4vw;
  background-color: #ffffff;
  border-radius: 8px;
  top: 40px;
  right: 0px;
  color: black;
  &:before {
    content: "";
    position: absolute;
    left: 70%;
    top: -30px;
    border: solid 15px transparent;
    border-bottom-color: #FFF;
    z-index: 1;
  }
`