import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  useGetCitiesByCountry,
  useGetCitiesByState,
  useGetCountries,
  useGetRegions,
  useGetState,
} from '@services/locations.services'
import {
  useGetAuctionQuery,
  useUpdateAuction,
} from '@services/auctions.services'
import { AuthContext } from '@context/AuthContext'
import EditOffer from '@pages/customer/EditOffer/EditOffer'
import EditAuction from '@pages/customer/EditAuction/EditAuction'

const AuctionEdit = () => {
  const params = useParams()
  const {
    user: { token },
  } = useContext(AuthContext)
  const [defaultValues, setDefaultValues] = useState(null)
  const { data: offer, isLoading: isOfferLoading } = useGetAuctionQuery(
    params.id,
    token
  )

  const { data: regions, isLoading: isRegionLoading } = useGetRegions()

  const defaultRegion = regions?.filter(
    (el) => el.id === offer?.locationRegion
  )[0]

  const { data: countries, isLoading: isCountryLoading } = useGetCountries(
    defaultRegion?.name
  )
  const defaultCountry = countries?.filter(
    (el) => el.id === offer?.locationCountry
  )[0]

  const { data: states, isLoading: isStateLoading } = useGetState(
    offer?.locationCountry
  )
  const defaultState = states?.filter((el) => el.id === offer?.locationState)[0]
  const { data: citiesByState, isLoading: isCityByStateLoading } =
    useGetCitiesByState(defaultState?.id)
  const { data: citiesByCountry, isLoading: isCityByCountryLoading } =
    useGetCitiesByCountry(defaultCountry?.id)

  const defaultCityByState = citiesByState?.filter(
    (el) => el.id === offer?.locationCity
  )[0]
  const defaultCityByCountry = citiesByCountry?.filter(
    (el) => el.id === offer?.locationCity
  )[0]

  useEffect(() => {
    const initialValues = {
      ...offer,
      duration: new Date(offer?.rfqEndDate),
      dateFrom: offer?.oneDay
        ? new Date(offer?.dateFrom)
        : [new Date(offer?.dateFrom), new Date(offer?.dateTo)],

      locationRegion: defaultRegion,
      locationCountry: defaultCountry,
      locationState: defaultState,
      locationCity: defaultCityByState
        ? defaultCityByState
        : defaultCityByCountry,
    }
    setDefaultValues(initialValues)
  }, [
    offer,
    regions,
    countries,
    states,
    citiesByState,
    citiesByCountry,
    defaultRegion,
    defaultState,
  ])

  const isLoading =
    isCityByCountryLoading ||
    isCityByStateLoading ||
    isRegionLoading ||
    isCountryLoading
  return (
    <>
      {defaultValues && offer && !isOfferLoading && !isLoading && (
        <EditAuction
          defaultValues={defaultValues}
          offer={offer}
          id={params.id}
          token={token}
          locations={{
            regions,
            countries,
            states,
            citiesByState,
            citiesByCountry,
          }}
        />
      )}
    </>
  )
}

export default AuctionEdit
