import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => (props.white ? 'white' : '#F5F7F9')};
  background-color: ${(props) => (props.form ? '#F5F7F9' : '#F5F7F9')};
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  height: ${(props) => (props.height ? props.height : 'fit-content')};
  border-radius: 12px;
  border-radius: ${(props) => props.form && '0.5vw'};
  padding: 1vw 3vw 3vw;
  padding: ${(props) => props.padding && props.padding};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.center ? 'center' : 'flex-start')};
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 24px 12px;
  }
  svg {
    font-size: 2.1vw !important;
    color: white;
  }
`
