import { useContext, useState } from 'react'
import { Container, Step, TextBox, StepWrapper } from './ProgressBar.styled'
import Text from '../../ui/Text/Text'
import { BsCheck2 } from 'react-icons/bs'
import { AuthContext } from '../../../context/AuthContext'
import { useTranslation } from 'react-i18next'

const ProgressBar = ({
  currentPage,
  addOrder,
  collectOffers,
  registerCustomer,
  registerPilot,
  ...rest
}) => {
  const {
    user: { userId },
  } = useContext(AuthContext)
  const { t } = useTranslation()
  if (registerCustomer && !addOrder && !collectOffers && !registerPilot) {
    return (
      <div>
        <Container>
          <StepWrapper>
            <TextBox>
              <Text medium>{t('progress-bar.account')}</Text>
              <Text small>{t('progress-bar.fill-data')}</Text>
            </TextBox>
            <Step
              filled={currentPage > 1}
              active={currentPage === 1}
              unfilled={currentPage < 1}
              className="step"
            >
              {currentPage === 1 ? '1' : <BsCheck2 />}
            </Step>
          </StepWrapper>
          <StepWrapper>
            <TextBox>
              <Text medium>{t('progress-bar.email-confirmation')}</Text>
              <Text small>{t('progress-bar.data-confirmation')}</Text>
            </TextBox>
            <Step
              filled={currentPage > 2}
              active={currentPage === 2}
              unfilled={currentPage < 2}
            >
              {currentPage === 2 || currentPage < 2 ? '2' : <BsCheck2 />}
            </Step>
          </StepWrapper>
        </Container>
      </div>
    )
  }
  if (collectOffers && !addOrder && !registerCustomer && !registerPilot) {
    return (
      <Container>
        <StepWrapper>
          <TextBox>
            <Text medium>{t('progress-bar.project-info')}</Text>
            <Text small>{t('progress-bar.project-details')}</Text>
          </TextBox>
          <Step
            filled={currentPage > 1}
            active={currentPage === 1}
            unfilled={currentPage < 1}
            className="step"
          >
            {currentPage === 1 ? '1' : <BsCheck2 />}
          </Step>
        </StepWrapper>
        {!userId && (
          <StepWrapper>
            <TextBox>
              <Text medium>{t('progress-bar.contact-info')}</Text>
              <Text small>{t('progress-bar.login-or-register')}</Text>
            </TextBox>
            <Step
              filled={currentPage > 2}
              active={currentPage === 2}
              unfilled={currentPage < 2}
              className="step"
            >
              {currentPage === 2 || currentPage < 2 ? '2' : <BsCheck2 />}
            </Step>
          </StepWrapper>
        )}
      </Container>
    )
  }
  if (addOrder && !registerCustomer && !collectOffers && !registerPilot) {
    return (
      <Container addOrder>
        <StepWrapper>
          <TextBox>
            <Text medium>{t('progress-bar.project-info')}</Text>
            <Text small>{t('progress-bar.project-details')}</Text>
          </TextBox>
          <Step
            filled={currentPage > 1}
            active={currentPage === 1}
            unfilled={currentPage < 1}
            className="step"
          >
            {currentPage === 1 ? '1' : <BsCheck2 />}
          </Step>
        </StepWrapper>
        {!userId && (
          <StepWrapper>
            <TextBox>
              <Text medium>{t('progress-bar.account')}</Text>
              <Text small>{t('progress-bar.login-or-register')}</Text>
            </TextBox>
            <Step
              filled={currentPage > 2}
              active={currentPage === 2}
              unfilled={currentPage < 2}
              className="step"
            >
              {currentPage === 2 || currentPage < 2 ? '2' : <BsCheck2 />}
            </Step>
          </StepWrapper>
        )}

        <StepWrapper>
          <TextBox>
            <Text medium>{t('progress-bar.auction')}</Text>
            <Text small>{t('progress-bar.auction-info')}</Text>
          </TextBox>
          {!userId ? (
            <Step
              filled={currentPage > 3}
              active={currentPage === 3}
              unfilled={currentPage < 3}
            >
              {currentPage === 3 || currentPage < 3 ? '3' : <BsCheck2 />}
            </Step>
          ) : (
            <Step
              filled={currentPage > 2}
              active={currentPage === 2}
              unfilled={currentPage < 2}
            >
              {currentPage === 2 || currentPage < 2 ? '2' : <BsCheck2 />}
            </Step>
          )}
        </StepWrapper>
      </Container>
    )
  }
  if (registerPilot && !addOrder && !collectOffers && !registerCustomer) {
    return (
      <Container>
        {!userId && (
          <StepWrapper>
            <TextBox>
              <Text medium>{t('progress-bar.account')}</Text>
              <Text small>{t('progress-bar.fill-data')}</Text>
            </TextBox>
            <Step
              filled={currentPage > 1}
              active={currentPage === 1}
              unfilled={currentPage < 1}
              className="step"
            >
              {currentPage === 1 ? '1' : <BsCheck2 />}
            </Step>
          </StepWrapper>
        )}

        <StepWrapper>
          <TextBox>
            <Text medium>{t('progress-bar.licenses')}</Text>
            <Text small></Text>
          </TextBox>
          <Step
            filled={currentPage > 2}
            active={currentPage === 2}
            unfilled={currentPage < 2}
            className="step"
          >
            {currentPage === 2 || currentPage < 2 ? '2' : <BsCheck2 />}
          </Step>
        </StepWrapper>
        <StepWrapper>
          <TextBox>
            <Text medium>{t('progress-bar.auctions')}</Text>
            <Text small>{t('progress-bar.preferences')}</Text>
          </TextBox>
          <Step
            filled={currentPage > 3}
            active={currentPage === 3}
            unfilled={currentPage < 3}
          >
            {currentPage === 3 || currentPage < 3 ? '3' : <BsCheck2 />}
          </Step>
        </StepWrapper>
      </Container>
    )
  }
  if (!registerCustomer && !registerPilot && !addOrder && !collectOffers) {
    return (
      <Container>
        <StepWrapper>
          <TextBox>
            <Text medium>{t('progress-bar.project-info')}</Text>
            <Text small>{t('progress-bar.project-details')}</Text>
          </TextBox>
          <Step
            filled={currentPage > 1}
            active={currentPage === 1}
            unfilled={currentPage < 1}
            className="step"
          >
            {currentPage === 1 ? '1' : <BsCheck2 />}
          </Step>
        </StepWrapper>
        {!userId && (
          <StepWrapper>
            <TextBox>
              <Text medium>{t('progress-bar.account')}</Text>
              <Text small>{t('progress-bar.login-or-register')}</Text>
            </TextBox>
            <Step
              filled={currentPage > 2}
              active={currentPage === 2}
              unfilled={currentPage < 2}
              className="step"
            >
              {currentPage === 2 || currentPage < 2 ? '2' : <BsCheck2 />}
            </Step>
          </StepWrapper>
        )}
        <StepWrapper>
          <TextBox>
            <Text medium>{t('progress-bar.auction')}</Text>
            <Text small>{t('progress-bar.auction-info')}</Text>
          </TextBox>
          {userId ? (
            <Step
              filled={currentPage > 3}
              active={currentPage === 3}
              unfilled={currentPage < 3}
            >
              {currentPage === 3 || currentPage < 3 ? '3' : <BsCheck2 />}
            </Step>
          ) : (
            <Step
              filled={currentPage > 2}
              active={currentPage === 2}
              unfilled={currentPage < 2}
            >
              {currentPage === 2 || currentPage < 2 ? '2' : <BsCheck2 />}
            </Step>
          )}
        </StepWrapper>
      </Container>
    )
  }
}

export default ProgressBar

const useHandleProgressBar = (trigger) => {
  const [currentPage, setCurrentPage] = useState(1)

  const next = async (fields) => {
    const result = await trigger(fields)

    if (!result) return
    setCurrentPage((previous) => previous + 1)
  }

  return { next, currentPage, setCurrentPage }
}

export { useHandleProgressBar }
