import styled from 'styled-components'

export const Txt = styled.p`
  color: #3d3d3d;
  color: ${(props) => props.primary && '#FF771C'};
  margin: ${(props) => props.margin && props.margin};
  color: ${(props) => props.secondary && '#A1A5B7'};
  color: ${(props) => props.error && '#e74042'};
  color: ${(props) => props.success && '#26c35c'};
  color: ${(props) => props.white && '#ffffff'};

  font-size: ${(props) => props.small && '12px'};
  font-size: ${(props) => props.medium && '14px'};
  font-size: ${(props) => props.large && '16px'};
  display: ${(props) => props.block && 'block'};
  display: ${(props) => props.inline && 'inline'};
  width: fit-content;
  cursor: ${(props) => props.onClick && 'pointer'};
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  font-weight: ${(props) => props.large && '700'};
  font-weight: ${(props) => props.medium && '600'};
  font-style: ${(props) => props.italic && 'italic'};
`
