import styled from 'styled-components'

export const Container = styled.div`
  background-color: #00022a;
  color: white;
  padding: 0px 32px;
  position: relative;

  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 130px);
  align-items: center;
  @media only screen and (max-width: 600px) {
    justify-content: center;
    align-items: flex-start;
    padding: 32px 24px;
    min-height: fit-content;
  }
`

export const Box = styled.div`
  width: 50%;
  &:first-child {
    width: 55%;
    @media only screen and (max-width: 600px) {
      min-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  img {
    width: 100%;
    @media only screen and (max-width: 600px) {
      position: absolute;
      right: -50px;
      top: -20px;
      z-index: 0;
      height: 270px;
      width: 280px;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
export const Title = styled.h1`
  color: white;
  line-height: 42px;
  font-size: 32px;
  font-weight: 800;
  @media only screen and (max-width: 600px) {
    font-size: 24px;
    z-index: 2;
  }
  span {
    color: #ff771c;
    font-size: 24px;
    @media only screen and (max-width: 600px) {
      width: 100%;
      display: block;
    }
  }
`
export const Subtitle = styled.h5`
  line-height: 26px;
  font-size: 16px;
  font-weight: 300;
  display: block;
  margin-top: 24px;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
    z-index: 2;
  }
`
export const CtaSection = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  @media only screen and (max-width: 600px) {
    justify-content: center;
    margin-top: 24px;
  }
`
export const ButtonContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: fit-content;
  div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: #ff771c;
    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
    p {
      width: fit-content;
      @media only screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
    svg {
      font-size: 24px;
      color: #ff771c;
      cursor: pointer;
      @media only screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  button {
    width: 100%;
  }
`
