import styled from 'styled-components'

export const Container = styled.div`
  background-color: #f5f7f9;  
  width: 100%;
    display: grid;
    padding-top: 24px;
    padding-bottom: 48px;

    grid-template-columns: 20% 60% 20%;
`
export const Content = styled.form`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
`
export const Form = styled.div`
  display: grid;
  max-width: 842px;
  gap: 24px;
  grid-template-columns: 25% 25% 25% 25%;
`

export const Group = styled.div`
  position: relative;
  height: fit-content;
  display: flex;
   justify-content: center;
   align-items: center
 width: 200px;
  svg {
    position: absolute;
    right: 0.5vw;
    bottom: 25%;
    font-size: 1.2vw;
    color: #ff771c;
    z-index: 10;
  }
  .p-multiselect-label-container {
    overflow: hidden !important;
  }
`
