import { Container, Background, Box } from './HowItWorks.styled'
import Headline from '@components/ui/Headline/Headline'
import DiagramImage from '@assets/diagram_big.png'
import { useTranslation } from 'react-i18next'

const HowItWorks = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Background />
      <Box>
        <Headline primary large>
          {t('home.how-it-works.title')}
        </Headline>
      </Box>
      <Box>
        <img src={DiagramImage} />
      </Box>
    </Container>
  )
}

export default HowItWorks
