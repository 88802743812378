import Button from '@components/ui/Button/Button'
import Text from '@components/ui/Text/Text'
import { useNavigate } from 'react-router'
import { AiOutlineUser } from 'react-icons/ai'
import { FaRegAddressCard } from 'react-icons/fa'
import {
  Container,
  Background,
  Content,
  Header,
  Icon,
  Footer,
  Form,
  Card,
  CardContainer,
} from './RegisterModal.styled'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const RegisterModal = ({ setIsRegisterModalOpen, setIsLoginModalOpen }) => {
  const [activeTab, setActiveTab] = useState('')
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Container>
      <Background
        onClick={(e) => {
          e.preventDefault()
          setIsRegisterModalOpen(false)
        }}
      />
      <Content>
        <Header>
          <Text medium>{t('register-modal.title')}</Text>
          <Icon
            onClick={(e) => {
              e.preventDefault()
              setIsRegisterModalOpen(false)
            }}
          />
        </Header>

        <Form>
          <Text medium>{t('register-modal.account-type')}</Text>
          <CardContainer>
            <Card
              active={activeTab === 'customer'}
              onClick={(e) => {
                e.preventDefault()
                setActiveTab('customer')
              }}
            >
              <FaRegAddressCard className="icon" />
              <Text medium>{t('register-modal.customer')}</Text>
              <Text small center>
                {t('register-modal.customer-desc-1')}
                <br /> {t('register-modal.customer-desc-2')}
              </Text>
            </Card>
            <Card
              active={activeTab === 'pilot'}
              onClick={(e) => {
                e.preventDefault()
                setActiveTab('pilot')
              }}
            >
              <AiOutlineUser className="icon" />

              <Text medium>{t('register-modal.pilot')}</Text>
              <Text small center>
                {t('register-modal.pilot-desc-1')} <br />{' '}
                {t('register-modal.pilot-desc-2')}
              </Text>
            </Card>
          </CardContainer>
          <Button
            label={t('register-modal.register')}
            primary
            light
            width="100%"
            margin="1vw 0 1.5vw 0"
            disabled={!activeTab}
            onClick={(e) => {
              e.preventDefault()
              navigate(`/register/${activeTab}`)
            }}
          />
        </Form>
        <Footer>
          <Text secondary small margin="0 0.3vw 0 0">
            {t('register-modal.have-account')}
          </Text>
          <Text
            small
            primary
            onClick={(e) => {
              e.preventDefault()
              setIsRegisterModalOpen(false)
              setIsLoginModalOpen(true)
            }}
          >
            {t('register-modal.login')}
          </Text>
        </Footer>
      </Content>
    </Container>
  )
}

export default RegisterModal
