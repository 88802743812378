import {
  Container,
  Box,
  Content,
  UserInfo,
  ImageContainer,
  Image,
  Right,
} from './PilotAccount.styled'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import TrackingBar from '@components/layout/TrackingBar/TrackingBar'
import PilotServices from './PilotServices/PilotServices'
import PilotLicenses from './PilotLicenses/PilotLicenses'
import AccountInfo from './AccountInfo/AccountInfo'
import { useContext } from 'react'
import { AuthContext } from '@context/AuthContext'
import {
  useGetPilot,
  useUpdatePilot,
  useUpdateUser,
} from '@services/user.services'
import CompanyInfo from './CompanyInfo/CompanyInfo'
import ChangePassword from './ChangePassword/ChangePassword'
import FileUploader from '@components/form/FileUploader/FileUploader'
import { useForm } from 'react-hook-form'
import PilotAccountLoader from './PilotAccountLoader'
import useToast from '@hooks/useToast'
import { useTranslation } from 'react-i18next'
const PilotAccount = () => {
  const { showToast, ToastComponent } = useToast()
  const { control, setValue, handleSubmit } = useForm()
  const {
    user: { token, userId },
  } = useContext(AuthContext)
  const { data: pilot, isLoading } = useGetPilot(token, userId)

  const { t } = useTranslation()
  const updateUser = useUpdateUser(pilot?.data?.user?.publicId, token)
  const onSubmit = (data) => {
    const updatedAvatar = {
      ...pilot?.data?.user,
      avatar: data.avatar,
    }
    updateUser.mutate(updatedAvatar, {
      onSuccess: () =>
        showToast('success', 'Success', t('pilot-account.updated')),
    })
  }
  return (
    <Container>
      {ToastComponent}

      <TrackingBar
        locations={[
          { name: 'DroneConnect', path: '/' },
          { name: 'my-accounts', path: '/' },
        ]}
      />
      <Box>
        {isLoading && <PilotAccountLoader />}
        {!isLoading && pilot && (
          <Content>
            <Text large>{t('pilot-account.account')}</Text>
            <UserInfo>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FileUploader
                  name="avatar"
                  control={control}
                  setValue={setValue}
                  currentAvatar={pilot?.data?.user?.avatar}
                />
                <Button
                  type="submit"
                  light
                  primary
                  width="100%"
                  label={t('pilot-account.save')}
                  margin={'10px 0'}
                />
              </form>
              <Right>
                <AccountInfo pilot={pilot?.data} />
                <CompanyInfo pilot={pilot?.data} />

                <PilotLicenses pilot={pilot?.data} />
                <PilotServices pilot={pilot?.data} />
                <ChangePassword pilot={pilot?.data} />
              </Right>
            </UserInfo>
          </Content>
        )}
      </Box>
    </Container>
  )
}

export default PilotAccount
