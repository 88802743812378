import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import FormGroup from '@components/form/FormGroup/FormGroup'
import FormError from '@components/form/FormError/FormError'
import PasswordRequirements from '@components/ui/PasswordRequirements/PasswordRequirements'
import Button from '@components/ui/Button/Button'
import { ErrorMessage } from '@hookform/error-message'
import { Form, FormContent } from '../RegisterPilot.styled'
import { useFormContext } from 'react-hook-form'
import FileUploader from '@components/form/FileUploader/FileUploader'
import { scrollToTop } from '@utils/scrollToTop'
import { useTranslation } from 'react-i18next'

const AccountInformation = ({ next }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()
  const { t } = useTranslation()
  return (
    <Form>
      <FormContent>
        <FormGroup width="100%">
          <FileUploader
            name="user.avatar"
            setValue={setValue}
            currentAvatar={null}
          />
        </FormGroup>

        <FormGroup type="text" margin="0 0 24px 0">
          <Label>{t('registration.first-name')}</Label>
          <Input
            type="text"
            width="13.75vw"
            name="user.firstName"
            {...register('user.firstName', {
              required: t('registration.field-required'),
            })}
            hasError={errors.hasOwnProperty('user.firstName')}
          />
          <ErrorMessage
            errors={errors}
            name="user.firstName"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        <FormGroup type="text" margin="0 0 24px 0">
          <Label>{t('registration.last-name')}</Label>
          <Input
            type="text"
            width="23.75vw"
            name="user.lastName"
            {...register('user.lastName', {
              required: t('registration.field-required'),
            })}
            hasError={errors.hasOwnProperty('user.lastName')}
          />
          <ErrorMessage
            errors={errors}
            name="user.lastName"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        <FormGroup type="text" margin="0 0 24px 0">
          <Label>{t('registration.e-mail')}</Label>
          <Input
            type="email"
            width="23.75vw"
            name="user.email"
            {...register('user.email', {
              required: t('registration.field-required'),
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Niepoprawny adres e-mail',
              },
            })}
            hasError={errors.hasOwnProperty('user.email')}
          />
          <ErrorMessage
            errors={errors}
            name="user.email"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        <FormGroup type="text" margin="0 0 24px 0">
          <Label>{t('registration.phone-number')}</Label>
          <Input
            type="text"
            width="11.875vw"
            name="user.phone"
            {...register('user.phone', {
              required: false,
            })}
          />
        </FormGroup>

        <FormGroup type="checkbox" margin="0 0 24px 0">
          <Input
            type="checkbox"
            width="1.2vw"
            name="user.isCompany"
            {...register('user.isCompany')}
          />
          <Label>{t('registration.as-company')}</Label>
        </FormGroup>

        {watch('user.isCompany') && (
          <>
            <FormGroup type="text" margin="0 0 24px 0">
              <Label>{t('registration.company-name')}</Label>

              <Input
                type="text"
                width="23.75vw"
                name="user.companyDetails.companyName"
                {...register('user.companyDetails.companyName', {
                  required: watch('user.isCompany')
                    ? t('registration.field-required')
                    : false,
                })}
                hasError={errors.hasOwnProperty(
                  'user.companyDetails.companyName'
                )}
              />
              <ErrorMessage
                errors={errors}
                name="user.companyDetails.companyName"
                render={({ message }) => <FormError message={message} />}
              />
            </FormGroup>
            <FormGroup type="text" margin="0 0 24px 0">
              <Label>{t('registration.tax-id')}</Label>

              <Input
                type="text"
                width="11.875vw"
                name="user.companyDetails.vatCode"
                {...register('user.companyDetails.vatCode', {
                  required: watch('user.isCompany')
                    ? t('registration.field-required')
                    : false,
                })}
                hasError={errors.hasOwnProperty('user.companyDetails.vatCode')}
              />
              <ErrorMessage
                errors={errors}
                name="user.companyDetails.vatCode"
                render={({ message }) => <FormError message={message} />}
              />
            </FormGroup>
            <FormGroup type="text" margin="0 0 24px 0">
              <Label>{t('registration.country')}</Label>

              <Input
                type="text"
                width="23.75vw"
                name="user.companyDetails.countryCode"
                {...register('user.companyDetails.countryCode', {
                  required: watch('user.isCompany')
                    ? t('registration.field-required')
                    : false,
                })}
                hasError={errors.hasOwnProperty(
                  'user.companyDetails.countryCode'
                )}
              />
              <ErrorMessage
                errors={errors}
                name="user.companyDetails.countryCode"
                render={({ message }) => <FormError message={message} />}
              />
            </FormGroup>
            <FormGroup type="checkbox" margin="0 0 24px 0">
              <FormGroup type="text" width="fit-content">
                <Label>{t('registration.street')}</Label>
                <Input
                  type="text"
                  width="11.875vw"
                  {...register('user.companyDetails.addressLine1', {
                    required: watch('user.isCompany')
                      ? t('registration.field-required')
                      : false,
                  })}
                  name="user.companyDetails.addressLine1"
                  hasError={errors.hasOwnProperty(
                    'user.companyDetails.addressLine1'
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="user.companyDetails.addressLine1"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
              <FormGroup type="text">
                <Label>{t('registration.home-number')}</Label>
                <Input
                  type="text"
                  width="11.875vw"
                  name="user.companyDetails.addressLine2"
                  {...register('user.companyDetails.addressLine2', {
                    required: watch('user.isCompany')
                      ? t('registration.field-required')
                      : false,
                  })}
                  hasError={errors.hasOwnProperty(
                    'user.companyDetails.addressLine2'
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="user.companyDetails.addressLine2"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
            </FormGroup>
            <FormGroup type="text">
              <FormGroup type="text" width="fit-content" margin="0 0 24px 0">
                <Label>{t('registration.city')}</Label>
                <Input
                  type="text"
                  width="11.875vw"
                  {...register('user.companyDetails.city', {
                    required: watch('user.isCompany')
                      ? t('registration.field-required')
                      : false,
                  })}
                  name="user.companyDetails.city"
                  hasError={errors.hasOwnProperty('user.companyDetails.city')}
                />
                <ErrorMessage
                  errors={errors}
                  name="user.companyDetails.city"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
              <FormGroup type="text" margin="0 0 24px 0">
                <Label>{t('registration.post-code')}</Label>
                <Input
                  type="text"
                  width="11.875vw"
                  name="user.companyDetails.postCode"
                  {...register('user.companyDetails.postCode', {
                    required: watch('user.isCompany')
                      ? t('registration.field-required')
                      : false,
                  })}
                  hasError={errors.hasOwnProperty(
                    'user.companyDetails.postCode'
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="user.companyDetails.postCode"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
            </FormGroup>
          </>
        )}

        <FormGroup type="text" margin="0 0 24px 0">
          <Label>{t('registration.password')}</Label>
          <Input
            type="password"
            width="23.75vw"
            name="user.password"
            {...register('user.password', {
              required: t('registration.field-required'),
            })}
            hasError={errors.hasOwnProperty('user.password')}
          />
          <ErrorMessage
            errors={errors}
            name="user.password"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        <PasswordRequirements
          watch={watch}
          name="user.password"
          email="email"
        />

        <FormGroup type="checkbox" margin="30px 0 0 0">
          <Input
            width="1vw"
            type="checkbox"
            name="user.privacyPolicyAgreement"
            {...register('user.privacyPolicyAgreement', {
              required: t('registration.field-required'),
            })}
          />
          <Label>{t('registration.privacy-policy-agreement')}</Label>

          <ErrorMessage
            errors={errors}
            name="user.privacyPolicyAgreement"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>
        <FormGroup type="checkbox" margin="20px 0 30px 0">
          <Input
            width="1vw"
            type="checkbox"
            name="user.notifications"
            {...register('user.notifications', {
              required: t('registration.field-required'),
            })}
          />
          <Label>{t('registration.notifications')}</Label>
          <ErrorMessage
            errors={errors}
            name="user.notifications"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            label={t('registration.next')}
            primary
            light
            onClick={(e) => {
              e.preventDefault()
              next([
                'user.firstName',
                'user.lastName',
                'user.email',
                'user.password',
                'user.privacyPolicyAgreement',
                'user.notifications',
                'user.companyDetails.companyName',
                'user.companyDetails.vatCode',
                'user.companyDetails.countryCode',
                'user.companyDetails.addressLine1',
                'user.companyDetails.addressLine2',
                'user.companyDetails.city',
                'user.companyDetails.postCode',
              ])
            }}
          />
        </div>
      </FormContent>
    </Form>
  )
}

export default AccountInformation
