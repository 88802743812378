import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.2vw;
  box-shadow: 0vw 0.01vw 1vw rgba(0, 0, 0, 0.07) !important;
  svg {
    height: 1em;
    width: 1em;
  }
`
export const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 10px;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  justify-content: space-between;
  background-color: ${(props) => props.bgColor === 'green' && '#26c35c'};
  background-color: ${(props) => props.bgColor === 'purple' && '#9245f6'};
  background-color: ${(props) => props.expired && 'red !important'};

  p {
    color: white !important;
    display: flex;
    gap: 5px;
    font-size: 14px;
    align-items: center;
    font-weight: 300;
  }
`
export const ListItem = styled.li`
  width: 100%;
  display: flex;
  height: fit-content;
  min-height: 300px;
  position: relative;
  border-bottom: 1px solid #dee2e6;
`

export const AuctionImage = styled.div`
  width: 20%;
  position: relative;
  background-image: ${(props) => props.src && `url(${props.src})`};
  background-size: cover;
  background-position: center;
`

export const Content = styled.div`
  width: ${(props) => (props.wFull ? '80%' : '100%')};
  height: 100%;
  padding: 1vw;
  display: flex;
  flex-direction: column;
`

export const GeneralInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
  gap: 1vw;
  margin-bottom: 1.5vw;
`
export const GeneralInfoTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
export const GeneralInfoTopCategory = styled.div`
  color: #3b82f6;
  background-color: rgba(59, 130, 246, 0.1);
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 6px;
`
export const GeneralInfoTopTooltips = styled.div`
  display: flex;
  width: fit-content;
  gap: 14px;
  align-items: center;
  color: #3d3d3d;
`
export const GeneralInfoTopTooltip = styled.div`
  font-size: 0.5em;

  display: flex;
  align-items: center;
  gap: 0.5em;
  position: relative;
`
export const GeneralInfoBottom = styled.div`
  color: #3d3d3d;
  font-size: 14px;
`

export const Details = styled.div`
  height: 40%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const DetailsRow = styled.div`
  font-size: 14px;
  gap: 20px;
  display: flex;
`

export const DetailsRowTitle = styled.p`
  color: #6c757d;
  width: 20%;
  font-weight: 400;
  font-size: 14px;
`
export const DetailsRowData = styled.p`
  color: #3d3d3d;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 1em;
`

export const Description = styled.div`
  color: white;
  display: flex;
  height: 30%;
  font-size: 1em;
  align-items: center;
  gap: 1.5vw;
  justify-content: space-between;
`
export const DescriptionLeft = styled.div`
  width: 70%;
  color: #3d3d3d;
  font-size: 0.8em;
  line-height: 1.2em;
`
export const DescriptionRight = styled.div`
  display: flex;
  gap: 3px;
`

export const PilotOffersList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const Tooltip = styled.div`
  background-color: #3d3d3d;
  position: absolute;
  top: 20px;
  left: 0;
  padding: 10px 20vw;
  border-radius: 0.2vw;
  width: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: white;
    width: fit-content;
  }
`
