import styled from 'styled-components'

export const Box = styled.div`
  color: white;
  line-hieght: 1vw;
  min-width: fit-ontent;
  text-align: center;
  font-size: 1vw;
  color: ${(props) => props.primary && 'white'};
  background-color: ${(props) => props.primary && '#00022A'};
  background-color: ${(props) => props.orange && '#FF771C'};
  color: ${(props) => props.outlined && '#FF771C'};
  background-color: ${(props) => props.outlined && 'white'};
  color: ${(props) => props.blue && '#3B82F6'};
  background-color: ${(props) => props.blue && 'rgba(59, 130, 246, 0.1)'};
  color: ${(props) => props.purple && '#9747FF'};
  background-color: ${(props) => props.purple && 'rgba(151, 71, 255, 0.1)'};
  color: ${(props) => props.green && '#26C35C'};
  background-color: ${(props) => props.green && 'rgba(38, 195, 92, 0.1)'};
  color: ${(props) => props.grey && '#6B7280'};
  background-color: ${(props) => props.grey && 'rgba(107, 114, 128, 0.1)'};
  padding: ${(props) => (props.primary ? '0.7vw' : '0.25vw 0.5vw')};
  padding: ${(props) => (props.orange || props.outlined ? '0.7vw 1vw' : '0.25vw 0.5vw')};
  border-radius: ${(props) => (props.primary ? '0.5vw' : '0.25vw')};
`
