import { Container, Content } from './AccountCreatedConfirmation.styled'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import { useNavigate } from 'react-router'
import { BsCheckCircle } from 'react-icons/bs'

const AccountCreatedConfirmation = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <Content>
        <BsCheckCircle />
        <Text large margin="1.5vw 0">
          Twoje konto zostało utworzone
        </Text>
        <Text small>
          Wysłaliśmy wiadomość z linkiem na Twój adres e-mail. Kliknij link aby
          potwierdzić konto.
        </Text>
        <Button
          primary
          light
          label="Strona główna"
          margin="2vw 0"
          onClick={(e) => {
            e.preventDefault()
            navigate('/')
          }}
        />
      </Content>
    </Container>
  )
}

export default AccountCreatedConfirmation
