import {
  Details,
  DetailsRow,
  DetailsBox,
  EditInformation,
  AttachmentList,
  AttachmentListItem,
  AttachmentDetails,
  Dropzone,
} from '../PilotAccount.styled'
import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Badge from '@components/ui/Badge/Badge'
import Separator from '@components/ui/Separator/Separator'
import Button from '@components/ui/Button/Button'
import { FiEdit2 } from 'react-icons/fi'
import { AiOutlineFile } from 'react-icons/ai'
import { useState, useCallback, useContext } from 'react'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import FormGroup from '@components/form/FormGroup/FormGroup'
import { useDropzone } from 'react-dropzone'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useGetLicenseCategories } from '@services/dictionaries.services'
import { useForm } from 'react-hook-form'
import { useUpdatePilot } from '@services/user.services'
import useToast from '@hooks/useToast'
import { useTranslation } from 'react-i18next'
import { formatDate } from '@utils/formatDate'
const PilotLicenses = ({ pilot }) => {
  const { showToast, ToastComponent } = useToast()
  const { t } = useTranslation()
  const [mode, setMode] = useState('view')

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        resolve(reader.result)
      }

      reader.onerror = (error) => {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    const base64files = [...files]
    for (const file of acceptedFiles) {
      const base64 = await convertFileToBase64(file)
      base64files.push({ name: file.name, data: base64 })
    }
    setFiles([...base64files])
    setValue('encodedFilesJson', [...base64files])
  })

  const handleRemoveFile = (name) => {
    const updatedFiles = files.filter((file) => file.name !== name)
    setFiles([...updatedFiles])
    setValue('encodedFilesJson', [...updatedFiles])
  }

  function downloadBase64File(base64Data, fileName) {
    const linkSource = base64Data
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const license = useGetLicenseCategories()
  const pilotLicenses =
    pilot?.encodedLicenseCategoriesJson &&
    JSON.parse(pilot?.encodedLicenseCategoriesJson)
  const attachments =
    pilot?.encodedFilesJson && JSON.parse(pilot?.encodedFilesJson)

  const [selectedLicenses, setSelectedLicenses] = useState([
    ...(pilotLicenses ?? []),
  ])
  const [files, setFiles] = useState([...(attachments ?? [])])

  const handleSelectLicenses = (value) => {
    if (selectedLicenses.includes(value)) {
      const updatedLicenses = selectedLicenses.filter(
        (option) => option !== value
      )
      setSelectedLicenses(updatedLicenses)
      setValue('encodedLicenseCategoriesJson', updatedLicenses)
    } else {
      setValue('encodedLicenseCategoriesJson', [...selectedLicenses, value])
      setSelectedLicenses([...selectedLicenses, value])
    }
  }

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: async () => ({
      encodedLicenseCategoriesJson: selectedLicenses,
      encodedFilesJson: files,
      pilotLicenseNumber: pilot?.pilotLicenseNumber,
      operatorLicenseNumber: pilot?.operatorLicenseNumber,
      licenseExpiryDate: new Date(pilot?.operatorLicenseExpiryDate),
      operatorLicenseExpiryDate: pilot?.operatorLicenseExpiryDate,
    }),
  })

  const updatePilot = useUpdatePilot(pilot?.user?.publicId)

  const onSubmit = (data) => {
    const convertedData = {
      ...data,
      encodedLicenseCategoriesJson: JSON.stringify(
        data.encodedLicenseCategoriesJson
      ),
      encodedFilesJson: JSON.stringify(data.encodedFilesJson),
    }
    updatePilot.mutate(convertedData, {
      onSuccess: () => {
        setMode('view')
        showToast('success', 'Success', 'Dane zostały zmienione!')
      },
    })
  }

  return (
    <Card width="100%" form padding="2vw">
      {ToastComponent}
      <Text large margin="0 0 2vw 1vw">
        {t('licenses.licenses')}
      </Text>
      {mode === 'view' && (
        <Details>
          <DetailsRow>
            <Text small>{t('licenses.license-number')}</Text>
            <Text secondary>{pilot?.pilotLicenseNumber}</Text>
          </DetailsRow>
          <DetailsRow>
            <Text small>{t('licenses.operator-license-number')}</Text>
            <Text secondary>{pilot?.operatorLicenseNumber}</Text>
          </DetailsRow>
          <DetailsRow>
            <Text small>{t('licenses.license-expiry-date')}</Text>
            <Text secondary>{formatDate(pilot?.licenseExpiryDate)}</Text>
          </DetailsRow>
          <DetailsRow>
            <Text small>{t('licenses.license-expiry-date')}</Text>
            <Text secondary>
              {pilot?.operatorLicenseExpiryDate &&
                formatDate(pilot?.operatorLicenseExpiryDate)}
            </Text>
          </DetailsRow>
          <DetailsRow>
            <Text small>{t('licenses.license-category')}</Text>
            <DetailsBox>
              {pilotLicenses?.map((el) => {
                const category = license?.data?.data?.filter(
                  (item) => item?.licenseCategory?.id === el
                )[0]?.name
                return <Badge primary>{category}</Badge>
              })}
            </DetailsBox>
          </DetailsRow>
          <DetailsRow>
            <Text small>{t('licenses.attachments')}</Text>

            <AttachmentList>
              {attachments &&
                attachments?.map((file) => (
                  <AttachmentListItem
                    onClick={() => downloadBase64File(file.data, file.name)}
                  >
                    <AiOutlineFile className="icon" />
                    <AttachmentDetails>
                      <p>{file?.name}</p>
                      <span>2MB</span>
                    </AttachmentDetails>
                  </AttachmentListItem>
                ))}
            </AttachmentList>
          </DetailsRow>
        </Details>
      )}
      {mode === 'edit' && (
        <Details onSubmit={handleSubmit(onSubmit)}>
          <DetailsRow>
            <Label small>{t('licenses.license-number')}</Label>
            <Input {...register('pilotLicenseNumber')} />
          </DetailsRow>
          <DetailsRow>
            <Label small>{t('licenses.operator-license-number')}</Label>
            <Input {...register('operatorLicenseNumber')} />
          </DetailsRow>
          <DetailsRow>
            <Label small>{t('licenses.license-expiry-date')}</Label>
            <Input {...register('licenseExpiryDate')} type="date" />
          </DetailsRow>
          <DetailsRow>
            <Label small>{t('licenses.operator-license-expiry-date')}</Label>
            <Input {...register('operatorLicenseExpiryDate')} type="date" />
          </DetailsRow>
          <DetailsRow>
            <DetailsBox>
              {license?.data?.data?.map((el) => (
                <Badge
                  style={{ cursor: 'pointer' }}
                  orange={selectedLicenses.includes(el.licenseCategory.id)}
                  outlined={!selectedLicenses.includes(el.licenseCategory.id)}
                  onClick={() => handleSelectLicenses(el.licenseCategory.id)}
                >
                  {el.name}
                </Badge>
              ))}
            </DetailsBox>

            <Text small>{t('licenses.upload')}</Text>
            <Text small italic>
              {t('licenses.files-desc')}
            </Text>
            <Dropzone {...getRootProps()}>
              <input {...getInputProps()} />
              <Text medium secondary>
                {t('licenses.drop-files')}
              </Text>
              <Button
                label={t('licenses.upload')}
                primary
                light
                type="button"
              />
            </Dropzone>
          </DetailsRow>
          <AttachmentList
            edit
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            {files?.map((el) => (
              <div>
                <AttachmentListItem
                  onClick={() => downloadBase64File(el.data, el.name)}
                >
                  <AiOutlineFile className="icon" />
                  <AttachmentDetails>
                    <p>{el.name}</p>
                    <span>2MB</span>
                  </AttachmentDetails>
                </AttachmentListItem>
                <Button
                  dark
                  outlined
                  onClick={() => handleRemoveFile(el.name)}
                  label={
                    <p>
                      <RiDeleteBin6Line /> {t('licenses.delete')}
                    </p>
                  }
                  margin="1vw 0 0 0"
                  width="fit-content"
                />
              </div>
            ))}
          </AttachmentList>
          <Text small margin="8px 0 8px 0">
            {t('licenses.agreements')}
          </Text>
          <FormGroup type="checkbox">
            <Input type="checkbox" />
            <Label> {t('licenses.agreement-1')}</Label>
          </FormGroup>
          <EditInformation>
            {mode === 'edit' && (
              <Button
                label={
                  updatePilot.isLoading ? (
                    <p>
                      {t('licenses.loading')}
                      <i
                        className="pi pi-spin pi-spinner"
                        style={{ fontSize: '14px' }}
                      ></i>{' '}
                    </p>
                  ) : (
                    t('licenses.save')
                  )
                }
                light
                primary
                type="submit"
              />
            )}
            {mode === 'edit' && (
              <Button
                label={t('licenses.cancel')}
                light
                outlined
                blue
                onClick={() => setMode('view')}
              />
            )}
          </EditInformation>
        </Details>
      )}
      {mode === 'view' && <Separator />}
      <EditInformation>
        {mode === 'view' && (
          <Button
            label={
              <p>
                <FiEdit2 /> {t('licenses.edit-data')}
              </p>
            }
            blue
            outlined
            onClick={(e) => {
              e.preventDefault()
              setMode((mode) => 'edit')
            }}
          />
        )}
      </EditInformation>
    </Card>
  )
}

export default PilotLicenses
