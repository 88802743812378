import { useContext, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import FormError from '@components/form/FormError/FormError'
import { Container, Box } from '../CustomerAuction/CustomerAuction.styled'
import TrackingBar from '@components/layout/TrackingBar/TrackingBar'
import {
  RadioContainer,
  RadioButton,
  RadioButtonLabel,
} from '../../../components/ui/OfferInfo/OfferInfo.styled'
import { Tooltip } from 'primereact/tooltip'

import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useGetServices } from '@services/dictionaries.services'
import { AuthContext } from '@context/AuthContext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'

import {
  useDeleteAuction,
  useGetAuctionQuery,
  useUpdateAuction,
} from '@services/auctions.services'
import { Grid } from '@pages/pilot/PilotAuctionList/PilotAuctionList.styled'
import useToast from '@hooks/useToast'
import { EditInformation } from '../CustomerAccount/CustomerAccount.styled'
import { useTranslation } from 'react-i18next'

const EditOffer = ({ defaultValues, offer, id, token, locations }) => {
  const [updatedLocations, setUpdatedLocations] = useState({
    regions: [...(locations?.regions ?? [])],
    countries: [...(locations?.countries ?? [])],
    state: [...(locations?.states ?? [])],
    citiesByCountry: [...(locations?.citiesByCountry ?? [])],
    citiesByState: [...(locations?.citiesByState ?? [])],
  })

  const navigate = useNavigate()
  const updateAuction = useUpdateAuction(id, token)
  const deleteAuction = useDeleteAuction(id, token)
  const {
    register,
    watch,
    control,
    setValue,
    handleSubmit,

    formState: { errors, isDirty },
  } = useForm({
    defaultValues: { ...offer, ...defaultValues },
  })

  const [active, setActive] = useState(offer?.oneDay ? 'oneDay' : 'fewDays')
  const { data: services } = useGetServices()
  const [category, setCategory] = useState(
    services?.data?.filter((el) => el.service.id === offer?.serviceId)[0]
  )
  const { showToast, ToastComponent } = useToast()
  const { t } = useTranslation()

  const onSubmit = (data) => {
    const newData = {
      ...offer,
      ...data,
    }
    delete newData?.service
    if (data?.oneDay) {
      delete newData?.dateTo
    }

    updateAuction.mutate(newData, {
      onSuccess: () => {
        showToast(
          'success',
          'Success',
          t('add-auction.project-info.offer-updated')
        )
        setTimeout(() => {
          navigate('/customer/auctions')
        }, 2000)
      },
    })
  }

  return (
    <Container>
      {ToastComponent}
      <TrackingBar
        locations={[
          { name: 'DroneConnect', path: '/' },
          { name: 'my-auctions', path: '/customer/auctions' },
          { name: 'edit' },
        ]}
      />
      <Grid style={{ marginTop: 50, marginBottom: 50 }}>
        <div></div>
        <Card width="58vw" form medium>
          <Text margin="8px 0 26px 0" medium>
            {t('add-auction.project-info.edit-auction')}
          </Text>
          {defaultValues && (
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <RadioContainer>
                <RadioButton
                  active={active === 'oneDay'}
                  value={true}
                  onClick={() => setActive((active) => 'oneDay')}
                  disabled
                >
                  <RadioButtonLabel>
                    {' '}
                    {t('add-auction.project-info.one-day')}
                  </RadioButtonLabel>
                </RadioButton>
                <RadioButton
                  active={active === 'fewDays'}
                  value={false}
                  disabled
                  onClick={() => setActive((active) => 'fewDays')}
                >
                  <RadioButtonLabel>
                    {t('add-auction.project-info.few-days')}
                  </RadioButtonLabel>
                </RadioButton>
              </RadioContainer>

              {active === 'oneDay' ? (
                <FormGroup type="text">
                  <Label>{t('add-auction.project-info.service-date')}</Label>
                  <Controller
                    name="dateFrom"
                    control={control}
                    disabled
                    rules={{ required: 'To pole jest wymagane' }}
                    render={({ field }) => (
                      <Calendar
                        {...field}
                        disabled
                        onChange={(e) => field.onChange(e.value)}
                        showIcon
                        value={new Date(offer?.dateFrom)}
                        minDate={new Date()}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="dateFrom"
                    render={({ message }) => <FormError message={message} />}
                  />
                </FormGroup>
              ) : (
                <FormGroup type="checkbox">
                  <FormGroup
                    type="text"
                    width="fit-content"
                    margin="0 0.625vw 0px 0"
                  >
                    <Label>{t('add-acution.project-info.service-date')}</Label>
                    <Controller
                      name="dateFrom"
                      control={control}
                      disabled
                      rules={{ required: 'To pole jest wymagane' }}
                      render={({ field }) => (
                        <Calendar
                          {...field}
                          onChange={(e) => field.onChange(e.value)}
                          showIcon
                          disabled
                          value={[
                            new Date(offer?.dateFrom),
                            new Date(offer?.dateTo),
                          ]}
                          defaultValue={new Date(offer?.dateFrom)}
                          minDate={new Date()}
                          selectionMode="range"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="dateFrom"
                      render={({ message }) => <FormError message={message} />}
                    />
                  </FormGroup>
                </FormGroup>
              )}

              <FormGroup type="text">
                <Label> {t('add-auction.project-info.category')}</Label>
                <Dropdown
                  disabled
                  options={services?.data}
                  optionLabel="name"
                  placeholder={t('add-auction.project-info.pick-category')}
                  className="w-full md:w-14rem"
                  filter
                  value={category}
                />
                <ErrorMessage
                  errors={errors}
                  name="serviceId"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
              <FormGroup type="text">
                <Label>{t('add-auction.project-info.location')}</Label>
                <div
                  style={{
                    display: 'flex',
                    gap: 5,
                    flexDirection: 'column',
                    marginTop: 5,
                  }}
                >
                  <Controller
                    name="locationRegion"
                    control={control}
                    disabled
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        disabled
                        options={locations?.regions ?? []}
                        optionLabel="name"
                        filter
                        placeholder={t('add-auction.project-info.pick-region')}
                        className="w-full md:w-14rem"
                      />
                    )}
                  />

                  <Controller
                    name="locationCountry"
                    control={control}
                    defaultValue={0}
                    disabled
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        options={locations?.countries ?? []}
                        optionLabel="name"
                        filter
                        disabled
                        placeholder={t('add-auction.project-info.pick-country')}
                        className="w-full md:w-14rem"
                      />
                    )}
                  />
                  <Controller
                    name="locationState"
                    control={control}
                    disabled
                    defaultValue={0}
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        options={locations?.states ?? []}
                        optionLabel="name"
                        filter
                        disabled
                        placeholder={t('add-acution.project-info.pick-state')}
                        className="w-full md:w-14rem"
                      />
                    )}
                  />

                  {watch('locationState') ? (
                    <Controller
                      name="locationCity"
                      control={control}
                      disabled
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          options={locations?.citiesByState ?? []}
                          optionLabel="name"
                          filter
                          disabled
                          placeholder={t('add-auction.project-info.pick-city')}
                          className="w-full md:w-14rem"
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      name="locationCity"
                      control={control}
                      disabled
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          options={locations?.citiesByCountry ?? []}
                          optionLabel="name"
                          filter
                          disabled
                          placeholder={t('add-auction.project-info.pick-city')}
                          className="w-full md:w-14rem"
                        />
                      )}
                    />
                  )}
                </div>
              </FormGroup>
              <FormGroup type="text">
                <Label>{t('add-auction.project-info.language')}</Label>
                <Input
                  type="text"
                  width="23.75vw"
                  name="language"
                  disabled
                  {...register('language', {
                    required: 'To pole jest wymagane ',
                  })}
                  hasError={errors.hasOwnProperty('language')}
                />
                <ErrorMessage
                  errors={errors}
                  name="language"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
              <FormGroup type="text">
                <Label
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10,
                  }}
                >
                  <Tooltip target=".description">
                    <div style={{ fontSize: 12, width: 300 }}>
                      {t('add-auction.project-info.tooltip-1')}
                      <br />
                      <br />
                      {t('add-auction.project-info.tooltip-2')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-3')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-4')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-5')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-6')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-7')}
                      <br /> <br />
                      {t('add-auction.project-info.tooltip-8')}
                      <br />
                      {t('add-auction.project-info.tooltip-9')}
                    </div>
                  </Tooltip>
                  <span style={{ display: 'inline' }}>
                    {' '}
                    {t('add-auction.project-info.description')}
                  </span>
                  <i
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{
                      display: 'inline',
                      marginLeft: 10,
                      cursor: 'pointer',
                    }}
                    className="pi pi-info-circle description"
                  />
                </Label>

                <textarea
                  width="23.75vw"
                  name="description"
                  {...register('description', {
                    required: 'To pole jest wymagane ',
                  })}
                  hasError={errors.hasOwnProperty('description')}
                />
                <ErrorMessage
                  errors={errors}
                  name="description"
                  render={({ message }) => <FormError message={message} />}
                />
              </FormGroup>
              <EditInformation style={{ width: '100%' }}>
                <div style={{ display: 'flex', gap: 16 }}>
                  <Button
                    label={
                      updateAuction.isLoading ? (
                        <p>
                          {t('add-auction.project-info.loading')}
                          <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: '14px' }}
                          ></i>{' '}
                        </p>
                      ) : (
                        t('add-auction.project-info.update')
                      )
                    }
                    primary
                    light
                    type="submit"
                  />
                  <Button
                    label="Wstecz"
                    outlined
                    blue
                    light
                    onClick={() => navigate('/customer/auctions')}
                  />
                </div>
                <Button
                  label={
                    deleteAuction.isLoading ? (
                      <p>
                        {t('add-auction.project-info.deleting')}{' '}
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: '14px' }}
                        ></i>{' '}
                      </p>
                    ) : (
                      t('add-auction.project-info.delete-auction')
                    )
                  }
                  style={{ background: '#E32224', color: 'white' }}
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault()
                    deleteAuction.mutate(null, {
                      onSuccess: () => {
                        showToast(
                          'success',
                          'Success',
                          t('add-auction.project-info.delete-toast-auction')
                        )
                        setTimeout(() => {
                          navigate('/customer/auctions')
                        }, 2000)
                      },
                    })
                  }}
                />
              </EditInformation>
            </form>
          )}
        </Card>
        <div></div>
      </Grid>
    </Container>
  )
}

export default EditOffer
