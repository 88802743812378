import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getBiddings, getBidding, getAuctionBiddings, bidAuction, acceptBid } from '../api/biddings'

export const useGetPilotsBiddings = (token) => {
  return useQuery({
    queryKey: ['biddings'],
    queryFn: async () => await getBiddings(token),
  })
}

export const useGetBidding = (token, id) => {
  return useQuery({
    queryKey: ['bidding', id],
    queryFn: async () => await getBidding(token, id),
  })
}

export const useGetAuctionBidding = (id) => {
  return useQuery({
    queryKey: ['auction_bidding', id],
    queryFn: async () => await getAuctionBiddings(id),
  })
}

export const useBidAuction = (id, token) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['auction_bidding'],
    mutationFn: async (data) => await bidAuction(id, token, data),
    onSuccess: () => queryClient.invalidateQueries(['auction_bidding']),
  })
}

export const useAcceptBid = (projectId, biddingId, token) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['auction_bidding', 'auction', 'auction_bidding', projectId, biddingId, 'auction', 'auctions', 'biddings'],
    mutationFn: async () => await acceptBid(projectId, biddingId, token),
    onSuccess: () => queryClient.invalidateQueries(['auction_bidding', projectId, biddingId, 'auction', 'auctions', 'biddings']),
  })
}
