import { useRef } from 'react'
import { Toast } from 'primereact/toast'

const useToast = () => {
  const toastRef = useRef(null)

  const showToast = (severity, summary, detail) => {
    toastRef.current.show({ severity, summary, detail })
  }

  return { showToast, ToastComponent: <Toast ref={toastRef} /> }
}

export default useToast
