import Text from '@components/ui/Text/Text'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Label from '@components/form/Label/Label'
import Input from '@components/form/Input/Input'
import Button from '@components/ui/Button/Button'
import { useEffect, useState } from 'react'
import { Form, FormContent } from '../RegisterPilot.styled'
import { useFormContext, Controller } from 'react-hook-form'
import { useGetServices } from '@services/dictionaries.services'
import { MultiSelect } from 'primereact/multiselect'
import { Dropdown } from 'primereact/dropdown'
import {
  useGetCitiesByCountry,
  useGetCitiesByState,
  useGetCountries,
  useGetRegions,
  useGetState,
} from '@services/locations.services'
import { useTranslation } from 'react-i18next'

const Preferences = ({ next, isLoading, isError, setCurrentPage }) => {
  const [selectedServices, setSelectedServices] = useState(null)
  const {
    register,
    watch,
    setValue,
    control,

    formState: { errors },
  } = useFormContext()
  const { data: services } = useGetServices()

  const { data: regions } = useGetRegions()
  const { data: countries } = useGetCountries(
    watch('pilot.preferredLocationRegion')?.name
  )
  const { data: states } = useGetState(
    watch('pilot.preferredLocationCountry')?.id
  )
  const { data: citiesByCountry } = useGetCitiesByCountry(
    watch('pilot.preferredLocationCountry')?.id
  )

  const { data: citiesByState } = useGetCitiesByState(
    watch('pilot.preferredLocationState')?.id
  )

  useEffect(() => {
    const formatCategories = selectedServices?.map((item) => {
      return { serviceId: item.service?.id }
    })
    setValue('pilot.pilotServices', formatCategories)
  }, [selectedServices])

  const { t } = useTranslation()
  return (
    <Form>
      <Text>{t('services.pilot-register')}</Text>
      <FormContent>
        <FormGroup type="text">
          <Label>{t('services.category')}</Label>

          <MultiSelect
            value={selectedServices}
            onChange={(e) => setSelectedServices(e.value)}
            options={services?.data ?? []}
            optionLabel="name"
            placeholder={t('services.pick-category')}
            className="w-full md:w-20rem"
          />
        </FormGroup>

        <FormGroup type="text">
          <Label>{t('locations.title')}</Label>
          <div
            style={{
              display: 'flex',
              gap: 5,
              flexDirection: 'column',
              marginTop: 5,
            }}
          >
            <Controller
              name="pilot.preferredLocationRegion"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={regions ?? []}
                  optionLabel="name"
                  filter
                  placeholder={t('locations.pick-region')}
                  className="w-full md:w-14rem"
                />
              )}
            />

            <Controller
              name="pilot.preferredLocationCountry"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={countries ?? []}
                  optionLabel="name"
                  filter
                  placeholder={t('locations.pick-country')}
                  className="w-full md:w-14rem"
                />
              )}
            />
            <Controller
              name="pilot.preferredLocationState"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={states ?? []}
                  optionLabel="name"
                  filter
                  placeholder={t('locations.pick-state')}
                  className="w-full md:w-14rem"
                />
              )}
            />

            {watch('pilot.preferredLocationState') ? (
              <Controller
                name="pilot.preferredLocationCity"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={citiesByState ?? []}
                    optionLabel="name"
                    filter
                    placeholder={t('locations.pick-city')}
                    className="w-full md:w-14rem"
                  />
                )}
              />
            ) : (
              <Controller
                name="pilot.preferredLocationCity"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={citiesByCountry ?? []}
                    optionLabel="name"
                    filter
                    placeholder={t('locations.pick-city')}
                    className="w-full md:w-14rem"
                  />
                )}
              />
            )}
          </div>
        </FormGroup>
        <FormGroup type="text">
          <Label>{t('services.general-notes')}</Label>
          <Text small secondary italic></Text>
          <Input width="36vw" {...register('pilot.generalAgreementNote')} />
        </FormGroup>
        <Text small secondary margin="0 0 1vw 0">
          {t('services.payments')}
        </Text>

        <FormGroup type="checkbox" margin="0">
          <Input
            type="checkbox"
            name="pilot.postPaymentAcceptance"
            {...register('pilot.postPaymentAcceptance')}
          />
          <Label>{t('services.post')}</Label>
        </FormGroup>
        <FormGroup type="checkbox" margin="0">
          <Input
            type="checkbox"
            name="pilot.prePaymentAcceptance"
            {...register('pilot.prePaymentAcceptance')}
          />
          <Label>{t('services.pre')}</Label>
        </FormGroup>
        <FormGroup type="checkbox" margin="0">
          <Input
            type="checkbox"
            name="pilot.depositPaymentAcceptance"
            {...register('pilot.depositPaymentAcceptance')}
          />
          <Label>{t('services.deposit')}</Label>
        </FormGroup>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '24px',
          }}
        >
          <Button
            label={t('services.prev')}
            outlined
            blue
            onClick={(e) => {
              e.preventDefault()
              setCurrentPage((prev) => prev - 1)
            }}
          />
          {!isLoading && (
            <Button
              primary
              light
              label={
                isLoading ? (
                  <p>
                    {t('services.loading')}
                    <i
                      className="pi pi-spin pi-spinner"
                      style={{ fontSize: '14px' }}
                    ></i>{' '}
                  </p>
                ) : (
                  t('services.register')
                )
              }
              type="submit"
            />
          )}
        </div>
      </FormContent>
    </Form>
  )
}

export default Preferences
