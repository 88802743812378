import { Group } from './FormGroup.styled'

const FormGroup = ({ children, type, margin, width }) => {
  return (
    <Group type={type} margin={margin} width={width}>
      {children}
    </Group>
  )
}

export default FormGroup