import { useContext, useEffect, useState } from 'react'
import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Button from '@components/ui/Button/Button'
import FormGroup from '@components/form/FormGroup/FormGroup'
import { Tooltip } from 'primereact/tooltip'

import Input from '@components/form/Input/Input'
import Label from '@components/form/Label/Label'
import FormError from '@components/form/FormError/FormError'
import {
  RadioContainer,
  RadioButton,
  RadioButtonLabel,
} from './OfferInfo.styled'
import { useFormContext, Controller } from 'react-hook-form'
import { motion } from 'framer-motion'
import { ErrorMessage } from '@hookform/error-message'
import { useGetServices } from '@services/dictionaries.services'
import { AuthContext } from '@context/AuthContext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import {
  useGetCitiesByCountry,
  useGetCitiesByState,
  useGetCountries,
  useGetRegions,
  useGetState,
} from '@services/locations.services'
import { useTranslation } from 'react-i18next'
import { currencies } from '@utils/currencies'

const OfferInfo = ({ next, active, setActive, isLoading }) => {
  const { t } = useTranslation()
  const [currency, setCurrency] = useState(null)
  const [category, setCategory] = useState(null)
  const {
    user: { userId },
  } = useContext(AuthContext)
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext()
  const { data: regions } = useGetRegions()
  const { data: countries } = useGetCountries(watch('locationRegion')?.name)
  const { data: states } = useGetState(watch('locationCountry')?.id)
  const { data: citiesByCountry } = useGetCitiesByCountry(
    watch('locationCountry')?.id
  )

  const { data: citiesByState } = useGetCitiesByState(
    watch('locationState')?.id
  )
  let today = new Date()
  let month = today.getMonth()
  let year = today.getFullYear()
  let prevMonth = month === 0 ? 11 : month - 1
  let prevYear = prevMonth === 11 ? year - 1 : year
  let nextMonth = month === 11 ? 0 : month + 1
  let nextYear = nextMonth === 0 ? year + 1 : year

  let minDate = new Date()

  minDate.setMonth(month)
  minDate.setFullYear(prevYear)
  let maxDate = new Date()

  maxDate.setDate(today.getDate() + 14)

  useEffect(() => {
    setValue('currency', currency)
  }, [currency])

  const validateDate = (value) => {
    if (value < today) {
      return 'Selected date cannot be in the past.'
    }
    return true
  }

  const { data: services } = useGetServices()
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%' }}
    >
      <Card width="58vw" form medium>
        <Text margin="14px 0 18px 0" large>
          {t('add-auction.project-info.title')}
        </Text>
        <RadioContainer>
          <RadioButton
            active={active === 'oneDay'}
            value={true}
            onClick={() => setActive((active) => 'oneDay')}
          >
            <RadioButtonLabel>
              {t('add-auction.project-info.one-day')}
            </RadioButtonLabel>
          </RadioButton>
          <RadioButton
            active={active === 'fewDays'}
            value={false}
            onClick={() => setActive((active) => 'fewDays')}
          >
            <RadioButtonLabel>
              {t('add-auction.project-info.few-days')}
            </RadioButtonLabel>
          </RadioButton>
        </RadioContainer>

        {active === 'oneDay' ? (
          <FormGroup type="text">
            <Label>{t('add-auction.project-info.service-date')}</Label>
            <Controller
              name="dateFrom"
              control={control}
              rules={{
                required: t('add-auction.project-info.field-required'),
                validate: validateDate,
              }}
              render={({ field }) => (
                <Calendar
                  {...field}
                  onChange={(e) => field.onChange(e.value)}
                  showIcon
                  minDate={minDate}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="dateFrom"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>
        ) : (
          <FormGroup type="checkbox">
            <FormGroup type="text" width="fit-content" margin="0 0.625vw 0px 0">
              <Label>{t('add-auction.project-info.service-date')}</Label>
              <Controller
                name="dateFrom"
                control={control}
                rules={{
                  required: t('add-auction.project-info.field-required'),
                  validate: validateDate,
                }}
                render={({ field }) => (
                  <Calendar
                    {...field}
                    onChange={(e) => field.onChange(e.value)}
                    showIcon
                    selectionMode="range"
                    minDate={minDate}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="dateFrom"
                render={({ message }) => <FormError message={message} />}
              />
            </FormGroup>
          </FormGroup>
        )}

        <FormGroup type="text">
          <Label>{t('add-auction.project-info.category')}</Label>
          <Dropdown
            onChange={(e) => {
              setCategory(e.value)
              setValue('serviceId', e.value.service.id)
            }}
            options={services?.data}
            optionLabel="name"
            placeholder={t('add-auction.project-info.pick-category')}
            className="w-full md:w-14rem"
            filter
            value={category}
          />
          <ErrorMessage
            errors={errors}
            name="serviceId"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>
        <FormGroup type="text">
          <Label>{t('add-auction.project-info.location')}</Label>
          <FormGroup>
            <Controller
              name="locationRegion"
              control={control}
              rules={{
                required: t('add-auction.project-info.field-required'),
              }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={regions ?? []}
                  optionLabel="name"
                  placeholder={t('add-auction.project-info.pick-region')}
                  className="w-full md:w-14rem"
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="locationRegion"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="locationCountry"
              control={control}
              rules={{
                required: t('add-auction.project-info.field-required'),
              }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={countries ?? []}
                  optionLabel="name"
                  filter
                  placeholder={t('add-auction.project-info.pick-country')}
                  className="w-full md:w-14rem"
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="locationCountry"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="locationState"
              control={control}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={states ?? []}
                  optionLabel="name"
                  filter
                  placeholder={t('add-auction.project-info.pick-state')}
                  className="w-full md:w-14rem"
                />
              )}
            />

            <ErrorMessage
              errors={errors}
              name="locationState"
              render={({ message }) => <FormError message={message} />}
            />
          </FormGroup>
          {watch('locationState') ? (
            <FormGroup>
              <Controller
                name="locationCity"
                control={control}
                rules={{
                  required: t('add-auction.project-info.field-required'),
                }}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={citiesByState ?? []}
                    optionLabel="name"
                    filter
                    placeholder={t('add-auction.project-info.pick-city')}
                    className="w-full md:w-14rem"
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="locationCity"
                render={({ message }) => <FormError message={message} />}
              />
            </FormGroup>
          ) : (
            <FormGroup>
              <Controller
                name="locationCity"
                control={control}
                rules={{
                  required: t('add-auction.project-info.field-required'),
                }}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={citiesByCountry ?? []}
                    optionLabel="name"
                    filter
                    placeholder={t('add-auction.project-info.pick-city')}
                    className="w-full md:w-14rem"
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="locationCity"
                render={({ message }) => <FormError message={message} />}
              />
            </FormGroup>
          )}
        </FormGroup>
        <FormGroup type="text">
          <Label>{t('add-auction.project-info.language')}</Label>
          <Input
            type="text"
            width="23.75vw"
            name="language"
            {...register('language', {
              required: t('add-auction.project-info.field-required'),
            })}
            hasError={errors.hasOwnProperty('language')}
          />
          <ErrorMessage
            errors={errors}
            name="language"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>
        <FormGroup type="text" width="fit-content" margin="0 10px 10px 0">
          <Label>{t('add-auction.auction-info.currency')}</Label>

          <Dropdown
            value={currency}
            onChange={(e) => setCurrency(e.value.code)}
            options={currencies}
            optionLabel="code"
            placeholder={
              currency ?? t('add-auction.auction-info.pick-currency')
            }
            className="w-full md:w-14rem"
            filter
          />
        </FormGroup>
        <FormGroup type="text">
          <Label
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            <Tooltip target=".description">
              <div style={{ fontSize: 12, width: 300 }}>
                {t('add-auction.project-info.tooltip-1')}
                <br />
                <br />
                {t('add-auction.project-info.tooltip-2')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-3')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-4')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-5')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-6')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-7')}
                <br /> <br />
                {t('add-auction.project-info.tooltip-8')}
                <br />
                {t('add-auction.project-info.tooltip-9')}
              </div>
            </Tooltip>
            <span style={{ display: 'inline' }}>
              {t('add-auction.project-info.description')}
            </span>
            <i
              data-pr-position="right"
              data-pr-at="right+5 top"
              data-pr-my="left center-2"
              style={{ display: 'inline', marginLeft: 10, cursor: 'pointer' }}
              className="pi pi-info-circle description"
            />
          </Label>

          <textarea
            width="23.75vw"
            name="description"
            {...register('description', {
              required: t('add-auction.project-info.field-required'),
            })}
            hasError={errors.hasOwnProperty('description')}
          />
          <ErrorMessage
            errors={errors}
            name="description"
            render={({ message }) => <FormError message={message} />}
          />
        </FormGroup>

        {userId && !isLoading && (
          <Button
            label={t('add-auction.project-info.send')}
            primary
            light
            type="submit"
          />
        )}
        {userId && isLoading && (
          <Button
            label={
              <p>
                {t('add-auction.project-info.loading')}
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: '14px' }}
                ></i>
              </p>
            }
            primary
            light
          />
        )}
        {!userId && !isLoading && (
          <Button
            label={t('add-auction.project-info.next')}
            primary
            light
            onClick={(e) => {
              e.preventDefault()
              if (active === 'oneDay') {
                next([
                  'dateFrom',
                  'serviceId',
                  'description',
                  'locationRegion',
                  'locationCity',
                  'locationCountry',
                  'language',
                ])
              } else {
                next([
                  'dateFrom',
                  'dateTo',
                  'serviceId',
                  'description',
                  'locationRegion',
                  'locationCity',
                  'locationCountry',
                  'language',
                ])
              }
            }}
          />
        )}
      </Card>
    </motion.div>
  )
}

export default OfferInfo
