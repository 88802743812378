import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  padding: 0px 72px;

  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 20% 60% 20%;
  gap: 42px;
  @media only screen and (max-width: 600px) {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
  }
`

export const Form = styled.form`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
`

export const FormContent = styled.div`
  border-radius: 8px;
  padding: 24px 32px;
  height: fit-content;
  background-color: #f5f7f9;
`
