import { Container, Subtitle, Copy } from './Description.styled'
import Card from '@components/ui/Card/Card'
import Headline from '@components/ui/Headline/Headline'
import { useTranslation } from 'react-i18next'
import { AiOutlineIdcard } from 'react-icons/ai'
import { FiShoppingCart } from 'react-icons/fi'
import { RiSearchLine } from 'react-icons/ri'

const Description = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Card height="21.8vw" width="21.8vw" center>
        <Subtitle>{t('home.description.step-1')}</Subtitle>
        <AiOutlineIdcard />
        <Headline secondary medium margin="2.25vw 0px 0.5vw 0">
          {t('home.description.step-1-title')}
        </Headline>
        <Copy>{t('home.description.step-1-description')}</Copy>
      </Card>
      <Card height="21.8vw" width="21.8vw" center>
        <Subtitle>{t('home.description.step-2')}</Subtitle>
        <RiSearchLine />
        <Headline secondary medium margin="2.25vw 0px 0.5vw 0">
          {t('home.description.step-2-title')}
        </Headline>
        <Copy>{t('home.description.step-2-description')}</Copy>
      </Card>
      <Card height="21.8vw" width="21.8vw" center>
        <Subtitle>{t('home.description.step-3')}</Subtitle>
        <FiShoppingCart />
        <Headline secondary medium margin="2.25vw 0px 0.5vw 0">
          {t('home.description.step-3-title')}
        </Headline>
        <Copy>{t('home.description.step-3-description')}</Copy>
      </Card>
    </Container>
  )
}

export default Description
