import styled from 'styled-components'

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;

  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  justify-content: ${(props) => props.row && 'space-between'};
  margin-bottom: ${(props) => props.row && '3vw'};
  gap: 24px;
  width: 100%;
  padding-top: 24px;

`
