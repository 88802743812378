import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import AuthContextProvider from './context/AuthContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'primereact/resources/primereact.css'
import 'primereact/resources/themes/lara-light-purple/theme.css'
import { I18nextProvider } from 'react-i18next'

import i18next from './i18n'

import App from './App'
import './index.css'
const queryClient = new QueryClient({})
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Suspense fallback={<p>Loading</p>}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthContextProvider>
            <I18nextProvider i18n={i18next}>
              <App />
            </I18nextProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  </Suspense>
)
