import styled from 'styled-components'

export const RadioContainer = styled.div`
  width: 300px;
  display: flex;
  border-radius: 6px;
  margin-bottom: 16px;
`

export const RadioButton = styled.div`
  padding: 8px 16px;
  border: 1px solid #ff771c;
  color: ${(props) => (props.active ? 'white' : '#3D3D3D')};
  background-color: ${(props) => (props.active ? '#FF771C' : 'transparent')};
  cursor: pointer;
  &:nth-child(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
  }
  &:nth-child(2) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

export const RadioButtonLabel = styled.p`
  font-size: 12px;
`
