import styled from 'styled-components'
import avatar from '../../../assets/avatar.jpg'

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw;
  border-top: 1px solid #dee2e6;
  &:first-child {
    border-top: 0px solid transparent;
  }
`

export const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
`
