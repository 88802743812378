import { EditInformation, Form } from '../PilotAccount.styled'
import Card from '@components/ui/Card/Card'
import Text from '@components/ui/Text/Text'
import Separator from '@components/ui/Separator/Separator'
import Button from '@components/ui/Button/Button'
import Label from '@components/form/Label/Label'
import FormGroup from '@components/form/FormGroup/FormGroup'
import Input from '@components/form/Input/Input'
import { useEffect, useState } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { useForm } from 'react-hook-form'
import PasswordRequirements from '@components/ui/PasswordRequirements/PasswordRequirements'
import { useChangePassword } from '@services/auth.services'
import useToast from '@hooks/useToast'
import { useTranslation } from 'react-i18next'

const ChangePassword = ({ pilot }) => {
  const { showToast, ToastComponent } = useToast()
  const { t } = useTranslation()

  const { handleSubmit, register, watch } = useForm({})
  const [mode, setMode] = useState('view')

  const changePassword = useChangePassword(pilot?.user?.publicId)

  const onSubmit = (data) => {
    if (data.password === data.currentPassword) {
      showToast('error', 'Error', t('change-password.error-1'))
      return
    }

    if (data.password !== data.passwordConfirm) {
      showToast('error', 'Error', t('cahnge-password.error-2'))
      return
    }
    const newPassword = {
      password: data.passwordConfirm,
    }
    changePassword.mutate(newPassword, {
      onSuccess: () => {
        setMode('view')
        showToast('success', 'Success', t('change-password.success'))
      },
    })
  }

  return (
    <Card width="100%" form padding="24px">
      {ToastComponent}
      <Text large margin="0 0 8px 0">
        {t('change-password.title')}
      </Text>
      <Separator />
      {mode === 'edit' && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup type="text">
            <Label>{t('change-password.current-password')}</Label>
            <Input
              width="25vw"
              {...register('currentPassword')}
              type="password"
            />
          </FormGroup>
          <FormGroup type="text">
            <Label> {t('change-password.new-password')}</Label>
            <Input width="25vw" {...register('password')} type="password" />
          </FormGroup>
          <PasswordRequirements
            watch={watch}
            name="password"
            email={pilot.email}
          />
          <FormGroup type="text">
            <Label small> {t('change-password.confirm-new-password')}</Label>
            <Input
              width="25vw"
              {...register('passwordConfirm')}
              type="password"
            />
          </FormGroup>

          <Separator />
          <EditInformation>
            {mode === 'edit' && (
              <Button
                label={
                  changePassword.isLoading ? (
                    <p>
                      {t('change-password.loading')}
                      <i
                        className="pi pi-spin pi-spinner"
                        style={{ fontSize: '14px' }}
                      ></i>{' '}
                    </p>
                  ) : (
                    t('change-password.save')
                  )
                }
                light
                primary
                type="submit"
              />
            )}
            {mode === 'edit' && (
              <Button
                label={t('change-password.cancel')}
                light
                outlined
                blue
                onClick={() => setMode('view')}
              />
            )}
          </EditInformation>
        </Form>
      )}
      {mode === 'view' && (
        <EditInformation>
          <Button
            label={
              <p>
                <FiEdit2 /> {t('change-password.title')}
              </p>
            }
            light
            outlined
            blue
            onClick={(e) => {
              e.preventDefault()
              setMode((mode) => 'edit')
            }}
          />
        </EditInformation>
      )}
    </Card>
  )
}

export default ChangePassword
