import { api } from './api.service'

export const getServiceCategories = async () => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/dictionaries/ServiceCategories?lang=pl`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
  return res
}

export const getLicenseCategories = async () => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/dictionaries/LicenseCategories?lang=pl`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
  return res
}

export const getServices = async () => {
  const res = await api.get(`${process.env.REACT_APP_API_URL}/dictionaries/Services?lang=pl`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
  return res
}
