export const saveToken = (token) => {
  localStorage.setItem('dc_auth_token', token)
}

export const saveAccountType = (accountType) => {
  localStorage.setItem('dc_account_type', accountType)
}

export const saveUsername = (username) => {
  localStorage.setItem('dc_auth_username', username)
}

export const saveUserId = (userId) => {
  localStorage.setItem('dc_auth_userId', userId)
}

export const removeToken = () => {
  localStorage.removeItem('dc_auth_token')
}

export const removeUsername = () => {
  localStorage.removeItem('dc_auth_username')
}

export const removeUserId = () => {
  localStorage.removeItem('dc_auth_userId')
}

export const removeAccountType = () => {
  localStorage.removeItem('dc_account_type')
}

export const getToken = () => {
  return localStorage.getItem('dc_auth_token')
}

export const getUsername = () => {
  return localStorage.getItem('dc_auth_username')
}

export const getUserId = () => {
  return localStorage.getItem('dc_auth_userId')
}

export const getAccountType = () => {
  return localStorage.getItem('dc_account_type')
}
